const generateTrackingURL = (link, source = '', medium = '', campaign = '', term = '', content = '') => {
  const queryParams = [];

  if (source) queryParams.push(`utm_source=${encodeURIComponent(source)}`);
  if (medium) queryParams.push(`utm_medium=${encodeURIComponent(medium)}`);
  if (campaign) queryParams.push(`utm_campaign=${encodeURIComponent(campaign)}`);
  if (term) queryParams.push(`utm_term=${encodeURIComponent(term)}`);
  if (content) queryParams.push(`utm_content=${encodeURIComponent(content)}`);

  const queryString = queryParams.join('&');
  if (queryString) {
    return `${link}?${queryString}`;
  }

  return link;
};

export default generateTrackingURL;

/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import config from '../../config/host';

// Navigation

// Hooks
import googleLogUser from '../../hooks/User/googleLogUser';

import styles from '../../assets/styles/Forms/LoginFormStyles';

import TextInput from '../Inputs/TextInput';
import DefaultButton from '../Buttons/DefaultButton';
import LineOr from '../Layout/LineOr';
import ContinueWithGoogle from '../Buttons/ContinueWithGoogle';

// Google login

function LoginForm({
  setEmail, userEmail, emailWarning, setPassword, password, passwordWarning, onClick, isMobile,
}) {
  const navigate = useNavigate();

  async function logUser(access_token) {
    const { data } = await axios({
      url: 'https://www.googleapis.com/oauth2/v2/userinfo',
      method: 'get',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const { email, id } = data;
    googleLogUser(email, id, navigate);
  }

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const tokens = await axios.post(`${config.host}/api/user/google`, {
        code: codeResponse.code,
      });
      logUser(tokens.data.access_token);
    },
    flow: 'auth-code',
    // onError: (error) => console.log('Login Failed:', error)
  });

  return (
    <div style={styles.loginFormContainerStyles(isMobile)}>
      <p style={styles.titleStyles(isMobile)}>Bem-vindo de volta</p>
      <p style={styles.subtitleStyles(isMobile)}>
        Ainda não tem uma conta?
        <span style={styles.signinStyles} onClick={() => navigate('/signin')}> Inscreva-se aqui</span>
      </p>
      <div style={styles.inputsStyles(isMobile)}>
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Endereço de email"
          placeholder="seuemail@exemplo.com"
          setValue={setEmail}
          value={userEmail}
          warningMessage={emailWarning}
        />
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Senha"
          placeholder="Insira 8 caracteres ou mais"
          additional="Esqueci minha senha"
          onClickAdditional={() => navigate('/forgot')}
          setValue={setPassword}
          password={password}
          hide
          warningMessage={passwordWarning}
        />
      </div>
      <DefaultButton
        w={isMobile ? '80vw' : ''}
        onClick={onClick}
        label="Entrar"
      />
      <LineOr w={isMobile ? '33vw' : '184px'} />
      <ContinueWithGoogle
        label={isMobile ? 'Login com Google' : 'Continue com o Google'}
        w={isMobile ? '80vw' : ''}
        onClick={login}
      />
    </div>
  );
}

export default LoginForm;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import colors from '../../assets/styles/Global/colors';

import pin from '../../assets/icons/pin.png';

import ChooseStates from '../Inputs/StateInput';

import LittleButton from './LittleButton';

function LocalFilter({ defaultStates, setStates }) {
  const [visible, setVisible] = useState(false);
  const [stateScreen, setStateScreen] = useState(false);

  const styles = {
    container: {
      width: '280px',
      height: '44px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderColor: colors.grey,
      padding: '0px 20px 0px 20px',
      color: colors.darkgrey,
      justifyContent: 'space-between',
      cursor: 'pointer',
      margin: '0px 20px 0px 0px',
    },

    icon: {
      width: '22px',
      height: '22px',
      position: 'fixed',
      marginLeft: '240px',
    },

    closeIcon: {
      width: '16px',
      height: '16px',
      cursor: 'pointer',
      margin: '24px 20px 0px 0px',
    },

    checkIcon: {
      width: '26px',
      height: '26px',
      cursor: 'pointer',
      marginTop: '24px',
    },

    label: {
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      marginBottom: '6px',
    },

    localContainer: {
      position: 'absolute',
      border: '1px solid #EFF2F7',
      marginTop: '10px',
      height: '380px',
      width: '400px',
      backgroundColor: '#FFFFFF',
      overflow: 'auto',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      zIndex: 4,
    },

    containerText: {
      width: '300px',
      height: '44px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderColor: colors.grey,
      padding: '0px 20px 0px 20px',
      color: colors.darkgrey,
      justifyContent: 'space-between',
      cursor: 'pointer',
      margin: '0px 20px 0px 0px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    popup: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      border: '1px solid #EFF2F7',
      marginTop: '10px',
      height: '250px',
      width: '510px',
      padding: '20px 20px 10px 20px',
      backgroundColor: '#FFFFFF',
      overflow: 'auto',
      borderRadius: '12px',
      zIndex: 4,
    },

    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '10px 10px 0px 0px',
    },
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <p style={styles.label}>Regiões</p>
          <div style={styles.containerText} onClick={() => setVisible(!visible)}>
            <p style={{ overflow: 'hidden', width: '222px' }}>{!defaultStates ? 'Todos os estados' : defaultStates.replaceAll(',', ', ')}</p>
            <img alt="pin-location" src={pin} style={styles.icon} />
          </div>
          {
                        visible
                          ? (
                            <div style={styles.popup}>
                              <ChooseStates withoutSelectAll setValue={setStates} setStatesScreen={setStateScreen} defaultStates={defaultStates} />
                              {
                                !stateScreen
                                  ? (
                                    <div style={styles.buttonContainer}>
                                      <LittleButton text="Cancelar" cancel onClick={setVisible} />
                                      <LittleButton text="Confirmar" onClick={setVisible} />
                                    </div>
                                  ) : false
                            }
                            </div>
                          ) : false
                    }
        </div>
      </div>
    </div>
  );
}

export default LocalFilter;

import globalStyles from '../Global/globalStyles';
import colors from '../Global/colors';

const styles = {
  container: {
    ...globalStyles.horizontalSpaceBetween,
    ...globalStyles.pointer,
    padding: '0px 18px 0px 18px',
    width: '92px',
    height: '32px',
    borderRadius: '20px',
    backgroundColor: colors.green,
  },

  icon: {
    width: '16px',
    height: '16px',
  },

  text: {
    fontSize: '14px',
    fontWeight: '500',
    color: colors.white,
  },
};

export default styles;

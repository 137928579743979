/* eslint-disable max-len */
import React, { forwardRef, useState } from 'react';

// Styles
import styles from '../../assets/styles/LandingPageStyles';
import links from '../../assets/images/bro-computer.svg';
import grow from '../../assets/images/bro-money.svg';
import createqr from '../../assets/images/bro-qrcode.svg';

import ButtonStartNow from '../Buttons/ButtonStartNow';

// Copywriting
import lp from '../../copy/lp.json';

const Working = forwardRef(({ isMobile }, ref) => {
  const [hovered, setHovered] = useState(-1);

  return (
    <div style={styles.section(isMobile, false, true)} ref={ref}>
      <div style={styles.header(isMobile)}>
        <p style={styles.title(isMobile)}>{lp['secao-funcionamento'].titulo}</p>
        <p style={styles.subtitle(isMobile)}>{lp['secao-funcionamento'].subtitulo}</p>
      </div>
      <div style={styles.body(isMobile, true)}>
        <div style={styles.between(isMobile, true)}>
          <div style={styles.stepWorking(isMobile, hovered === 1)} onMouseEnter={() => setHovered(1)} onMouseLeave={() => setHovered(-1)}>
            <div style={styles.numberContainer(isMobile)}>
              <p style={styles.number(isMobile)}>1</p>
            </div>
            <div style={styles.workingImageContainer(isMobile)}>
              <img alt="create-qr" src={createqr} style={{ height: '100%', marginTop: '20px' }} />
            </div>
            <div style={styles.workingTextContainer(isMobile)}>
              <p style={styles.workingTitle(isMobile)}>{lp['secao-funcionamento']['caixa-1'].titulo}</p>
              <p style={styles.workingText(isMobile)}>{lp['secao-funcionamento']['caixa-1'].texto}</p>
            </div>
          </div>
          <div style={styles.stepWorking(isMobile, hovered === 2)} onMouseEnter={() => setHovered(2)} onMouseLeave={() => setHovered(-1)}>
            <div style={styles.numberContainer(isMobile)}>
              <p style={styles.number(isMobile)}>2</p>
            </div>
            <div style={styles.workingImageContainer(isMobile)}>
              <img alt="links" src={links} style={{ height: '130%', marginTop: '14px' }} />
            </div>
            <div style={styles.workingTextContainer(isMobile)}>
              <p style={styles.workingTitle(isMobile)}>{lp['secao-funcionamento']['caixa-2'].titulo}</p>
              <p style={styles.workingText(isMobile)}>{lp['secao-funcionamento']['caixa-2'].texto}</p>
            </div>
          </div>
          <div style={styles.stepWorking(isMobile, hovered === 3)} onMouseEnter={() => setHovered(3)} onMouseLeave={() => setHovered(-1)}>
            <div style={styles.numberContainer(isMobile)}>
              <p style={styles.number(isMobile)}>3</p>
            </div>
            <div style={styles.workingImageContainer(isMobile)}>
              <img alt="grow" src={grow} style={{ height: '100%', marginTop: '14px' }} />
            </div>
            <div style={styles.workingTextContainer(isMobile)}>
              <p style={styles.workingTitle(isMobile)}>{lp['secao-funcionamento']['caixa-3'].titulo}</p>
              <p style={styles.workingText(isMobile)}>{lp['secao-funcionamento']['caixa-3'].texto}</p>
            </div>
          </div>
        </div>
        <div style={styles.ctaContainer(isMobile)}>
          <ButtonStartNow label="Comece agora" />
        </div>
      </div>
    </div>
  );
});

export default Working;

/* eslint-disable max-len */
import React from 'react';

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Components
import CreditCard from '../Checkout/CreditCard';

// Styles
import styles from '../../assets/styles/ProfileStyles';

function BillingInformation({
  cardNumber, setCardNumber, cardExpiration, setCardExpiration, cardCVV, setCardCVV, cardName, setCardName, onClick, cpf, setCPF,
}) {
  const handleCardNumber = (txt) => {
    if (!txt.length) {
      setCardNumber('');
      return;
    }
    if (/^\d+(?:\s+\d+)*$/.test(txt.trim()) && txt.trim().length < 20) {
      setCardNumber(txt.trim().match(/\d{1,4}/g).join(' '));
    }
  };
  const handleCardExpiration = (txt) => {
    const strippedDate = txt.replace(/[^0-9]/g, '');
    let formattedDate = '';
    if (strippedDate.length <= 1) {
      formattedDate += `${strippedDate.slice(0, 2)}`;
    }
    if (strippedDate.length === 2) {
      if (strippedDate.length < cardExpiration.length) {
        formattedDate += `${strippedDate.slice(0, 2)}`;
      } else {
        formattedDate += `${strippedDate.slice(0, 2)} / `;
      }
    }
    if (strippedDate.length > 2) {
      formattedDate += `${strippedDate.slice(0, 2)} / ${strippedDate.slice(2, 4)}`;
    }
    setCardExpiration(formattedDate);
  };

  const handleCPF = (txt) => {
    if (!txt.length) {
      setCPF('');
      return;
    }
    if (/^\d+(?:\s+\d+)*$/.test(txt.trim()) && txt.trim().length <= 14) {
      setCPF(txt.trim().match(/\d{1,3}/g).join(' '));
    }
  };

  const handleCardCVV = (txt) => {
    if (!txt.length) {
      setCardCVV('');
      return;
    }
    if (/^\d+$/.test(txt) && txt.length <= 3) {
      setCardCVV(txt);
    }
  };

  const handleCardName = (txt) => {
    if (/^[A-Za-z\s]+$/.test(txt)) {
      setCardName(txt.toUpperCase());
    }
  };

  return (
    <div style={styles.billingInfoContainer}>
      <p style={styles.subsubtitle}>Informações do cartão</p>
      <CreditCard />
      <div style={styles.cardInfo}>
        <TextInput
          label="Número do cartão"
          placeholder="0000 0000 0000 0000"
          setValue={handleCardNumber}
          value={cardNumber}
          w="calc(5 *(100vw - 920px)/12 - 24px)"
        />
        <TextInput
          label="Expiração"
          placeholder="DD / MM"
          setValue={handleCardExpiration}
          value={cardExpiration}
          w="calc(2.5 * (100vw - 920px)/12)"
        />
        <TextInput
          label="CPF"
          placeholder="111 222 333 44"
          w="calc(4.5 *(100vw - 920px)/12 - 24px)"
          setValue={handleCPF}
          value={cpf}
        />
      </div>
      <div style={styles.cardInfo}>
        <TextInput
          label="Nome como no cartão"
          placeholder="JOÃO GUILHERME SILVA"
          setValue={handleCardName}
          value={cardName}
          w="calc(9 *(100vw - 920px)/12 - 24px)"
        />
        <TextInput
          label="CVV"
          placeholder="123"
          w="calc(3 *(100vw - 920px)/12)"
          setValue={handleCardCVV}
          value={cardCVV}
          info
          infoTitle="Código de segurança"
          infoDescription="É o código de três dígitos situado na parte de trás do cartão"
        />
      </div>
      <DefaultButton
        label="Concluir compra"
        w="100%"
        onClick={onClick}
      />
    </div>
  );
}

export default BillingInformation;

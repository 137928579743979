import React, { useState, useEffect, useRef } from 'react';

// Navigation
import { useParams } from 'react-router-dom';

// Hooks
import useRedirectUser from '../hooks/Redirect/useRedirectUser';

// Layout
import NavBar from '../layouts/NavBar';
import LoggedNavBar from '../layouts/LoggedNavBar';

// Sections
import Main from '../components/Landing/Main';
import Problem from '../components/Landing/Problem';
import Working from '../components/Landing/Working';
import Examples from '../components/Landing/Examples';
import Plans from '../components/Landing/Plans';
import FAQ from '../components/Landing/FAQ';

// Styles
import styles from '../assets/styles/LandingPageStyles';

function Landing() {
  // Token (session)
  const token = localStorage.getItem('token');

  // Getting URL parameters
  const { qrShort } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const utmSource = queryParams.get('utm_source');
  const utmMedium = queryParams.get('utm_medium');
  const utmCampaign = queryParams.get('utm_campaign');
  const utmTerm = queryParams.get('utm_term');
  const utmContent = queryParams.get('utm_term');

  useRedirectUser(qrShort, utmSource, utmMedium, utmCampaign, utmTerm, utmContent);

  // Checking width for responsiveness purposes
  const [width, setWidth] = useState(Number(window.innerWidth));
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isMobile = width <= 768;

  // References to scroll to when clicked
  const plansRef = useRef();
  const workingRef = useRef();
  const problemRef = useRef();
  const examplesRef = useRef();

  const navbarHeight = !isMobile ? 80 : 72;

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const scrollToWorking = () => window.scrollTo({ behavior: 'smooth', top: workingRef.current.offsetTop - navbarHeight + 30 });
  const scrollToProblem = () => window.scrollTo({ behavior: 'smooth', top: problemRef.current.offsetTop - navbarHeight + 30 });
  const scrollToExamples = () => window.scrollTo({ behavior: 'smooth', top: examplesRef.current.offsetTop - navbarHeight + 30 });
  const scrollToPlans = () => window.scrollTo({ behavior: 'smooth', top: plansRef.current.offsetTop - navbarHeight + 30 });

  if (qrShort) { // If passed as parameter, means the user wants to be redirected
    return (
      <div /> // TODO: think about what may display while redirect occurs
    );
  }
  // Else, returns the landing page
  return (
    <div>
      <div style={styles.navBarContainer(isMobile)}>
        {
          token
            ? (
              <LoggedNavBar
                landing
                scrollToPlans={scrollToPlans}
                scrollToWorking={scrollToWorking}
                scrollToProblem={scrollToProblem}
                scrollToExamples={scrollToExamples}
                scrollToTop={scrollToTop}
                isMobile={isMobile}
                onlySignin
              />
            )
            : (
              <NavBar
                landing
                scrollToPlans={scrollToPlans}
                scrollToWorking={scrollToWorking}
                scrollToProblem={scrollToProblem}
                scrollToExamples={scrollToExamples}
                scrollToTop={scrollToTop}
                isMobile={isMobile}
                onlySignin
              />
            )
        }
      </div>
      <Main isMobile={isMobile} scrollToWorking={scrollToWorking} />
      <Working isMobile={isMobile} ref={workingRef} />
      <Problem isMobile={isMobile} ref={problemRef} />
      <Examples isMobile={isMobile} ref={examplesRef} />
      <Plans isMobile={isMobile} ref={plansRef} />
      <FAQ isMobile={isMobile} />
    </div>
  );
}

export default Landing;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Buttons
import ButtonNavBar from '../components/Buttons/ButtonNavBar';

// Styles
import logo from '../assets/images/logoname.png';
import styles from '../assets/styles/Layout/LoggedNavBarStyles';
import onlylogo from '../assets/images/logo.png';
import menu from '../assets/icons/menu.png';

function LoggedNavBar({
  landing, isMobile, scrollToPlans, scrollToWorking, scrollToProblem, scrollToExamples, scrollToTop,
}) {
  const navigate = useNavigate();

  return (
    <div style={styles.navContainerStyles(isMobile)}>
      <div style={styles.linksContainerStyles(isMobile, landing)}>
        <img alt="logo" src={isMobile ? onlylogo : logo} style={styles.logoStyles(isMobile)} onClick={scrollToTop} />
        <p style={styles.navLinkStyles(isMobile)} onClick={scrollToWorking}>Como funciona</p>
        <p style={styles.navLinkStyles(isMobile)} onClick={scrollToProblem}>Nossa solução</p>
        <p style={styles.navLinkStyles(isMobile)} onClick={scrollToExamples}>Seu negócio</p>
        <p style={styles.navLinkStyles(isMobile)} onClick={scrollToPlans}>Planos</p>
      </div>
      <div style={styles.buttonsContainerStyles(isMobile)}>
        <ButtonNavBar label="Meus QRs" onClick={() => navigate('/home')} />
        {
                    !isMobile
                      ? <ButtonNavBar label="Perfil" onClick={() => navigate('/profile')} />
                      : false
                }
        {
                    isMobile
                      ? <img alt="menu-hamburguer" src={menu} style={styles.menu(isMobile)} />
                      : false
                }
      </div>
    </div>
  );
}

export default LoggedNavBar;

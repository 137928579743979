/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';

// Styles
import styles from '../../assets/styles/LandingPageStyles';
import plus from '../../assets/icons/plus.png';
import minus from '../../assets/icons/minus.png';

function QA({
  question, answer, onClick, selected, id, isMobile, first,
}) {
  const ref = useRef(null);

  return (
    <div style={styles.QAContainer(isMobile)}>
      <div onClick={onClick} style={styles.questionContainer(isMobile, first, id === selected + 1)}>
        <p>{question}</p>
        {
          selected === id
            ? <img alt="minus-icon" src={minus} style={styles.plusIcon} />
            : <img alt="plus-icon" src={plus} style={styles.plusIcon} />
        }
      </div>
      <div style={styles.answerContainer(isMobile, id === selected, ref)} ref={ref}>
        <p>{answer}</p>
      </div>
    </div>
  );
}

export default QA;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  VictoryStack,
  VictoryArea,
  VictoryBar,
  VictoryPie,
  VictoryAxis,
  VictoryChart,
} from 'victory';
import config from '../../config/host';
import Legend from '../Chart/Legend';

// Filter Components
import DateRange from '../Chart/DateRange';
import TimeRange from '../Chart/TimeRange';
import LocalFilter from '../Chart/Local';
import ApplyButton from '../Chart/ApplyButton';

// Group scans
import {
  groupByWeekDay,
  groupByHour,
  groupByOs,
} from '../../logic/groupScans';

function ScansAnalysis({ scans }) {
  const { qrShort } = useParams();

  const token = localStorage.getItem('token');

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [scansByWeekDay, setScansByWeekDay] = useState(groupByWeekDay(scans, true));
  const [scansByHour, setScansByHour] = useState(groupByHour(scans, true));
  const [scansByOs, setScansByOs] = useState(groupByOs(scans, true));
  const defaultScansByOs = [{ y: 100 }, { y: 0 }, { y: 0 }];
  const defaultScansByHour = [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }];
  const defaultScansByWeekDay = [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }];

  const [scansByOsData, setScansByOsData] = useState(defaultScansByOs);
  const [scansByHourData, setScansByHourData] = useState(defaultScansByHour);
  const [scansByWeekDayData, setScansByWeekDayData] = useState(defaultScansByWeekDay);

  useEffect(() => {
    setScansByWeekDay(groupByWeekDay(scans));
    setScansByHour(groupByHour(scans));
  }, [scans]);

  useEffect(() => {
    setScansByOsData(scansByOs);
    setScansByHourData(scansByHour);
    setScansByWeekDayData(scansByWeekDay);
  }, []);

  const [analysis, setAnalysis] = useState('week');

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [values, setValues] = useState([0, 48]);
  const [states, setStates] = useState('');

  const filter = () => {
    axios
      .get(`${config.host}/api/scan/filterScansFromQRShort`, {
        headers: { authorization: token },
        params: {
          qrShort, daysInterval: selectionRange, timeInterval: values, states,
        },
      })
      .then((response) => {
        if (!response.data.length) {
          setScansByHour([]);
          setScansByWeekDay([]);
        } else {
          setScansByWeekDay(groupByWeekDay(response.data));
          setScansByHour(groupByHour(response.data));
          setScansByOs(groupByOs(response.data));
        }
      });
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', height: '100%',
    }}
    >
      <div style={{ margin: '0px 0px 16px 22px', display: 'flex' }}>
        <DateRange setDateRange={setSelectionRange} />
        <TimeRange setTimeRange={setValues} />
        <LocalFilter setStates={setStates} defaultStates={states} />
        <ApplyButton onClick={filter} />
      </div>
      <div style={{
        display: 'flex', alignItems: 'center', margin: '0px 0px 16px 22px', zIndex: '1',
      }}
      >
        <div style={{ width: '240px', margin: '0px 40px 0px 0px' }}>
          <Select
            options={[{ value: 'week', label: 'Por dia da semana' }, { value: 'hour', label: 'Por hora do dia' }]}
            styles={{ control: (baseStyles, state) => ({ ...baseStyles, width: '240px' }) }}
            onChange={(e) => setAnalysis(e.value)}
            defaultValue={{ value: 'week', label: 'Por dia da semana' }}
          />
        </div>
        <div>
          <Legend />
        </div>
      </div>
      <div style={{
        display: 'flex', justifyContent: 'space-between', zIndex: '-5', marginTop: '20px',
      }}
      >
        {
          analysis === 'week'
            ? (
              <div>
                <VictoryChart domainPadding={{ x: 40 }} width={(windowSize.current[0] - 400) / 1.5} height={windowSize.current[1] > 800 ? 460 : 340}>
                  <VictoryStack colorScale="qualitative">
                    {
                      scansByWeekDay.map((el, idx) => (
                        <VictoryBar
                          key={idx}
                          data={el.scansPerWeekDay}
                        />
                      ))
                  }
                  </VictoryStack>
                  <VictoryAxis
                    dependentAxis
                    standalone={false}
                    style={{
                      grid: { stroke: 'grey', strokeWidth: 0.4 },
                      axis: { stroke: 'transparent' },
                    }}
                  />
                  <VictoryAxis
                    style={{ tickLabels: { angle: 45 } }}
                  />
                </VictoryChart>
              </div>
            )
            : (
              <div>
                <VictoryChart domainPadding={{ x: 10 }} width={(windowSize.current[0] - 400) / 1.5} height={windowSize.current[1] > 800 ? 460 : 340}>
                  <VictoryStack colorScale="qualitative">
                    {
                      scansByHour.map((el, idx) => (
                        <VictoryArea
                          key={idx}
                          data={el.scansPerHour}
                        />
                      ))
                  }
                  </VictoryStack>
                  <VictoryAxis
                    dependentAxis
                    standalone={false}
                    style={{
                      grid: { stroke: 'grey', strokeWidth: 0.4 },
                      axis: { stroke: 'transparent' },
                    }}
                  />
                  <VictoryAxis
                    style={{ tickLabels: { angle: 45 } }}
                  />
                </VictoryChart>
              </div>
            )
        }
        <div style={{ margin: '0px 30px 0px 0px' }}>
          <VictoryPie
            width={(windowSize.current[0] - 400) / 4}
            height={windowSize.current[1] > 800 ? 460 : 340}
            data={scansByOsData}
            colorScale="qualitative"
            labels={({ datum }) => {
              if (datum.x === 'unknown') {
                return '?';
              }
              return datum.x;
            }}
          />
        </div>
      </div>
    </div>

  );
}

export default ScansAnalysis;

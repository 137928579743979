import { useState } from 'react';
import useFetchQRFromUser from '../QR/useFetchQRFromUser';

function useFetchMaxNOfLinks(token) {
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  return qrList.reduce((acc, value) => Math.max(acc, value.nOfLinks), 0);
}

export default useFetchMaxNOfLinks;

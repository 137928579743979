/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import config from '../../config/host';

import setNOfLinks from '../QR/setNOfLinks';

function cancelPlan(token) {
  axios
    .get(`${config.host}/api/qr/getOldestQR`, {
      headers: { authorization: token },
    })
    .then((oldestQR) => {
      axios
        .delete(`${config.host}/api/qr/deleteAllButOldest`, {
          headers: { authorization: token },
        })
        .then((response) => response.data);

      setNOfLinks(token, oldestQR.data.qrShort, 1);

      axios
        .delete(`${config.host}/api/link/deleteAllLinksButOne`, {
          headers: { authorization: token },
          params: { qrId: oldestQR.data._id },
        })
        .then((response) => response.data);

      axios
        .get(`${config.host}/api/link/getMainLink`, {
          headers: { authorization: token },
          params: { qrId: oldestQR.data._id },
        })
        .then((mainLink) => {
          axios
            .delete(`${config.host}/api/scan/deleteAllScansButMains`, {
              headers: { authorization: token },
              params: { linkId: mainLink.data._id },
            })
            .then((response) => response.data);
        });
    });
}

export default cancelPlan;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import colors from '../../assets/styles/Global/colors';

function ApplyButton({ onClick }) {
  const [hover, setHover] = useState(false);

  const styles = {
    container: {
      width: '130px',
      height: '44px',
      backgroundColor: hover ? colors.green : '#FFFFFF',
      border: '1px solid',
      borderColor: colors.green,
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      margin: '26px 0px 0px 10px',
      transition: '.3s ease-in-out',
    },

    text: {
      color: hover ? '#FFFFFF' : colors.green,
      fontSize: '20px',
    },
  };

  return (
    <div
      style={styles.container}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p style={styles.text}>Aplicar</p>
    </div>
  );
}

export default ApplyButton;

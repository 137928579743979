/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';

import styles from '../../assets/styles/Buttons/DefaultButtonStyles';

function DefaultButton({
  onClick, label, w, secundary, disabled, terciary, warning, m,
}) {
  let style;
  if (secundary) {
    style = w ? { ...styles.secundaryButtonStyle, width: w } : { ...styles.secundaryButtonStyle };
  } else if (terciary && disabled) {
    style = w ? { ...styles.disabledTerciaryButtonStyle, width: w } : { ...styles.disabledTerciaryButtonStyle };
  } else if (disabled) {
    style = w ? { ...styles.disabledButtonStyle, width: w } : { ...styles.disabledButtonStyle };
  } else if (terciary) {
    style = w ? { ...styles.terciaryButtonStyle, width: w } : { ...styles.terciaryButtonStyle };
  } else if (warning) {
    style = w ? { ...styles.warningButtonStyles, width: w } : { ...styles.warningButtonStyles };
  } else {
    style = w ? { ...styles.buttonStyle, width: w } : { ...styles.buttonStyle };
  }

  if (m) {
    style = { ...style, margin: m };
  }
  return (
    <div onClick={onClick} style={style}>
      <p style={styles.labelStyle}>{label}</p>
    </div>
  );
}

export default DefaultButton;

import React, { useState, useEffect } from 'react';

// Navigation
import { useSearchParams, useNavigate } from 'react-router-dom';

// Hooks
import resetPassword from '../hooks/User/resetPassword';

// Layout
import NavBar from '../layouts/NavBar';

// Forms
import ResetPasswordForm from '../components/Forms/ResetPasswordForm';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Reset() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Token (session)
  const token = localStorage.getItem('token');

  // Checking width for responsiveness purposes
  const [width, setWidth] = useState(Number(window.innerWidth));
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isMobile = width <= 768;

  // Reset password form variables
  const [password, setPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState('');

  const handlePassword = (passwordInputed) => {
    setPassword(passwordInputed);
    if (passwordInputed.length < 8) {
      setPasswordWarning('Insira no mínimo 8 caracteres');
    } else {
      setPasswordWarning('');
    }
    if (passwordInputed !== confirmPassword && confirmPassword) {
      setConfirmPasswordWarning('As senhas são diferentes');
    } else {
      setConfirmPasswordWarning('');
    }
  };

  const handleConfirmPassword = (confirmPasswordInputed) => {
    setConfirmPassword(confirmPasswordInputed);
    if (password !== confirmPasswordInputed) {
      setConfirmPasswordWarning('As senhas são diferentes');
    } else {
      setConfirmPasswordWarning('');
    }
  };

  const tryResetPassword = () => {
    if (!passwordWarning && !confirmPasswordWarning) {
      resetPassword(searchParams.get('id'), token, password);
      navigate('/');
    }
  };

  return (
    <div style={globalStyles.columnCentered}>
      <NavBar isMobile={isMobile} />
      <ResetPasswordForm
        isMobile={isMobile}
        setPassword={handlePassword}
        passwordWarning={passwordWarning}
        setConfirmPassword={handleConfirmPassword}
        confirmPasswordWarning={confirmPasswordWarning}
        onClick={tryResetPassword}
      />
    </div>
  );
}

export default Reset;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import globalStyles from './Global/globalStyles';
import colors from './Global/colors';

const styles = {

  navBarContainer(isMobile) {
    return {
      width: '100vw',
      position: 'fixed',
      zIndex: '2',
    };
  },

  mainSection(isMobile) {
    return {
      ...globalStyles.row,
      height: '100vh',
      width: '100%',
      justifyContent: !isMobile ? 'flex-start' : 'center',
    };
  },

  slogan(isMobile) {
    return {
      width: !isMobile ? '50%' : '80%',
      padding: !isMobile ? '26vh 4vw 0px 8vw' : '16vh 4vw 0px 4vw',
    };
  },

  mainTitle(isMobile) {
    return {
      fontSize: !isMobile ? '4vw' : '40px',
      fontWeight: '800',
      textAlign: !isMobile ? 'start' : 'center',
    };
  },

  mainSubtitle(isMobile) {
    return {
      fontSize: !isMobile ? '1.8vw' : '20px',
      textAlign: !isMobile ? 'start' : 'center',
      margin: !isMobile ? '22px 0px 10vh 0px' : '22px 0px 52px 0px',
    };
  },

  buttonContainer(isMobile) {
    return {
      display: 'flex',
      justifyContent: isMobile ? 'center' : 'flex-start',
    };
  },

  imageContainer(isMobile) {
    return {
      display: !isMobile ? 'flex' : 'none',
      width: !isMobile ? '50%' : '0px',
      ...globalStyles.center,
    };
  },

  mainImage(isMobile) {
    return {
      display: !isMobile ? 'flex' : 'none',
      width: !isMobile ? '32vw' : '0px',
    };
  },

  arrowDownContainer: {
    ...globalStyles.pointer,
    position: 'absolute',
    bottom: '40px',
    left: 'calc(50vw - 10px)',
  },

  arrowDownIcon(hover) {
    return {
      width: '36px',
      height: '36px',
      transform: hover ? 'scale(1.08)' : 'scale(1)',
      transition: 'transform .3s ease-in-out',
    };
  },

  section(isMobile, doubled, background) {
    return {
      height: !isMobile ? doubled ? '200vh' : '100vh' : '',
      width: '100%',
      backgroundColor: background ? colors.lightgrey : '#FFFFFF',
    };
  },

  header(isMobile) {
    return {
      height: '22vh',
      ...globalStyles.center,
      flexDirection: 'column',
      width: '100%',
      padding: '16px 16px 6px 16px',
    };
  },

  title(isMobile) {
    return {
      fontSize: !isMobile ? '2.2vw' : '28px',
      fontWeight: '600',
      margin: '0px 0px 12px 0px',
      textAlign: 'center',
    };
  },

  subtitle(isMobile) {
    return {
      fontSize: !isMobile ? '22px' : '17px',
      fontWeight: '400',
      textAlign: 'center',
    };
  },

  body(isMobile, centered, doubled, evenly = true) {
    return {
      padding: !isMobile ? '30px 130px' : '30px 10px',
      height: !isMobile ? doubled ? 'calc(200vh - 22vh)' : 'calc(100vh - 22vh)' : '',
      display: 'flex',
      flexDirection: 'column',
      alignItems: centered ? 'center' : 'start',
      justifyContent: evenly ? 'space-evenly' : '',
    };
  },

  between(isMobile, top) {
    return {
      display: 'flex',
      width: '100%',
      height: '90%',
      flexDirection: !isMobile ? 'row' : 'column',
      justifyContent: !isMobile ? 'space-between' : 'flex-start',
      alignItems: 'center',
      margin: top && !isMobile ? '-160px 0px -90px 0px' : '0px',
    };
  },

  stepWorking(isMobile, hover) {
    return {
      width: !isMobile ? 'calc((74vw)/3)' : '94%',
      height: !isMobile ? '86%' : '28vh',
      position: 'relative',
      backgroundColor: hover ? colors.reallytransparentgreen : colors.lightgrey,
      borderRadius: '20px',
      marginBottom: isMobile ? '50px' : '0px',
    };
  },

  numberContainer(isMobile) {
    return {
      ...globalStyles.center,
      width: !isMobile ? '44px' : '40px',
      height: !isMobile ? '44px' : '40px',
      borderRadius: '100px',
      backgroundColor: colors.green,
      position: 'absolute',
      top: !isMobile ? 'calc(48% - 22px)' : '-8px',
      left: 'calc(50% - 22px)',
    };
  },

  number(isMobile) {
    return {
      color: colors.white,
      fontSize: !isMobile ? '22px' : '20px',
    };
  },

  workingImageContainer(isMobile) {
    return {
      ...globalStyles.center,
      display: !isMobile ? 'flex' : 'none',
      height: '50%',
    };
  },

  workingTextContainer(isMobile) {
    return {
      height: '50%',
      padding: !isMobile ? '18px 24px' : '40px 24px',
      ...globalStyles.columnCentered,
    };
  },

  workingTitle(isMobile) {
    return {
      fontSize: !isMobile ? 'max(23px, 1.6vw)' : '24px',
      width: '120%',
      fontWeight: '500',
      margin: '0px 0px 20px 0px',
      textAlign: 'center',
    };
  },

  workingText(isMobile) {
    return {
      fontSize: !isMobile ? 'min(1.25vw, 22px)' : '17px',
      textAlign: 'center',
    };
  },

  ctaContainer(isMobile) {
    return {
      ...globalStyles.center,
      width: '100%',
    };
  },

  stepProblem(isMobile) {
    return {
      ...globalStyles.row,
      justifyContent: isMobile ? 'center' : 'flex-start',
      width: '100%',
      height: !isMobile ? 'calc((200vh - 22vh - 300px)/3)' : '28vh',
      marginBottom: '36px',
    };
  },

  problemImageContainer(isMobile) {
    return {
      ...globalStyles.center,
      width: !isMobile ? '40%' : '0px',
      height: '100%',
    };
  },

  problemTextContainer(isMobile) {
    return {
      padding: !isMobile ? '8vh 80px' : '0px',
      flexDirection: 'column',
      height: '100%',
      width: !isMobile ? '60%' : '90%',
      textAlign: isMobile ? 'center' : '',
    };
  },

  problemTitle(isMobile) {
    return {
      fontSize: !isMobile ? 'max(32px, 2vw)' : '24px',
      fontWeight: '500',
      margin: '0px 0px 20px 0px',
    };
  },

  problemText(isMobile) {
    return {
      fontSize: !isMobile ? 'max(20px, 1.2vw)' : '17px',
    };
  },

  exampleText(isMobile) {
    return {
      fontSize: '18px',
      textAlign: 'center',
    };
  },

  optionsContainer(isMobile) {
    return {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '80vw',
      padding: !isMobile ? '0px 2vw' : '0px',
      margin: !isMobile ? '2px 0px' : '-10px 0px 12px 0px',
    };
  },

  optionContainer(isMobile) {
    return {
      ...globalStyles.pointer,
      borderRadius: '20px',
      padding: !isMobile ? '10px 18px' : '6px 10px',
      maxWidth: !isMobile ? '100vw' : '180px',
    };
  },

  selectedOptionContainer(isMobile) {
    return {
      ...globalStyles.pointer,
      borderRadius: '20px',
      backgroundColor: colors.green,
      padding: !isMobile ? '10px 18px' : '6px 10px',
      maxWidth: !isMobile ? '100vw' : '180px',
    };
  },

  optionTitle(isMobile) {
    return {
      fontSize: !isMobile ? '22px' : '16px',
      textAlign: 'center',
    };
  },

  selectedOptionTitle(isMobile) {
    return {
      fontSize: !isMobile ? '22px' : '16px',
      fontWeight: '500',
      textAlign: 'center',
    };
  },

  selectedExampleContainer: {
    ...globalStyles.center,
    height: '60%',
  },

  selectedExample(isMobile) {
    return {
      width: !isMobile ? '42%' : '100%',
    };
  },

  exampleImageContainer: {
    height: '20vh',
  },

  plansContainer(isMobile) {
    return {
      width: '96%',
      height: !isMobile ? '104%' : '240vh',
      display: 'flex',
      justifyContent: !isMobile ? 'space-around' : 'space-between',
      flexDirection: !isMobile ? 'row' : 'column',
      alignItems: 'center',
      marginTop: '-6%',
    };
  },

  QAContainer(isMobile) {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: isMobile ? '94%' : '84%',
    };
  },

  questionContainer(isMobile, first, next) {
    return {
      ...globalStyles.pointer,
      ...globalStyles.horizontalSpaceBetween,
      padding: !isMobile ? '28px 40px' : '20px 20px',
      display: 'flex',
      alignItems: 'center',
      width: !isMobile ? '60%' : '92%',
      fontSize: !isMobile ? '20px' : '16px',
      fontWeight: '500',
      borderTop: !first ? `1px solid ${colors.grey}` : '',
    };
  },

  answerContainer(isMobile, visible, ref) {
    return {
      padding: !isMobile ? '0px 40px' : '0px 20px',
      width: !isMobile ? '60%' : '92%',
      fontSize: '18px',
      maxHeight: visible ? `${ref.current.scrollHeight}px` : '0',
      transition: 'max-height 0.25s linear 0s',
      overflow: 'hidden',
      marginBottom: visible ? '24px' : '0px',
    };
  },

  plusIcon: {
    width: '18px',
  },

  faqContainer(isMobile) {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margiTop: '-4%',
    };
  },
};

export default styles;

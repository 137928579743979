import globalStyles from './Global/globalStyles';

const styles = {
  headerInfoContainerStyles: {
    ...globalStyles.row,
    width: 'calc(100vw - 310px)',
    height: '56px',
    padding: '0px 100px 0px 100px',
  },

  infoContainerStyles: {
    width: '100%',
    padding: '0px 10px 0px 0px',
  },

  titleStyles: {
    fontSize: '32px',
    fontWeight: 'bold',
  },

  subtitle: {
    fontSize: '18px',
  },

  headerTitle: {
    ...globalStyles.horizontalSpaceBetween,
    width: '100%',
  },

  iconsContainer: {
    ...globalStyles.horizontalSpaceBetween,
  },

  underLine: {
    ...globalStyles.horizontalSpaceBetween,
  },

  copyToClipboard(hover, copied) {
    return {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      backgroundColor: '#EDEDED',
      padding: '4px 8px 4px 8px',
      fontSize: '13px',
      borderRadius: '4px',
      margin: !copied ? '72px 0px 0px 66px' : '72px 0px 0px 218px',
      opacity: hover || copied ? 1 : 0,
      transition: hover || copied ? 'opacity 0.3s ease-in-out' : '',
      justifyContent: 'flex-end',
    };
  },

  chat: {
    width: '9px',
    height: '9px',
    backgroundColor: '#EDEDED',
    position: 'absolute',
    margin: '-24px 10px 0px 0px',
    transform: 'rotate(45deg)',
  },
};

export default styles;

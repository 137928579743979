import React from 'react';

// Hooks
import useFetchUser from '../../hooks/User/useFetchUser';

import styles from '../../assets/styles/ProfileStyles';

function AccountUsage() {
  const token = localStorage.getItem('token');
  const user = useFetchUser(token);

  return (
    <div style={styles.accountUsageContainer}>
      <div style={styles.info}>
        <p style={styles.label}>Número de QR Codes</p>
        <p style={styles.value}>{user.qrs}</p>
      </div>
      <div style={styles.info}>
        <p style={styles.label}>Número máximo de links</p>
        <p style={styles.value}>{user.links}</p>
      </div>
    </div>
  );
}

export default AccountUsage;

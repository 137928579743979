import globalStyles from './Global/globalStyles';
import colors from './Global/colors';

const styles = {
  box: {
    ...globalStyles.verticalAlign,
    ...globalStyles.littleRoundedBorder,
    ...globalStyles.horizontalSpaceBetween,
    ...globalStyles.verticalAlign,
    height: '48px',
    borderColor: colors.grey,
    backgroundColor: '#FFFFFF',
    width: '94%',
    marginBottom: '20px',
    position: 'relative',
    padding: '0px 20px',
  },

  linkContainer: {
    ...globalStyles.row,
    ...globalStyles.pointer,
    fontSize: '15px',
  },

  info: {
    ...globalStyles.row,
  },

  redirectsContainer: {
    ...globalStyles.columnCentered,
    width: '90px',
    height: '76%',
  },

  redirects: {
    fontSize: '18px',
    fontWeight: '500',
    margin: '0px',
    padding: '0px',
  },

  redirectsText: {
    fontSize: '10px',
    fontWeight: '500',
    margin: '-2px 0px 0px 0px',
    padding: '0px',
  },

  iconsContainer: {
    ...globalStyles.verticalAlign,
    ...globalStyles.horizontalSpaceBetween,
    width: '80px',
    padding: '0px 6px',
  },

  copyIcon: {
    width: '20px',
    height: '20px',
    margin: '0px 0px 0px 18px',
  },

  icons: {
    width: '22px',
    height: '22px',
    ...globalStyles.pointer,
  },

  copyToClipboard(hover, copied) {
    return {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#EDEDED',
      padding: '4px 8px 4px 8px',
      fontSize: '12px',
      borderRadius: '4px',
      margin: '-66px 0px 0px -30px',
      opacity: hover || copied ? 1 : 0,
      transition: hover || copied ? 'opacity 0.3s ease-in-out' : '',
    };
  },

  chat: {
    width: '9px',
    height: '9px',
    backgroundColor: '#EDEDED',
    position: 'absolute',
    margin: '24px 0px 0px 8px',
    transform: 'rotate(45deg)',
  },
};

export default styles;

/* eslint-disable no-restricted-syntax */

const stateAbbreviations = {
  Acre: 'AC',
  Alagoas: 'AL',
  Amapa: 'AP',
  Amazonas: 'AM',
  Bahia: 'BA',
  Ceara: 'CE',
  'Distrito Federal': 'DF',
  'Espirito Santo': 'ES',
  Goias: 'GO',
  Maranhao: 'MA',
  'Mato Grosso': 'MT',
  'Mato Grosso do Sul': 'MS',
  'Minas Gerais': 'MG',
  Para: 'PA',
  Paraiba: 'PB',
  Parana: 'PR',
  Pernambuco: 'PE',
  Piaui: 'PI',
  'Rio de Janeiro': 'RJ',
  'Rio Grande do Norte': 'RN',
  'Rio Grande do Sul': 'RS',
  Rondonia: 'RO',
  Roraima: 'RR',
  'Santa Catarina': 'SC',
  'Sao Paulo': 'SP',
  Sergipe: 'SE',
  Tocantins: 'TO',
};

// Returns an object containing all formatted information about the exact time of scan
const getTime = () => {
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const weekDays = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
  const date = new Date();
  return {
    month: months[date.getMonth()],
    weekDay: weekDays[date.getDay() - 1],
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
  };
};

// Returns 1. the link that fitted in all constraints and 2. if it is the default (boolean value)
const decideLink = (links, osName, location) => {
  const time = getTime();
  const defaultLink = links.filter((el) => el.default)[0];
  links = links.filter((el) => !el.default);

  // Tests every link trying to fit their constraints into user situation
  for (const link of links) {
    const hours = link.hourRestriction.split(',').map((el) => Number(el) / 2);
    const osCheck = link.osName.includes(osName) || !link.osName;
    const timeCheck = (link.timeRestriction.includes(time.month)
        && link.timeRestriction.includes(time.weekDay)
        && time.hour + time.minute / 60 > hours[0] && time.hour + time.minute / 60 < hours[1]);
    const locCheck = link.locRestriction.includes(stateAbbreviations[location.split(',')[1]]) || !link.locRestriction;
    if (osCheck && timeCheck && locCheck) {
      return [link.url, true, link._id];
    }
  }
  return [defaultLink.url, false, defaultLink._id];
};

export default decideLink;

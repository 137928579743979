import React from 'react';

function InfoHeader({ name, value }) {
  return (
    <div style={{ width: '100px' }}>
      <p style={{ fontSize: '16px', display: 'flex', marginBottom: '0px' }}>{name}</p>
      <p style={{ fontSize: '18px', display: 'flex', marginTop: '0px' }}>{value}</p>
    </div>
  );
}

export default InfoHeader;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import useFetchUser from '../hooks/User/useFetchUser';
import useFetchMaxNOfLinks from '../hooks/User/maxNOfLinks';
import useFetchQRFromUser from '../hooks/QR/useFetchQRFromUser';

// Layout
import SideBar from '../layouts/SideBar';
import TopBar from '../layouts/TopBar';
import Line from '../components/Layout/Line';

// Form
import CreateQRForm from '../components/Forms/CreateQRForm';
import PersonalInfo from '../components/Profile/PersonalInfo';
import AccountUsage from '../components/Profile/AccountUsage';
import BillingInformation from '../components/Profile/BillingInformation';

// Styles
import styles from '../assets/styles/ProfileStyles';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Profile() {
  const navigate = useNavigate();

  // Token (session)
  const token = localStorage.getItem('token');
  if (!token) navigate('/login');

  // All QR Codes from the user
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  const onCreateQRCode = (newQRCode) => setQRList((prev) => [...prev, newQRCode]);
  const [createQROverlay, setCreateQROverlay] = useState(false);

  // Information about usage account
  const user = useFetchUser(token);
  const maxLinks = useFetchMaxNOfLinks(token);
  const qrLen = user.qrs;

  // Payment info form variables
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');

  const [cardExpiration, setCardExpiration] = useState('');
  const [cardCVV, setCardCVV] = useState('');

  const [info, selectInfo] = useState(1);

  return (
    <div style={globalStyles.row}>
      {
        createQROverlay
          ? (
            <CreateQRForm
              setOverlay={setCreateQROverlay}
              onCreateQRCode={onCreateQRCode}
            />
          ) : false
      }
      <SideBar
        setOverlay={setCreateQROverlay}
        qrList={qrList}
        onCreateQRCode={onCreateQRCode}
      />
      <div>
        <TopBar setOverlay={setCreateQROverlay} />
        <div style={globalStyles.containerPadding}>
          <div>
            <p style={globalStyles.boldTitle}>Minha conta</p>
            <div style={styles.header}>
              <p
                style={info === 1 ? styles.subtitle : styles.unabledSubtitle}
                onClick={() => selectInfo(1)}
              >
                Informações pessoais
              </p>
              <p
                style={info === 2 ? styles.subtitle : styles.unabledSubtitle}
                onClick={() => selectInfo(2)}
              >
                Uso da conta
              </p>
              <p
                style={info === 3 ? styles.subtitle : styles.unabledSubtitle}
                onClick={() => selectInfo(3)}
              >
                Informações de cobrança
              </p>
            </div>
            <Line w="calc(100vw - 590px)" />
            {
              info === 1 ? <PersonalInfo />
                : info === 2 ? <AccountUsage qrCodes={qrLen} maxLinks={maxLinks} />
                  : info === 3 ? (
                    <BillingInformation
                      cardName={cardName}
                      setCardName={setCardName}
                      cardNumber={cardNumber}
                      setCardNumber={setCardNumber}
                      cardExpiration={cardExpiration}
                      setCardExpiration={setCardExpiration}
                      cardCVV={cardCVV}
                      setCardCVV={setCardCVV}
                    />
                  ) : false
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

import axios from 'axios';
import config from '../../config/host';

function logUser(email, password, nav, setEmail, setPassword, setEmailWarning, setPasswordWarning) {
  axios
    .post(`${config.host}/api/user/logUser`, {
      email, password,
    })
    .then((response) => {
      if (response.data === 'Not found') {
        setPasswordWarning('');
        setEmailWarning('Esse email não está cadastrado');
      } else if (response.data === 'Invalid credentials') {
        setEmailWarning('');
        setPasswordWarning('Senha incorreta');
      } else {
        setPassword('');
        setEmail('');
        setEmailWarning('');
        setPasswordWarning('');
        localStorage.setItem('token', response.data);
        nav('/home');
      }
    });
}

export default logUser;

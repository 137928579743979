/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

// Styles
import { useNavigate } from 'react-router-dom';
import styles from '../../assets/styles/CancelStyles';

// General components
import DefaultButton from '../Buttons/DefaultButton';

// Hooks
import updatePlan from '../../hooks/User/updatePlan';
import cancelPlan from '../../hooks/User/cancelPlan';
import setNOfQRs from '../../hooks/User/setNOfQRs';

// Navigation

function CancelPlan({ overlay, setOverlay }) {
  const visible = overlay ? {} : { display: 'none' };

  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const tryCancelPlan = () => {
    setOverlay(false);
    cancelPlan(token);
    setNOfQRs(token, 1);
    updatePlan(token, '640b7535140beb8cabbede3a', 1);
    navigate('/plans');
  };

  return (
    <div style={{ ...styles.background, ...visible }}>
      <div style={styles.containerStyles}>
        <div style={styles.titleContainer}>
          <p style={styles.titleStyles}>Cancelar seu plano?</p>
        </div>
        <div style={styles.body}>
          <p style={styles.bodyText}>
            Cancelando o plano, somente um QR Code será mantido, junto ao seu link principal. Todo o restante de QR Codes, links e histórico de scans será
            <strong>permanentemente</strong>
            {' '}
            deletado.
            <br />
            <br />
            Por padrão, manteremos o QR Code mais antigo. Portanto, caso deseje manter no plano gratuito outro QR Code, nós recomendamos que delete manualmente todo o restante que não deseje manter.
            <br />
            <br />
            Ao clicar em "Sim, cancelar", seu histórico atual
            {' '}
            <strong>não</strong>
            {' '}
            poderá ser recuperado jamais.
          </p>
        </div>
        <div style={styles.buttonsContainer}>
          <DefaultButton label="Cancelar" w="172px" secundary onClick={() => setOverlay(false)} />
          <DefaultButton label="Sim, cancelar" w="172px" onClick={tryCancelPlan} />
        </div>
      </div>
    </div>
  );
}

export default CancelPlan;

import axios from 'axios';
import config from '../../config/host';

function updateUser(token, userName, email) {
  axios
    .put(`${config.host}/api/user/updateUser`, {
      headers: { authorization: token },
      params: { userName, email },
    })
    .then((response) => response);
}

export default updateUser;

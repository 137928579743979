import axios from 'axios';
import config from '../../config/host';

function setNOfQRs(token, number) {
  axios
    .put(`${config.host}/api/user/setNOfQRs`, {
      headers: { authorization: token },
      params: { number },
    })
    .then((response) => response);
}

export default setNOfQRs;

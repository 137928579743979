/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

// Buttons
import ButtonStartNow from '../Buttons/ButtonStartNow';

// Styles
import styles from '../../assets/styles/LandingPageStyles';
import broscanning from '../../assets/images/bro-scanning.svg';
import arrowdown from '../../assets/icons/arrowdown.png';

// Copywriting
import lp from '../../copy/lp.json';

function Main({ isMobile, scrollToWorking }) {
  const [arrowHovered, setArrowHovered] = useState(false);

  return (
    <div style={styles.mainSection(isMobile)}>
      <div style={styles.slogan(isMobile)}>
        <p style={styles.mainTitle(isMobile)}>{lp['secao-principal'].titulo}</p>
        <p style={styles.mainSubtitle(isMobile)}>{lp['secao-principal'].subtitulo}</p>
        <div style={styles.buttonContainer(isMobile)}>
          <ButtonStartNow
            label="Crie seu QR Code"
            isMobile={isMobile}
          />
        </div>
      </div>
      <div style={styles.imageContainer(isMobile)}>
        <img alt="bro-scanning" style={styles.mainImage(isMobile)} src={broscanning} />
      </div>
      <div
        onMouseEnter={() => setArrowHovered(true)}
        onMouseLeave={() => setArrowHovered(false)}
        style={styles.arrowDownContainer}
        onClick={scrollToWorking}
      >
        <img alt="arrow-down" src={arrowdown} style={styles.arrowDownIcon(arrowHovered)} />
      </div>
    </div>
  );
}

export default Main;

import axios from 'axios';
import config from '../../config/host';

function deleteQRFromShort(token, qrShort) {
  axios
    .delete(`${config.host}/api/qr/deleteQRFromShort`, {
      headers: { authorization: token },
      params: { qrShort },
    })
    .then((response) => response);
}

export default deleteQRFromShort;

import React, { forwardRef } from 'react';

// Styles
import styles from '../../assets/styles/LandingPageStyles';
import confused from '../../assets/images/bro-stressed.svg';
import idea from '../../assets/images/bro-thinking.svg';
import solution from '../../assets/images/bro-idea.svg';

import ButtonStartNow from '../Buttons/ButtonStartNow';

// Copywriting
import lp from '../../copy/lp.json';

const Problem = forwardRef(({ isMobile }, ref) => (
  <div style={styles.section(isMobile, true)} ref={ref}>
    <div style={styles.header(isMobile)}>
      <p style={styles.title(isMobile)}>{lp['secao-problema'].titulo}</p>
      <p style={styles.subtitle(isMobile)}>{lp['secao-problema'].subtitulo}</p>
    </div>
    <div style={styles.body(isMobile, false, false, false)}>
      <div style={styles.stepProblem(isMobile)}>
        <div style={styles.problemImageContainer(isMobile)}>
          <img alt="confused" src={confused} style={{ width: '70%' }} />
        </div>
        <div style={styles.problemTextContainer(isMobile)}>
          <p style={styles.problemTitle(isMobile)}>{lp['secao-problema']['caixa-problema'].titulo}</p>
          <p style={styles.problemText(isMobile)}>{lp['secao-problema']['caixa-problema'].texto}</p>
        </div>
      </div>
      <div style={styles.stepProblem(isMobile)}>
        <div style={styles.problemTextContainer(isMobile)}>
          <p style={styles.problemTitle(isMobile)}>{lp['secao-problema']['caixa-solucao'].titulo}</p>
          <p style={styles.problemText(isMobile)}>{lp['secao-problema']['caixa-solucao'].texto}</p>
        </div>
        <div style={styles.problemImageContainer(isMobile)}>
          <img alt="idea" src={idea} style={{ width: '70%' }} />
        </div>
      </div>
      <div style={styles.stepProblem(isMobile)}>
        <div style={styles.problemImageContainer(isMobile)}>
          <img alt="solution" src={solution} style={{ width: '70%' }} />
        </div>
        <div style={styles.problemTextContainer(isMobile)}>
          <p style={styles.problemTitle(isMobile)}>{lp['secao-problema']['caixa-funcionalidades'].titulo}</p>
          <p style={styles.problemText(isMobile)}>{lp['secao-problema']['caixa-funcionalidades'].texto}</p>
        </div>
      </div>
      <div style={styles.ctaContainer(isMobile)}>
        <ButtonStartNow label="Crie sua conta" />
      </div>
    </div>
  </div>
));

export default Problem;

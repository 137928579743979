import globalStyles from '../Global/globalStyles';
import colors from '../Global/colors';

const styles = {
  regionsContainer: {
    ...globalStyles.row,
    ...globalStyles.center,
    width: '440px',
    height: '160px',
    flexWrap: 'wrap',
  },

  regionContainer: {
    ...globalStyles.pointer,
    ...globalStyles.horizontalSpaceAround,
    border: 'solid 1px',
    borderColor: colors.grey,
    borderRadius: '10px',
    height: '40px',
    padding: '0px 18px',
    margin: '0px 12px',
  },

  regionName: {
    fontSize: '17px',
    marginRight: '10px',
  },

  numberOfSelectedStates: {
    color: colors.darkgrey,
  },

  allSelectedStates: {
    color: colors.green,
  },

  statesContainer: {
    width: '440px',
    height: '100%',
  },

  headerStates: {
    ...globalStyles.row,
    alignItems: 'center',
    height: '32px',
    marginBottom: '20px',
  },

  bodyStates: {
    ...globalStyles.center,
    flexWrap: 'wrap',
    height: 'calc(100% - 20px)',
  },

  selectedState: {
    ...globalStyles.center,
    ...globalStyles.pointer,
    backgroundColor: colors.green,
    height: '32px',
    padding: '0px 12px',
    borderRadius: '10px',
    margin: '0px 10px',
  },

  notSelectedState: {
    ...globalStyles.center,
    ...globalStyles.pointer,
    height: '32px',
    padding: '0px 12px',
    borderRadius: '10px',
    margin: '0px 10px',
  },

  backIcon: {
    ...globalStyles.pointer,
    width: '22px',
    height: '22px',
  },

  regionNameHeader: {
    ...globalStyles.pointer,
    fontSize: '20px',
    margin: '0px 0px 0px 42px',
  },

  selectAllContainer: {
    ...globalStyles.row,
    ...globalStyles.horizontalSpaceBetween,
    margin: '24px 0px 0px 0px',
    width: '42%',
  },

  selectAll: {
    ...globalStyles.pointer,
    fontSize: '17px',
    color: colors.green,
  },
};

export default styles;

import globalStyles from '../Global/globalStyles';
import colors from '../Global/colors';

const styles = {
  defaultLabelContainer: {
    width: '420px',
    ...globalStyles.horizontalSpaceBetween,
  },

  defaultLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.9px',
  },

  monthsContainer: {
    ...globalStyles.row,
    margin: '10px 0px 14px 0px',
    flexWrap: 'wrap',
    width: '420px',
  },

  monthContainer: {
    ...globalStyles.center,
    width: '70px',
    height: '36px',
  },

  selectedMonth: {
    ...globalStyles.center,
    ...globalStyles.pointer,
    backgroundColor: colors.green,
    padding: '4px 0px',
    width: '60px',
    borderRadius: '10px',
  },

  notSelectedMonth: {
    ...globalStyles.pointer,
    opacity: '0.5',
  },

  info: {
    ...globalStyles.pointer,
    width: '28px',
    position: 'absolute',
    bottom: '24px',
    right: '-40px',
  },

  popover: {
    borderRadius: '10px',
    border: '1px solid',
    borderColor: colors.grey,
    zIndex: '12',
    backgroundColor: '#FFFFFF',
    width: '312px',
    position: 'relative',
    margin: '0px 0px 8px 10px',
  },

  popoverHeader: {
    backgroundColor: '#F0F0F0',
    borderBottom: '1px solid',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottomColor: colors.grey,
    padding: '10px 12px 10px 16px',
    fontSize: '16px',
    fontWeight: '500',
  },

  point: {
    width: '14px',
    height: '14px',
    backgroundColor: '#FFFFFF',
    borderLeft: '1px solid',
    borderLeftColor: colors.grey,
    borderBottom: '1px solid',
    borderBottomColor: colors.grey,
    position: 'absolute',
    top: '48px',
    left: '-8px',
    transform: 'rotate(45deg)',
  },

  popoverBody: {
    padding: '16px 12px 16px 16px',
    fontSize: '14px',
  },
};

export default styles;

import React, { useState, useEffect } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import useFetchUser from '../hooks/User/useFetchUser';
import useFetchPlans from '../hooks/Plan/useFetchPlans';
import useFetchMaxNOfLinks from '../hooks/User/maxNOfLinks';
import maxFeaturedLink from '../hooks/User/maxFeaturedLink';
import useFetchQRFromUser from '../hooks/QR/useFetchQRFromUser';

// Layout
import SideBar from '../layouts/SideBar';
import TopBar from '../layouts/TopBar';

// Forms
import CreateQRForm from '../components/Forms/CreateQRForm';

// Components
import PlanBox from '../components/PlanBox';
import Info from '../components/Info';
import CancelPlan from '../components/SureTo/CancelPlan';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Plans() {
  const navigate = useNavigate();

  // Token (session)
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) navigate('/login');
  });

  // All QR Codes from the user
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  const onCreateQRCode = (newQRCode) => setQRList((prev) => [...prev, newQRCode]);
  const [createQROverlay, setCreateQROverlay] = useState(false);

  // Data to check if user can actually change plans
  const user = useFetchUser(token);
  const plans = useFetchPlans();
  const maxLinks = useFetchMaxNOfLinks(token);
  const maxFeatured = maxFeaturedLink(token);

  const [warningQR, setWarningQR] = useState(false);
  const [warningLinks, setWarningLinks] = useState(false);
  const [warningFeatures, setWarningFeatures] = useState(false);

  const [cancelPlanOverlayVisible, setCancelPlanOverlayVisible] = useState(false);

  function showWarningQR() {
    setWarningQR(true);
    setTimeout(() => {
      setWarningQR(false);
    }, 4000);
  }

  function showWarningLinks() {
    setWarningLinks(true);
    setTimeout(() => {
      setWarningLinks(false);
    }, 4000);
  }

  function showWarningFeatures() {
    setWarningFeatures(true);
    setTimeout(() => {
      setWarningFeatures(false);
    }, 4000);
  }

  const tryGoToCheckout = (planInfo) => {
    if (user.qrs > planInfo.qrs) {
      showWarningQR();
      return;
    }
    if (maxLinks > planInfo.links) {
      showWarningLinks();
      return;
    }
    if (maxFeatured > planInfo.featureLevel) {
      showWarningFeatures();
      return;
    }
    navigate(`/checkout/${planInfo._id}`);
  };

  return (
    <div style={globalStyles.row}>
      <Info
        display={warningQR}
        warning
        title="Não foi possível selecionar o plano"
        body="Você possui mais QR Codes que o plano selecionado permite. Por favor, exclua alguns QR Codes ou selecione outro plano."
      />
      <Info
        display={warningLinks}
        warning
        title="Não foi possível selecionar o plano"
        body="Você possui algum QR Code com mais links que o plano selecionado permite. Por favor, exclua alguns links ou selecione outro plano."
      />
      <Info
        display={warningFeatures}
        warning
        title="Não foi possível selecionar o plano"
        body="Você possui algum(ns) Link com features não inclusas no plano selecionado. Por favor, exclua-o(s) links ou selecione outro plano."
      />
      <CancelPlan
        overlay={cancelPlanOverlayVisible}
        setOverlay={setCancelPlanOverlayVisible}
      />
      {
        createQROverlay
          ? (
            <CreateQRForm
              setOverlay={setCreateQROverlay}
              onCreateQRCode={onCreateQRCode}
            />
          ) : false
      }
      <SideBar
        setOverlay={setCreateQROverlay}
        qrList={qrList}
      />
      <div>
        <TopBar setOverlay={setCreateQROverlay} />
        <div style={globalStyles.columnCentered}>
          <p style={globalStyles.title}>Planos</p>
          <div style={globalStyles.spaceEvenlyContainer}>
            {
              plans.map((plan, idx) => (
                <PlanBox
                  key={plan._id}
                  planInfo={plan}
                  userPlan={user.plan}
                  tryGoToCheckout={idx ? tryGoToCheckout : () => setCancelPlanOverlayVisible(true)}
                />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plans;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Buttons
import ButtonNavBar from '../components/Buttons/ButtonNavBar';

// Styles
import logo from '../assets/images/logoname.png';
import onlylogo from '../assets/images/logo.png';
import menu from '../assets/icons/menu.png';
import styles from '../assets/styles/Layout/NavBarStyles';

function NavBar({
  landing, isMobile, onlySignin, onlyLogin, scrollToPlans, scrollToWorking, scrollToProblem, scrollToExamples, scrollToTop,
}) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(-1);

  return (
    <div style={styles.navContainerStyles(isMobile)}>
      {
        landing
          ? (
            <div style={styles.linksContainerStyles(isMobile, landing)}>
              <img alt="logo" src={isMobile ? onlylogo : logo} style={styles.logoStyles(isMobile)} onClick={scrollToTop} />
              <p
                onMouseEnter={() => setHovered(1)}
                onMouseLeave={() => setHovered(-1)}
                style={styles.navLinkStyles(isMobile, hovered === 1)}
                onClick={scrollToWorking}
              >
                Como funciona
              </p>
              <p
                onMouseEnter={() => setHovered(2)}
                onMouseLeave={() => setHovered(-1)}
                style={styles.navLinkStyles(isMobile, hovered === 2)}
                onClick={scrollToProblem}
              >
                Nossa solução
              </p>
              <p
                onMouseEnter={() => setHovered(3)}
                onMouseLeave={() => setHovered(-1)}
                style={styles.navLinkStyles(isMobile, hovered === 3)}
                onClick={scrollToExamples}
              >
                Seu negócio
              </p>
              <p
                onMouseEnter={() => setHovered(4)}
                onMouseLeave={() => setHovered(-1)}
                style={styles.navLinkStyles(isMobile, hovered === 4)}
                onClick={scrollToPlans}
              >
                Planos
              </p>
            </div>
          )
          : (
            <div style={styles.linksContainerStyles(isMobile, landing)}>
              <img alt="logo" src={isMobile ? onlylogo : logo} style={styles.logoStyles(isMobile)} onClick={() => navigate('/')} />
            </div>
          )
            }
      <div style={styles.buttonsContainerStyles(isMobile)}>
        {
                    isMobile && onlyLogin
                      ? <ButtonNavBar label="Entrar" onClick={() => navigate('/login')} />
                      : false
                }
        {
                    isMobile && onlySignin
                      ? <ButtonNavBar label="Criar conta" onClick={() => navigate('/signin')} />
                      : false
                }
        {
                    !isMobile
                      ? <ButtonNavBar label="Entrar" onClick={() => navigate('/login')} />
                      : false
                }
        {
                    !isMobile
                      ? <ButtonNavBar label="Criar conta" onClick={() => navigate('/signin')} />
                      : false
                }
        {
                    isMobile
                      ? <img alt="menu-hamburguer" src={menu} style={styles.menu(isMobile)} />
                      : false
                }
      </div>
    </div>
  );
}

export default NavBar;

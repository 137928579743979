/* eslint-disable import/no-cycle */
import axios from 'axios';
import config from '../../config/host';

import googleCreateUser from './googleCreateUser';

function googleLogUser(email, googleId, nav) {
  axios
    .post(`${config.host}/api/user/googleLogUser`, {
      email, googleId,
    })
    .then((response) => {
      if (response.data === 'Not found') {
        googleCreateUser(nav, email, googleId);
      } else {
        localStorage.setItem('token', response.data);
        nav('/home');
      }
    });
}

export default googleLogUser;

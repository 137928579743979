/* eslint-disable max-len */
import React from 'react';

// Inputs
import ChooseMonths from '../Inputs/MonthInput';
import ChooseWeekDays from '../Inputs/WeekDayInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/AddLinkFormStyles';

function AddLinkFormStep3({
  months, setMonths, weekDays, setWeekDays, nextNode, previousNode,
}) {
  const next = () => {
    nextNode();
  };

  const skip = () => {
    setMonths('Janeiro,Fevereiro,Março,Abril,Maio,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro');
    setWeekDays('Segunda,Terça,Quarta,Quinta,Sexta,Sábado,Domingo');
    nextNode();
  };

  return (
    <div style={styles.headerFormStyles}>
      <p style={styles.titleStyles}>Restrição de Tempo</p>
      <div style={styles.inputsContainer}>
        <ChooseMonths setValue={setMonths} defaultMonths={months} info />
        <ChooseWeekDays setValue={setWeekDays} defaultWeekDays={weekDays} info />
      </div>
      <div style={styles.buttonsContainer}>
        <DefaultButton w="80px" label="Anterior" terciary onClick={previousNode} />
        <div style={styles.rightButtonsContainer}>
          <DefaultButton w="124px" label="Pular" onClick={skip} secundary />
          <DefaultButton w="124px" label="Próximo" onClick={next} />
        </div>
      </div>
    </div>
  );
}

export default AddLinkFormStep3;

import { useState } from 'react';
import getLinksFromUser from '../Link/getLinksFromUser';

function useFetchMaxNOfLinks(token) {
  const [linkList, setLinkList] = useState([]);
  getLinksFromUser(token, setLinkList);
  return linkList.reduce((acc, value) => {
    const timeNLoc = value.locRestriction.split(',').length === 27
            || value.hourRestriction === '0,48'
            || value.timeRestriction.split('-')[0].split(',').length === 12
            || value.timeRestriction.split('-')[1].split(',').length === 7;
    if (!timeNLoc) { return Math.max(acc, 3); }
    if (value.osName.length !== 2 && timeNLoc) { return Math.max(acc, 1); }
    if (value.osName.length === 2 && timeNLoc) { return Math.max(acc, 0); }
    return 0;
  }, 0);
}

export default useFetchMaxNOfLinks;

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config/host';

import getOperatingSystem from '../../logic/decideOs';
import decideLink from '../../logic/decideLink';

import generateTrackingURL from '../../logic/generateUTM';

function useRedirectUser(qrShort, utmSource, utmMedium, utmCampaign, utmTerm, utmContent) {
  const osName = getOperatingSystem();

  const updateScans = (scanLocation, qrId, linkId, url, success, utmUrl) => {
    axios
      .post(`${config.host}/api/scan/createScan`, {
        params: {
          osName, scanLocation, qrId, qrShort, linkId, redirectedTo: url, success, utmUrl, utmSource, utmMedium, utmCampaign, utmTerm, utmContent,
        },
      })
      .then(() => {
        window.location.href = generateTrackingURL(url, utmSource, utmMedium, utmCampaign, utmTerm, utmContent);
      });
  };

  useEffect(() => {
    if (!qrShort) return;
    axios
      .get('https://ipapi.co/json/')
      .then((resLocation) => {
        axios
          .get(`${config.host}/api/link/getLinksFromQRShort`, {
            params: { qrShort },
          })
          .then((response) => {
            const scanLocation = `${resLocation.data.country_name},${resLocation.data.region},${resLocation.data.city}`;
            const [url, success, linkId] = decideLink(response.data, osName, scanLocation);
            updateScans(scanLocation, response.data[0].qrId, linkId, url, success, window.location.href);
          })
          .catch((error) => error);
      });
  }, []);
}

export default useRedirectUser;

import React from 'react';

import styles from '../../assets/styles/Forms/ForgotFormStyles';

import TextInput from '../Inputs/TextInput';
import DefaultButton from '../Buttons/DefaultButton';

function ForgotForm({
  setEmail, emailWarning, onClick, forgot, isMobile,
}) {
  return (
    <div style={styles.forgotFormContainerStyles(isMobile)}>
      {
                forgot
                  ? (
                    <div style={styles.titleContainer}>
                      <p style={styles.titleStyles(isMobile)}>Esqueceu sua senha?</p>
                      <p style={styles.subtitleStyles(isMobile)}>
                        Não se preocupe! Enviaremos um
                        <span style={styles.signinStyles}>link</span>
                        {' '}
                        para alterá-la
                      </p>
                    </div>
                  )
                  : (
                    <div style={styles.titleContainer}>
                      <p style={styles.titleStyles(isMobile)}>Deseja mudar sua senha?</p>
                      <p style={styles.subtitleStyles(isMobile)}>
                        Não se preocupe! Enviaremos um
                        <span style={styles.signinStyles}>link</span>
                        {' '}
                        para alterá-la
                      </p>
                    </div>
                  )
            }
      <div style={styles.inputsStyles}>
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Endereço de email"
          placeholder="Seu email cadastrado"
          setValue={setEmail}
          warningMessage={emailWarning}
        />
      </div>
      <DefaultButton
        w={isMobile ? '80vw' : ''}
        onClick={onClick}
        label="Enviar link"
      />
    </div>
  );
}

export default ForgotForm;

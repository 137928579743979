import axios from 'axios';
import config from '../../config/host';

function updateScansWhenQRUpdated(token, oldShort, qrShort) {
  axios
    .put(`${config.host}/api/scan/updateScansWhenQRUpdated`, {
      headers: { authorization: token },
      params: { oldShort, qrShort },
    })
    .then((response) => response);
}

export default updateScansWhenQRUpdated;

/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import globalStyles from '../Global/globalStyles';
import colors from '../Global/colors';

const styles = {
  logoStyles(isMobile) {
    return {
      ...globalStyles.pointer,
      width: !isMobile ? '320px' : '50px',
    };
  },

  navContainerStyles(isMobile) {
    return {
      ...globalStyles.horizontalSpaceBetween,
      width: '100vw',
      height: !isMobile ? '80px' : '72px',
      backgroundColor: '#FFFFFF',
    };
  },

  linksContainerStyles(isMobile, landing) {
    return {
      display: 'flex',
      justifyContent: landing ? !isMobile ? 'space-around' : 'flex-start' : 'flex-start',
      alignItems: 'center',
      width: !isMobile ? 'min(100% - 360px, 1000px)' : '22vw',
      marginLeft: '26px',
      paddingLeft: '2px',
    };
  },

  buttonsContainerStyles(isMobile) {
    return {
      display: 'flex',
      justifyContent: !isMobile ? 'space-around' : 'flex-end',
      alignItems: 'center',
      width: '360px',
      marginRight: '26px',
    };
  },

  navLinkStyles(isMobile, hovered) {
    return {
      ...globalStyles.pointer,
      fontSize: '20.5px',
      display: !isMobile ? 'flex' : 'none',
      background: 'linear-gradient(#13C47A 0 0) bottom /var(--d, 0) 1.5px no-repeat',
      transition: '.2s',
      '--d': hovered ? '100%' : '0%',
    };
  },

  menu(isMobile) {
    return {
      ...globalStyles.pointer,
      width: '28px',
      height: '32px',
      margin: '0px 10px 0px 30px',
    };
  },
};

export default styles;

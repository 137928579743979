const shuffleString = (string) => {
  let shuffledString = '';
  const stringArray = string.split('');

  while (stringArray.length > 0) {
    const randomIndex = Math.floor(Math.random() * stringArray.length);
    const randomCharacter = stringArray.splice(randomIndex, 1)[0];
    shuffledString += randomCharacter;
  }

  return shuffledString;
};

const generateRandomPassword = (len) => {
  const symbols = ['_', '-', '+', '*', '$', '!', '#'];
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';

  const getRandomCharacter = (characters) => {
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
  };

  let randomString = [
    getRandomCharacter(upperCaseLetters),
    getRandomCharacter(lowerCaseLetters),
    getRandomCharacter(numbers),
    getRandomCharacter(symbols),
  ].join('');

  const characters = upperCaseLetters + lowerCaseLetters + numbers + symbols;
  const characterLength = characters.length;

  for (let i = 0; i < len - 4; i += 1) {
    randomString += characters.charAt(Math.floor(Math.random() * characterLength));
  }

  return shuffleString(randomString);
};

export default generateRandomPassword;

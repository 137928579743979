/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { useState } from 'react';
import Select from 'react-select';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import selectInputStyles from '../../assets/styles/Inputs/SelectInputStyles';

import questionMark from '../../assets/icons/info.png';

function SelectInput({
  label, options, setValue, value, w, border, isMulti, warningMessage, placeholder, info, infoTitle, infoDescription, infoW, reseted,
}) {
  const opt = options.map((el) => ({ value: el.toLowerCase(), label: el }));

  let style = selectInputStyles.defaultSelectInput;
  if (border === 'left') { style = selectInputStyles.leftSelectInput; } else
  if (border === 'right') { style = selectInputStyles.rightSelectInput; } else
  if (border === 'none') { style = selectInputStyles.noBorderSelectInput; }

  const [val, setVal] = useState([]);
  const [empty, setEmpty] = useState(false);

  function handleChange(e) {
    isMulti ? setValue(e.map((el) => el.label)) : setValue(e.label);
    isMulti ? setVal(e.map((el) => el.label)) : setVal(e.label);
    if (isMulti && !e.map((el) => el.label).length) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }

  const popover = (
    <Popover style={infoW ? { ...selectInputStyles.popover, width: infoW } : selectInputStyles.popover}>
      <Popover.Header style={selectInputStyles.popoverHeader}>{infoTitle}</Popover.Header>
      <Popover.Body style={selectInputStyles.popoverBody}>
        {infoDescription}
      </Popover.Body>
      <div style={selectInputStyles.point} />
    </Popover>
  );

  return (
    <div>
      <div style={w ? { ...selectInputStyles.defaultLabelContainer, width: w } : { ...selectInputStyles.defaultLabelContainer }}>
        <p style={selectInputStyles.defaultLabel}>{label}</p>
      </div>
      {
        info
          ? (
            <div style={{ position: 'relative' }}>
              <Select
                options={opt}
                isMulti={isMulti}
                placeholder={placeholder}
                value={reseted ? opt : val.length || empty ? val.map((el) => ({ value: el.toLowerCase(), label: el })) : value[0] ? value.map((el) => ({ value: el.toLowerCase(), label: el })) : []}
                styles={{ control: (baseStyles) => ({ ...baseStyles, ...style, width: w }) }}
                onChange={(e) => handleChange(e)}
              />
              <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <img alt="question-mark" src={questionMark} style={selectInputStyles.info} />
              </OverlayTrigger>
            </div>
          )
          : (
            <Select
              options={opt}
              isMulti={isMulti}
              placeholder={placeholder}
              value={reseted ? opt : val.length || empty ? val.map((el) => ({ value: el.toLowerCase(), label: el })) : value[0] ? value.map((el) => ({ value: el.toLowerCase(), label: el })) : []}
              styles={{ control: (baseStyles) => ({ ...baseStyles, ...style, width: w }) }}
              onChange={(e) => handleChange(e)}
            />
          )
        }
      <div style={w ? { ...selectInputStyles.warningMessageContainer, width: w } : { ...selectInputStyles.warningMessageContainer }}>
        <p style={selectInputStyles.warningMessage}>{warningMessage}</p>
      </div>
    </div>
  );
}

export default SelectInput;

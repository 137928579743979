import React, { useState } from 'react';

import styles from '../../assets/styles/Forms/AddLinkFormStyles';

import DefaultButton from '../Buttons/DefaultButton';

import ChooseStates from '../Inputs/StateInput';

function AddLinkFormStep5({ setLocRestriction, createLink, previousNode }) {
  const [stateScreen, setStateScreen] = useState(false);

  return (
    <div style={styles.headerFormStyles}>
      <p style={styles.titleStyles}>Restrição de Localidade</p>
      <div style={styles.inputsContainer}>
        <ChooseStates setStatesScreen={setStateScreen} setValue={setLocRestriction} />
      </div>
      {
                !stateScreen
                  ? (
                    <div style={styles.buttonsContainer}>
                      <DefaultButton w="80px" label="Anterior" terciary onClick={previousNode} />
                      <DefaultButton w="160px" label="Criar link" onClick={createLink} />
                    </div>
                  )
                  : false
            }
    </div>
  );
}

export default AddLinkFormStep5;

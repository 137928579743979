/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import styled from 'styled-components';
import { useRanger } from 'react-ranger';
import colors from '../../assets/styles/Global/colors';

import clock from '../../assets/icons/grey-clock.png';

import LittleButton from './LittleButton';

export const Track = styled('div')`
  display: inline-block;
  height: 6px;
  width: 90%;
  margin: 0 5%;
`;

export const Tick = styled('div')`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    height: 8px;
    width: 2px;
    transform: translate(-50%, 0.6rem);
  }
`;

export const TickLabel = styled('div')`
  position: absolute;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.4rem);
  white-space: nowrap;
`;

export const Segment = styled('div')`
  background: ${(props) => (props.index === 0
    ? colors.grey
    : props.index === 1
      ? colors.green
      : props.index === 2
        ? colors.grey
        : colors.grey)};
  height: 100%;
`;

export const Handle = styled('div')`
    background: ${colors.green};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 1.5rem;
    border-radius: 24%;
    font-size: 0.8rem;
    white-space: nowrap;
    color: white;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    transform: ${(props) => (props.active ? 'translateY(-100%) scale(1.3)' : 'translateY(0) scale(0.9)')};
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer
    `;

function TimeRange({ setTimeRange }) {
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState([0, 48]);

  const handleChange = (vals) => {
    setValues(vals);
    setTimeRange(vals);
  };

  const {
    getTrackProps, ticks, segments, handles,
  } = useRanger({
    min: 0,
    max: 48,
    stepSize: 1,
    values,
    onChange: handleChange,
  });

  const styles = {
    container: {
      width: '184px',
      height: '44px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderColor: colors.grey,
      padding: '0px 20px 0px 20px',
      color: colors.darkgrey,
      justifyContent: 'space-between',
      cursor: 'pointer',
      margin: '0px 20px 0px 0px',
    },

    icon: {
      width: '20px',
      height: '20px',
    },

    closeIcon: {
      width: '16px',
      height: '16px',
      cursor: 'pointer',
      margin: '24px 20px 0px 0px',
    },

    checkIcon: {
      width: '26px',
      height: '26px',
      cursor: 'pointer',
      marginTop: '24px',
    },

    label: {
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      marginBottom: '6px',
    },

    containerSelector: {
      display: 'flex',
      paddingTop: '26px',
      flexDirection: 'column',
      position: 'absolute',
      border: '1px solid #EFF2F7',
      padding: '24px 20px 0px 20px',
      borderRadius: '12px',
      marginTop: '10px',
      width: '420px',
      height: '110px',
      backgroundColor: '#FFFFFF',
      alignItems: 'flex-end',
      zIndex: 4,
    },

    buttonContainer: {
      display: 'flex',
      margin: '42px 0px 0px 0px',
    },
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <p style={styles.label}>Período do dia</p>
          <div style={styles.container} onClick={() => setVisible(!visible)}>
            {
              values[0] === 0 && values[1] === 48
                ? <p>Dia todo</p>
                : (
                  <p>
                    {values[0] % 2 ? `${(values[0] - 1) / 2}h30` : `${values[0] / 2}h00`}
                    {' '}
                    -
                    {' '}
                    {values[1] % 2 ? `${(values[1] - 1) / 2}h30` : `${values[1] / 2}h00`}
                  </p>
                )
             }
            <img alt="clock" src={clock} style={styles.icon} />
          </div>
        </div>
      </div>
      {
                visible
                  ? (
                    <div style={styles.containerSelector}>
                      <Track {...getTrackProps()}>
                        {ticks.map(({ value, getTickProps }) => (
                          <Tick {...getTickProps()}>
                            <TickLabel>{`${value / 2}h`}</TickLabel>
                          </Tick>
                        ))}
                        {segments.map(({ getSegmentProps }, i) => (
                          <Segment {...getSegmentProps()} index={i} />
                        ))}
                        {handles.map(({ value, active, getHandleProps }) => (
                          <button
                            {...getHandleProps({
                              style: {
                                appearance: 'none',
                                border: 'none',
                                background: 'transparent',
                                outline: 'none',
                              },
                            })}
                          >
                            <Handle active={active}>{value % 2 ? `${(value - 1) / 2}h30` : `${value / 2}h00`}</Handle>
                          </button>
                        ))}
                      </Track>
                      <div style={styles.buttonContainer}>
                        <LittleButton text="Cancelar" cancel onClick={setVisible} />
                        <LittleButton text="Confirmar" onClick={setVisible} />
                      </div>
                    </div>
                  )
                  : false
            }
    </div>
  );
}

export default TimeRange;

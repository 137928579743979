/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';

import styles from '../../assets/styles/Inputs/ChooseStatesStyles';

import back from '../../assets/icons/back.png';

function ChooseStates({
  setValue, defaultStates, setStatesScreen, reseted, withoutSelectAll,
}) {
  const allStates = [
    {
      name: 'Norte',
      states: [
        { name: 'Acre', sigla: 'AC', selected: false },
        { name: 'Amapá', sigla: 'AP', selected: false },
        { name: 'Amazonas', sigla: 'AM', selected: false },
        { name: 'Pará', sigla: 'PA', selected: false },
        { name: 'Rondônia', sigla: 'RO', selected: false },
        { name: 'Roraima', sigla: 'RR', selected: false },
        { name: 'Tocantins', sigla: 'TO', selected: false },
      ],
    },
    {
      name: 'Nordeste',
      states: [
        { name: 'Alagoas', sigla: 'AL', selected: false },
        { name: 'Bahia', sigla: 'BA', selected: false },
        { name: 'Ceará', sigla: 'CE', selected: false },
        { name: 'Maranhão', sigla: 'MA', selected: false },
        { name: 'Paraíba', sigla: 'PB', selected: false },
        { name: 'Pernambuco', sigla: 'PE', selected: false },
        { name: 'Piauí', sigla: 'PI', selected: false },
        { name: 'Rio Grande do Norte', sigla: 'RN', selected: false },
        { name: 'Sergipe', sigla: 'SE', selected: false },
      ],
    },
    {
      name: 'Sudeste',
      states: [
        { name: 'Espírito Santo', sigla: 'ES', selected: false },
        { name: 'Minas Gerais', sigla: 'MG', selected: false },
        { name: 'Rio de Janeiro', sigla: 'RJ', selected: false },
        { name: 'São Paulo', sigla: 'SP', selected: false },
      ],
    },
    {
      name: 'Centro-Oeste',
      states: [
        { name: 'Distrito Federal', sigla: 'DF', selected: false },
        { name: 'Goiás', sigla: 'GO', selected: false },
        { name: 'Mato Grosso', sigla: 'MT', selected: false },
        { name: 'Mato Grosso do Sul', sigla: 'MS', selected: false },
      ],
    },
    {
      name: 'Sul',
      states: [
        { name: 'Paraná', sigla: 'PR', selected: false },
        { name: 'Rio Grande do Sul', sigla: 'RS', selected: false },
        { name: 'Santa Catarina', sigla: 'SC', selected: false },
      ],
    },
  ];

  const [selectedRegion, setSelectedRegion] = useState('None');
  const [states, setStates] = useState(allStates.map((region) => {
    if (!defaultStates) return region;
    for (const st of region.states) {
      if (defaultStates.includes(st.sigla)) {
        st.selected = true;
      }
    }
    return region;
  }));

  useEffect(() => {
    setStates(allStates.map((region) => {
      if (!defaultStates) return region;
      for (const st of region.states) {
        if (defaultStates.includes(st.sigla)) {
          st.selected = true;
        }
      }
      return region;
    }));
  }, [defaultStates]);

  if (reseted && states.reduce((acc, region) => {
    for (const st of region.states) {
      if (st.selected) {
        acc += 1;
      }
    }
    return acc;
  }, 0) !== 27) {
    setStates(states.map((region) => {
      for (const st of region.states) {
        st.selected = true;
      }
      return region;
    }));
  }

  const updateRestriction = () => {
    setValue(states.reduce((acc, region) => {
      for (const st of region.states) {
        if (st.selected) {
          acc += `${st.sigla},`;
        }
      }
      return acc;
    }, '').slice(0, -1));
  };

  return (
    <div>
      <div style={styles.regionsContainer}>
        {
                    selectedRegion === 'None'
                      ? states.map((region) => (
                        <div
                          style={styles.regionContainer}
                          key={region.name}
                          onClick={() => {
                            setSelectedRegion(region.name);
                            setStatesScreen(true);
                          }}
                        >
                          <p style={styles.regionName}>{region.name}</p>
                          <p style={
                                    region.states.filter((state) => state.selected).length === region.states.length
                                      ? styles.allSelectedStates
                                      : styles.numberOfSelectedStates
}
                          >
                            {region.states.filter((state) => state.selected).length}
                          </p>
                        </div>
                      ))
                      : (
                        <div style={styles.statesContainer}>
                          <div style={styles.headerStates}>
                            <img
                              src={back}
                              style={styles.backIcon}
                              onClick={() => {
                                setSelectedRegion('None');
                                setStatesScreen(false);
                              }}
                            />
                            <p
                              style={styles.regionNameHeader}
                              onClick={() => {
                                setStates(states.map((region) => {
                                  if (region.name === selectedRegion) {
                                    for (const st of region.states) {
                                      st.selected = true;
                                    }
                                  }
                                  return region;
                                }));
                                updateRestriction();
                              }}
                            >
                              {selectedRegion}
                            </p>
                          </div>
                          <div style={styles.bodyStates}>
                            {
                            states.reduce((acc, region) => {
                              if (region.name === selectedRegion) {
                                acc.push(...region.states);
                              }
                              return acc;
                            }, []).map((state) => (
                              <div
                                key={state.sigla}
                                style={state.selected ? styles.selectedState : styles.notSelectedState}
                                onClick={() => {
                                  setStates(states.map((region) => {
                                    for (const st of region.states) {
                                      if (st.name === state.name) st.selected = !st.selected;
                                    }
                                    return region;
                                  }));
                                  updateRestriction();
                                }}
                              >
                                <p>{state.name}</p>
                              </div>
                            ))
                        }
                          </div>
                        </div>
                      )
                }
      </div>
      {
                selectedRegion === 'None'
                  ? !withoutSelectAll
                    ? (
                      <div style={styles.selectAllContainer}>
                        <p>ou...</p>
                        <p
                          style={styles.selectAll}
                          onClick={() => {
                            setStates(states.map((region) => {
                              for (const st of region.states) {
                                st.selected = true;
                              }
                              return region;
                            }));
                            updateRestriction();
                          }}
                        >
                          Selecionar todos
                        </p>
                      </div>
                    )
                    : false
                  : false
            }
    </div>
  );
}

export default ChooseStates;

import React, { useState } from 'react';

// Hooks
import useFetchQRFromUser from '../hooks/QR/useFetchQRFromUser';

// Layout
import SideBar from '../layouts/SideBar';
import TopBar from '../layouts/TopBar';

// Forms
import CreateQRForm from '../components/Forms/CreateQRForm';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function AboutUs() {
  // Token (session)
  const token = localStorage.getItem('token');

  // All QR Codes from the user
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  const onCreateQRCode = (newQRCode) => setQRList((prev) => [...prev, newQRCode]);
  const [createQROverlay, setCreateQROverlay] = useState(false);

  return (
    <div style={globalStyles.row}>
      {
        createQROverlay
          ? (
            <CreateQRForm
              setOverlay={setCreateQROverlay}
              onCreateQRCode={onCreateQRCode}
            />
          ) : false
      }
      <SideBar
        qrList={qrList}
      />
      <div>
        <TopBar
          setOverlay={setCreateQROverlay}
        />
      </div>
    </div>
  );
}

export default AboutUs;

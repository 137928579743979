/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import createUser from '../hooks/User/createUser';

// Layout
import NavBar from '../layouts/NavBar';

// Forms
import SigninForm from '../components/Forms/SigninForm';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Signin() {
  const navigate = useNavigate();

  // Token (session)
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) navigate('/home');
  });

  // Checking width for responsiveness purposes
  const [width, setWidth] = useState(Number(window.innerWidth));
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isMobile = width <= 768;

  // Signin form variables
  const [email, setEmail] = useState('');
  const [emailWarning, setEmailWarning] = useState('');

  const [password, setPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState('');

  const handleEmail = (emailInputed) => {
    setEmail(emailInputed);
    const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailInputed);
    setEmailWarning(!validEmail ? 'Email inválido' : '');
  };

  const handlePassword = (passwordInputed) => {
    setPassword(passwordInputed);
    setPasswordWarning(passwordInputed.length < 8 ? 'Insira no mínimo 8 caracteres' : '');
    setConfirmPasswordWarning((passwordInputed !== confirmPassword) && confirmPassword ? 'As senhas são diferentes' : '');
  };

  const handleConfirmPassword = (confirmPasswordInputed) => {
    setConfirmPassword(confirmPasswordInputed);
    setConfirmPasswordWarning(password !== confirmPasswordInputed ? 'As senhas são diferentes' : '');
  };

  const tryCreateUser = () => {
    if (!email) setEmailWarning('Insira um email');
    if (!password) setPasswordWarning('Insira uma senha');
    if (!confirmPassword && password) setConfirmPasswordWarning('Confirme sua senha');
    if (email && password && confirmPassword && !emailWarning && !passwordWarning && !confirmPasswordWarning) {
      createUser(navigate, email, setEmail, password, setPassword, setConfirmPassword, emailWarning, setEmailWarning, passwordWarning, confirmPasswordWarning);
    }
  };

  return (
    <div style={globalStyles.columnCentered}>
      <NavBar isMobile={isMobile} onlyLogin />
      <SigninForm
        isMobile={isMobile}
        userEmail={email}
        setEmail={handleEmail}
        emailWarning={emailWarning}
        password={password}
        setPassword={handlePassword}
        passwordWarning={passwordWarning}
        confirmPassword={confirmPassword}
        setConfirmPassword={handleConfirmPassword}
        confirmPasswordWarning={confirmPasswordWarning}
        onClick={tryCreateUser}
      />
    </div>
  );
}

export default Signin;

import React, { useState } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import useFetchQRFromUser from '../hooks/QR/useFetchQRFromUser';

// Layout
import SideBar from '../layouts/SideBar';
import TopBar from '../layouts/TopBar';

// Forms
import CreateQRForm from '../components/Forms/CreateQRForm';

function Home() {
  const navigate = useNavigate();

  // Token (session)
  const token = localStorage.getItem('token');
  if (!token) navigate('/login');

  // All QR Codes from the user
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  const onCreateQRCode = (newQRCode) => setQRList((prev) => [...prev, newQRCode]);
  const [createQROverlay, setCreateQROverlay] = useState(false);

  return (
    <div style={{ display: 'flex' }}>
      {
        createQROverlay
          ? (
            <CreateQRForm
              setOverlay={setCreateQROverlay}
              onCreateQRCode={onCreateQRCode}
            />
          ) : false
      }
      <SideBar
        qrList={qrList}
        setOverlay={setCreateQROverlay}
      />
      <TopBar
        setOverlay={setCreateQROverlay}
      />
    </div>
  );
}

export default Home;

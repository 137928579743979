/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React from 'react';

import styles from '../../assets/styles/LinkStyles';
import DefaultButton from '../Buttons/DefaultButton';

import LinkBox from './LinkBox';

function Links({
  links, defaultLink, setOverlay, setEditOverlay, setDeleteLinkOverlay, selectLink, scans,
}) {
  return (
    <div style={styles.container}>
      <div style={styles.linksContainer}>
        <div style={styles.links}>
          <p style={styles.linkDescription}>Principal</p>
          <LinkBox
            linkName="Link de escape"
            url={defaultLink}
            main
            setEditOverlay={setEditOverlay}
            setDeleteLinkOverlay={setDeleteLinkOverlay}
            accesses={scans.filter((scan) => (scan.redirectedTo === defaultLink)).length}
          />
          <p style={styles.linkDescription}>Outros</p>
          {
            links.filter((el) => !el.default).map((el) => (
              <LinkBox
                link={el}
                selectLink={selectLink}
                linkName={el.linkName}
                url={el.url}
                key={el._id}
                linkId={el._id}
                setEditOverlay={setEditOverlay}
                setDeleteLinkOverlay={setDeleteLinkOverlay}
                accesses={scans.filter((scan) => (scan.redirectedTo === el.url)).length}
              />
            ))
          }
        </div>
      </div>
      <div style={styles.button}>
        <DefaultButton label="Adicionar novo link" w="24%" secundary onClick={() => setOverlay(true)} />
      </div>
    </div>
  );
}

export default Links;

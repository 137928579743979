/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import styles from '../../assets/styles/SelectorStyles';

function Selector({
  selected, options, setSelector, utm,
}) {
  if (!utm) {
    return (
      <div style={styles.container(options.length)}>
        {
          options.map((el, idx) => (
            <div
              style={idx === options.length - 1 ? styles.disabled : idx === selected ? styles.selected : styles.notSelected}
              onClick={() => {
                if (idx !== options.length - 1) setSelector(idx);
              }}
              key={idx}
            >
              <p style={styles.text}>{el}</p>
            </div>
          ))
        }
      </div>
    );
  }

  return (
    <div style={styles.container(options.length)}>
      {
        options.map((el, idx) => (
          <div style={idx === selected ? styles.selected : styles.notSelected} onClick={() => setSelector(idx)} key={idx}>
            <p style={styles.text}>{el}</p>
          </div>
        ))
      }
    </div>
  );
}

export default Selector;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config/host';

// Logic
import generateTrackingURL from '../../logic/generateUTM';
import extractTrackingParameters from '../../logic/extractTrackingParameters';

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/CreateUTMLinkFormStyles';
import close from '../../assets/icons/close.png';

function EditUTMForm({
  setOverlay, utmLink, onEditUTM,
}) {
  const token = localStorage.getItem('token');

  const params = extractTrackingParameters(utmLink.url);

  const [source, setSource] = useState(params.source || '');
  const [medium, setMedium] = useState(params.medium || '');
  const [campaign, setCampaign] = useState(params.campaign || '');
  const [term, setTerm] = useState(params.term || '');
  const [content, setContent] = useState(params.content || '');

  const closeOverlay = () => {
    setSource('');
    setMedium('');
    setCampaign('');
    setTerm('');
    setContent('');
    setOverlay(false);
  };

  const tryUpdatingUTMLink = () => {
    const newUrl = generateTrackingURL(
      params.redirect,
      source,
      medium,
      campaign,
      term,
      content,
    );
    axios
      .put(`${config.host}/api/utm/updateUTM`, {
        headers: { authorization: token },
        params: {
          utmId: utmLink._id,
          url: newUrl,
        },
      })
      .then(() => onEditUTM({
        utmId: utmLink._id,
        url: newUrl,
      }));
    closeOverlay();
  };

  return (
    <div style={styles.background}>
      <div style={{ ...styles.containerStyles, position: 'relative' }}>
        <img alt="close" src={close} style={styles.close} onClick={closeOverlay} />
        <p style={styles.titleStyles}>Editar UTM Link</p>
        <div style={styles.inputsStyles}>
          <TextInput
            w="12vw"
            label="Source"
            placeholder="source"
            setValue={setSource}
            value={source}
          />
          <TextInput
            w="12vw"
            label="Medium"
            placeholder="medium"
            setValue={setMedium}
            value={medium}
          />
          <TextInput
            w="12vw"
            label="Campaign"
            placeholder="campaign"
            setValue={setCampaign}
            value={campaign}
          />
          <TextInput
            w="12vw"
            placeholder="term"
            label="Term"
            setValue={setTerm}
            value={term}
          />
          <TextInput
            w="12vw"
            placeholder="content"
            label="Content"
            setValue={setContent}
            value={content}
          />
        </div>
        <p style={{ fontSize: '17px', alignSelf: 'flex-start', marginLeft: '4%' }}>
          Prévia:
          {' '}
          <span style={{ fontWeight: '500', textDecoration: 'underline' }}>
            {
            generateTrackingURL(
              params.redirect,
              source,
              medium,
              campaign,
              term,
              content,
            )
          }
          </span>
        </p>
        <DefaultButton
          label="Criar UTM link"
          onClick={tryUpdatingUTMLink}
        />
      </div>
    </div>
  );
}

export default EditUTMForm;

import globalStyles from '../Global/globalStyles';
import colors from '../Global/colors';

const styles = {
  loginFormContainerStyles(isMobile) {
    return {
      ...globalStyles.center,
      ...globalStyles.column,
      width: !isMobile ? '540px' : '100vw',
      height: 'calc(100vh - 100px)',
    };
  },

  titleStyles(isMobile) {
    return {
      fontSize: !isMobile ? '40px' : '28px',
      fontWeight: 'bold',
      letterSpacing: '0.9px',
    };
  },

  subtitleStyles(isMobile) {
    return {
      fontSize: !isMobile ? '20px' : '16px',
      marginTop: '4px',
      marginBottom: '16px',
    };
  },

  signinStyles: {
    ...globalStyles.pointer,
    color: colors.green,
    fontWeight: 'bold',
  },

  inputsStyles(isMobile) {
    return {
      ...globalStyles.column,
      height: '230px',
      justifyContent: 'space-around',
      width: !isMobile ? '' : '80vw',
    };
  },
};

export default styles;

import React from 'react';
import ReactDOM from 'react-dom/client';

// Google Analytics
import GA4React from 'ga-4-react';

// Google Tag Manager
import TagManager from 'react-gtm-module';

// Google Authentication
import { GoogleOAuthProvider } from '@react-oauth/google';

// Navigation
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

// All pages
import Landing from './routes/Landing';
import Signin from './routes/Signin';
import Login from './routes/Login';
import Profile from './routes/Profile';
import QR from './routes/QR';
import Home from './routes/Home';
import Checkout from './routes/Checkout';
import Plans from './routes/Plans';
import AboutUs from './routes/AboutUs';
import Support from './routes/Support';
import Forgot from './routes/Forgot';
import Reset from './routes/Reset';

// Global CSS
import './assets/styles/Global/global.css';

// Initialize GA4
const ga4react = new GA4React(process.env.REACT_APP_GA_TRACKING_ID);
ga4react.initialize().then().catch();

// Initialize GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
};
TagManager.initialize(tagManagerArgs);

// All routes and pages loaded
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:qrShort?" element={<Landing />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/qr/:qrShort?" element={<QR />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/support" element={<Support />} />
        <Route path="/checkout/:planId" element={<Checkout />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/reset" element={<Reset />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <App />
  </GoogleOAuthProvider>,
);

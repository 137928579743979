/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import config from '../../config/host';

// Navigation

// Hooks
import googleCreateUser from '../../hooks/User/googleCreateUser';

// Google

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import ButtonLogin from '../Buttons/DefaultButton';
import ContinueWithGoogle from '../Buttons/ContinueWithGoogle';

// Layout
import LineOr from '../Layout/LineOr';

// Styles
import styles from '../../assets/styles/Forms/SigninFormStyles';

function LoginForm({
  setEmail, userEmail, password, setPassword, confirmPassword, setConfirmPassword, onClick, emailWarning, passwordWarning, confirmPasswordWarning, isMobile,
}) {
  const navigate = useNavigate();

  async function createUser(access_token) {
    const { data } = await axios({
      url: 'https://www.googleapis.com/oauth2/v2/userinfo',
      method: 'get',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const { email, id } = data;
    googleCreateUser(navigate, email, id);
  }

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const tokens = await axios.post(`${config.host}/api/user/google`, {
        code: codeResponse.code,
      });

      createUser(tokens.data.access_token);
    },
    flow: 'auth-code',
    // onError: (error) => console.log('Login Failed:', error)
  });

  return (
    <div style={styles.signInFormContainerStyles(isMobile)}>
      <p style={styles.titleStyles(isMobile)}>Crie sua conta</p>
      <p style={styles.subtitleStyles(isMobile)}>
        Já tem uma conta?
        <span style={styles.loginStyles} onClick={() => navigate('/login')}> Entre aqui</span>
      </p>
      <div style={styles.inputsStyles(isMobile)}>
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Endereço de email"
          placeholder="seuemail@exemplo.com"
          setValue={setEmail}
          value={userEmail}
          warningMessage={emailWarning}
        />
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Senha"
          placeholder="Insira 8 caracteres ou mais"
          setValue={setPassword}
          value={password}
          warningMessage={passwordWarning}
          hide
        />
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Confirme sua senha"
          placeholder="Confirme a senha"
          setValue={setConfirmPassword}
          value={confirmPassword}
          warningMessage={confirmPasswordWarning}
          hide
        />
      </div>
      <ButtonLogin
        w={isMobile ? '80vw' : ''}
        onClick={onClick}
        label="Criar conta"
      />
      <LineOr w={isMobile ? '33vw' : '184px'} />
      <ContinueWithGoogle
        label={isMobile ? 'Login com Google' : 'Continue com o Google'}
        w={isMobile ? '80vw' : ''}
        onClick={login}
      />
    </div>
  );
}

export default LoginForm;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import styles from '../assets/styles/PlansStyles';

import DefaultButton from './Buttons/DefaultButton';

import qrcode from '../assets/icons/qrcode.png';
import close from '../assets/icons/no.png';
import link from '../assets/icons/link.png';
import tracking from '../assets/icons/tracking.png';
import clock from '../assets/icons/clock.png';
import target from '../assets/icons/target.png';

function PlanBox({
  planInfo, userPlan, tryGoToCheckout, isMobile,
}) {
  const plan = planInfo || { productFeatures: [] };
  const selected = userPlan === plan._id;

  return (
    <div style={selected ? styles.selectedBox : styles.box(isMobile)}>
      <p style={styles.planTitle}>{plan.productName}</p>
      <div style={styles.featuresContainer(isMobile)}>
        {
          plan.productFeatures.map((el, idx) => (
            <div style={el.included ? styles.feature(isMobile) : styles.featureUnabled(isMobile)} key={el.feature}>
              {
                el.included
                  ? idx === 0
                    ? <img src={qrcode} style={styles.icon} />
                    : idx === 1
                      ? <img src={tracking} style={styles.icon} />
                      : idx === 2
                        ? <img src={link} style={styles.icon} />
                        : idx === 3
                          ? <img src={clock} style={styles.icon} />
                          : idx === 4
                            ? <img src={target} style={styles.icon} />
                            : false
                  : <img src={close} style={styles.icon} />
                }
              <p>{el.feature}</p>
            </div>
          ))
                }
      </div>
      <div style={styles.priceContainer}>
        <p style={styles.price(isMobile)}>
          <span style={styles.cifra}>R$ </span>
          {plan.productPrice}
          <span style={styles.permonth}>/mês</span>
        </p>
      </div>
      {
        selected
          ? <DefaultButton label="Seu plano" disabled w="94%" />
          : <DefaultButton label="Escolher" w="94%" onClick={() => tryGoToCheckout(planInfo)} />
      }
    </div>
  );
}

export default PlanBox;

import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config/host';

function useFetchQRFromShort(token, qrShort, setQR) {
  useEffect(() => {
    axios
      .get(`${config.host}/api/qr/getQRFromShort`, {
        headers: { authorization: token },
        params: { qrShort },
      })
      .then((response) => setQR(response.data[0]));
  }, []);
}

export default useFetchQRFromShort;

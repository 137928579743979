/* eslint-disable no-unused-vars */
import globalStyles from '../Global/globalStyles';
import colors from '../Global/colors';

const styles = {
  sideBarContainer: {
    ...globalStyles.column,
    height: '100vh',
    width: '310px',
    padding: '26px',
    borderRight: `solid 1px ${colors.grey}`,
  },

  header: {
    height: '190px',
  },

  logo: {
    ...globalStyles.pointer,
    width: '100%',
    margin: '20px 0px 36px 0px',
  },

  sessionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '20px 0px 24px 0px',
    letterSpacing: '0.9px',
  },

  itemContainer(hover, clicked) {
    return {
      ...globalStyles.horizontalSpaceBetween,
      ...globalStyles.pointer,
      height: '40px',
      alignItems: 'center',
      margin: '0px 10px 8px 10px',
      backgroundColor: hover || clicked ? 'rgba(19, 196, 122, 0.2)' : '#FFFFFF',
      border: clicked ? '1px solid rgba(19, 196, 122, 0.6)' : '0px',
      padding: '10px',
      borderRadius: '6px',
    };
  },

  searchContainer(hover, clicked) {
    return {
      ...globalStyles.horizontalSpaceBetween,
      cursor: 'text',
      height: '40px',
      alignItems: 'center',
      margin: '0px 10px 8px 10px',
      backgroundColor: hover && !clicked ? 'rgba(19, 196, 122, 0.2)' : '#FFFFFF',
      paddingLeft: '10px',
      borderRadius: '6px',
      border: clicked ? '1px solid rgba(19, 196, 122, 0.6)' : '0px',
    };
  },

  searchInput(clicked, hover) {
    return {
      border: '0',
      outline: '0',
      fontSize: '20px',
      width: '180px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    };
  },

  itemContent: {
    ...globalStyles.row,
    ...globalStyles.pointer,
    alignItems: 'center',
  },

  selectAdd(display) {
    return {
      ...globalStyles.column,
      display: display ? 'flex' : 'none',
      zIndex: '4',
      position: 'absolute',
      width: '300px',
      height: '120px',
      backgroundColor: '#FFFFFF',
      alignSelf: 'start',
      left: '282px',
      borderRadius: '8px',
      border: `1px solid ${colors.grey}`,
      padding: '16px 20px',
    };
  },

  itemToAdd(hover) {
    return {
      ...globalStyles.pointer,
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 10px',
      fontSize: '18px',
      backgroundColor: hover ? 'rgba(19, 196, 122, 0.2)' : '#FFFFFF',
      borderRadius: '6px',
    };
  },

  subItemIcon: {
    width: '26px',
    marginRight: '10px',
  },

  searchContent: {
    ...globalStyles.row,
    cursor: 'text',
    alignItems: 'center',
  },

  allQRsContainer: {
    height: 'calc(100% - 400px)',
    padding: '10px',
    overflow: 'auto',
  },

  qrContainer(hover) {
    return {
      ...globalStyles.verticalAlign,
      ...globalStyles.littleRoundedBorder,
      ...globalStyles.pointer,
      border: 0,
      paddingLeft: '14px',
      margin: '0px 0px 10px 0px',
      height: '42px',
      backgroundColor: hover ? colors.transparentgreen : '#FFFFFF',
    };
  },

  qrContainerSelected: {
    ...globalStyles.verticalAlign,
    ...globalStyles.littleRoundedBorder,
    ...globalStyles.pointer,
    paddingLeft: '14px',
    margin: '0px 0px 10px 0px',
    height: '42px',
    backgroundColor: colors.transparentgreen,
    fontWeight: '500',
    border: '1px solid rgba(19, 196, 122, 0.6)',
  },

  itemTitle: {
    fontSize: '20px',
  },

  qrTitle: {
    fontSize: '18px',
  },

  sideBarIcons: {
    ...globalStyles.pointer,
    width: '20px',
    marginRight: '10px',
  },

  sideBarEnding: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '180px',
  },

  newFolderContainer: {
    ...globalStyles.pointer,
    ...globalStyles.row,
    alignItems: 'center',
    height: '30px',
    marginBottom: '12px',
  },

  newFolderInput: {
    border: '0',
    outline: '0',
    height: '22px',
    fontSize: '18px',
  },

  arrowIcon(selected) {
    return {
      width: '12px',
      transform: selected ? 'rotate(90deg)' : 'rotate(0deg)',
      marginRight: '12px',
    };
  },

  qrsInsideFolder: {
    marginLeft: '20px',
  },
};

export default styles;

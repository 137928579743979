/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

// Layout
import Line from '../Layout/Line';

// Icons and images
import copy from '../../assets/icons/copy.png';

import styles from '../../assets/styles/MinHeaderInfoQRStyles';

function MinHeaderInfoQR({
  qrName, url,
}) {
  const [hover, setHover] = useState(false);
  const [copied, setCopied] = useState(false);

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    setCopied(true);
  }

  return (
    <div>
      <div style={styles.headerInfoContainerStyles}>
        <div style={styles.infoContainerStyles}>
          <div style={styles.headerTitle}>
            <p style={styles.titleStyles}>{qrName}</p>
            <div style={styles.iconsContainer}>
              <div style={styles.underLine}>
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => copyToClipboard(url)}
                  onMouseEnter={() => {
                    if (!copied) setHover(true);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                    setCopied(false);
                  }}
                >
                  <p style={{ fontSize: '20px' }}>{url}</p>
                  <img alt="copy-to-clipboard" src={copy} style={{ width: '20px', height: '20px', marginLeft: '16px' }} />
                  <div style={styles.copyToClipboard(hover, copied)}>
                    <div style={styles.chat} />
                    <p>{copied ? 'Copiado!' : hover ? 'Copiar para área de transferência' : false}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Line mt="10px" mb="10px" />
        </div>
      </div>

    </div>
  );
}

export default MinHeaderInfoQR;

import globalStyles from './Global/globalStyles';
import colors from './Global/colors';

const styles = {

  box(isMobile) {
    return {
      ...globalStyles.column,
      ...globalStyles.littleRoundedBorder,
      borderColor: colors.grey,
      alignItems: ' center',
      padding: !isMobile ? '28px' : '28px 16px',
      borderRadius: '18px',
      width: !isMobile ? 'calc((100vw - 180px)/4)' : '84%',
      height: !isMobile ? '88%' : '70vh',
    };
  },

  selectedBox: {
    ...globalStyles.column,
    ...globalStyles.littleRoundedBorder,
    borderColor: colors.green,
    borderWidth: '3px',
    alignItems: ' center',
    padding: '30px',
    borderRadius: '18px',
    width: 'calc((100vw - 180px)/4)',
    height: '88%',
  },

  planTitle: {
    fontSize: '30px',
    fontWeight: '500',
  },

  featuresContainer(isMobile) {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginTop: !isMobile ? '40px' : '22px',
      height: '60%',
      width: '96%',
    };
  },

  feature(isMobile) {
    return {
      ...globalStyles.littleRoundedBorder,
      ...globalStyles.verticalAlign,
      borderRadius: '30px',
      padding: '0px 20px 0px 18px',
      height: 'max(calc(24% - 20px), 38px)',
      border: 0,
      backgroundColor: colors.transparentgreen,
      color: colors.black,
      fontSize: !isMobile ? '0.9vw' : '14px',
    };
  },

  featureUnabled(isMobile) {
    return {
      ...globalStyles.littleRoundedBorder,
      ...globalStyles.verticalAlign,
      borderRadius: '30px',
      padding: '0px 20px 0px 18px',
      height: 'max(calc(24% - 20px), 38px)',
      border: 0,
      backgroundColor: colors.unabled,
      color: colors.unabledtxt,
      fontSize: !isMobile ? '0.9vw' : '14px',
    };
  },

  cifra: {
    fontSize: '16px',
    height: '100%',
  },

  price(isMobile) {
    return {
      fontSize: '40px',
      height: !isMobile ? '50px' : '40px',
      margin: '20px 0px 30px 0px',
    };
  },

  permonth: {
    fontSize: '14px',
  },

  icon: {
    width: '10%',
    marginRight: '8px',
  },

};

export default styles;

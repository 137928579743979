import axios from 'axios';
import config from '../../config/host';

function resetPassword(userId, resetToken, newPassword) {
  axios
    .put(`${config.host}/api/user/resetPassword`, {
      params: { userId, resetToken, newPassword },
    })
    .then((response) => response);
}

export default resetPassword;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// Styles
import styles from '../../assets/styles/LinkBoxStyles';

import star from '../../assets/icons/star.png';
import edit from '../../assets/icons/edit.png';
import trash from '../../assets/icons/trash.png';

function LinkBox({
  linkName, setEditOverlay, setDeleteLinkOverlay, main, url, accesses, link, selectLink,
}) {
  return (
    <div style={styles.box}>
      <div style={styles.starContainer}>
        {
          main ? <img src={star} style={styles.star} /> : false
        }
      </div>
      <div style={styles.infoContainer}>
        <p style={styles.linkTitle}>{linkName}</p>
        <p style={styles.url}>{url}</p>
      </div>
      <div style={styles.redirectsContainer}>
        <p style={styles.redirects}>{accesses}</p>
        <p style={styles.redirectsText}>acessos</p>
      </div>
      <div style={styles.actions}>
        <img
          src={edit}
          style={styles.icons(main)}
          onClick={() => {
            selectLink(link);
            setEditOverlay(true);
            localStorage.setItem('selectedOs', link.osName);
          }}
        />
        <img
          src={trash}
          style={styles.icons(main)}
          onClick={() => {
            selectLink(link);
            setDeleteLinkOverlay(true);
          }}
        />

      </div>
    </div>
  );
}

export default LinkBox;

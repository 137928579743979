import React, { forwardRef } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import useFetchPlans from '../../hooks/Plan/useFetchPlans';

// Components
import PlanBox from '../PlanBox';

// Styles
import styles from '../../assets/styles/LandingPageStyles';

const Plans = forwardRef(({ isMobile }, ref) => {
  const plans = useFetchPlans();
  const navigate = useNavigate();

  return (
    <div style={styles.section(isMobile)} ref={ref}>
      <div style={styles.header(isMobile)}>
        <p style={styles.title(isMobile)}>Conheça nossos planos</p>
        <p style={styles.subtitle(isMobile)}>Temos o melhor plano para o seu negócio</p>
      </div>
      <div style={styles.body(isMobile, true, false)}>
        <div style={styles.plansContainer(isMobile)}>
          {
            plans.map((plan) => (
              <PlanBox
                key={plan._id}
                isMobile={isMobile}
                planInfo={plan}
                userPlan={false}
                tryGoToCheckout={() => navigate('/signin')}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
});

export default Plans;

import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config/host';

function useFetchLinksFromUser(token, setLinks) {
  useEffect(() => {
    axios
      .get(`${config.host}/api/link/getLinksFromUser`, {
        headers: { authorization: token },
      })
      .then((response) => setLinks(response.data));
  }, []);
}

export default useFetchLinksFromUser;

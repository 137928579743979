/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ShakeHorizontal } from 'reshake';
import config from '../../config/host';

// Hooks
import useFetchUser from '../../hooks/User/useFetchUser';
import createNewLink from '../../hooks/Link/createNewLink';
import updateNOfQRs from '../../hooks/User/updateNOfQRs';
import useFetchPlans from '../../hooks/Plan/useFetchPlans';

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/CreateNewFormStyles';
import close from '../../assets/icons/close.png';

function CreateQRForm({ setOverlay, onCreateQRCode }) {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const user = useFetchUser(token);
  const plans = useFetchPlans();

  let possible = false;
  if (plans.filter((plan) => plan._id === user.plan).length) {
    possible = plans.filter((plan) => plan._id === user.plan)[0].qrs > user.qrs;
  }

  const [qrName, setQRName] = useState('');
  const [qrNameWarning, setQRNameWarning] = useState('');

  const [defaultLink, setDefaultLink] = useState('https://');
  const [defaultLinkWarning, setDefaultLinkWarning] = useState('');

  const [wrong, setWrong] = useState(false);

  const tryCreateQR = () => {
    if (!qrNameWarning && !defaultLinkWarning && qrName && defaultLink) {
      const qr = {
        qrName,
        defaultLink,
        userId: user._id,
      };
      axios
        .post(`${config.host}/api/qr/createQR`, qr, {
          headers: { authorization: token },
        })
        .then((response) => {
          onCreateQRCode(response.data);
          createNewLink(token, {
            linkName: 'Link de escape',
            url: qr.defaultLink,
            qrId: response.data._id,
            qrShort: response.data.qrShort,
            default: true,
          });
        });
      updateNOfQRs(token, 1);
      setOverlay(false);
    } else {
      if (!qrName) setQRNameWarning('Insira um nome para o seu QR Code');
      const validLink = /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/.test(defaultLink);
      if (!defaultLink) setDefaultLinkWarning('Insira um link para o seu QR Code');
      else if (!validLink) setDefaultLinkWarning('Link inválido');
      setWrong(true);
      setTimeout(() => setWrong(false), 318);
    }
  };

  const handleQRName = (qrNameInputed) => {
    setQRName(qrNameInputed);
    setQRNameWarning(!qrNameInputed ? 'Insira um nome para o seu QR Code' : '');
  };

  const handleDefaultLink = (defaultLinkInputed) => {
    if (defaultLinkInputed.includes('https://')) {
      setDefaultLink(defaultLinkInputed);
      const validLink = /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/.test(defaultLinkInputed);
      setDefaultLinkWarning(
        !defaultLink
          ? 'Insira um link para o seu QR Code'
          : !validLink
            ? 'Link inválido'
            : '',
      );
    }
  };

  const closeOverlay = () => {
    setQRName('');
    setDefaultLink('https://');
    setQRNameWarning('');
    setDefaultLinkWarning('');
    setOverlay(false);
  };

  return (
    <div style={styles.background}>
      <ShakeHorizontal active={wrong}>
        <div style={styles.containerStyles}>
          <img alt="close" src={close} style={styles.close} onClick={closeOverlay} />
          <p style={styles.titleStyles}>Criar novo QR Code</p>
          {
            possible
              ? (
                <div style={styles.inputsStyles}>
                  <TextInput
                    label="Nome do seu QR"
                    placeholder="Baixe o aplicativo"
                    setValue={handleQRName}
                    warningMessage={qrNameWarning}
                    value={qrName}
                  />
                  <TextInput
                    label="Link"
                    setValue={handleDefaultLink}
                    warningMessage={defaultLinkWarning}
                    value={defaultLink}
                  />
                </div>
              )
              : (
                <div style={styles.inputsStyles}>
                  <TextInput
                    label="Nome do seu QR"
                    placeholder="Baixe o aplicativo"
                    setValue={handleQRName}
                    warningMessage="Número máximo de QR Codes atingido"
                    disabled
                  />
                  <TextInput
                    label="Link"
                    placeholder="https://app.com"
                    setValue={handleDefaultLink}
                    warningMessage="Número máximo de QR Codes atingido"
                    disabled
                  />
                </div>
              )
          }
          {
            possible
              ? (
                <DefaultButton
                  label="Criar QR Code"
                  onClick={tryCreateQR}
                />
              )
              : (
                <DefaultButton
                  label="Atualizar plano"
                  onClick={() => {
                    setOverlay(false);
                    navigate('/plans');
                  }}
                />
              )
            }
        </div>
      </ShakeHorizontal>
    </div>
  );
}

export default CreateQRForm;

import React from 'react';

// Styles
import { useNavigate } from 'react-router-dom';
import styles from '../../assets/styles/DeleteStyles';

// General components
import DefaultButton from '../Buttons/DefaultButton';

// Hooks
import deleteQRFromShort from '../../hooks/QR/deleteQRFromShort';
import deleteLinksWhenQRDeleted from '../../hooks/Link/deleteLinksWhenQRDeleted';
import deleteScansWhenQRDeleted from '../../hooks/Scan/deleteScansWhenQRDeleted';
import updateNOfQRs from '../../hooks/User/updateNOfQRs';

function DeleteQR({ setOverlay, qrShort }) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const deleteQRCode = () => {
    setOverlay(false);
    navigate('/home');
    deleteQRFromShort(token, qrShort);
    deleteLinksWhenQRDeleted(token, qrShort);
    deleteScansWhenQRDeleted(token, qrShort);
    updateNOfQRs(token, -1);
  };

  return (
    <div style={styles.background}>
      <div style={styles.containerStyles}>
        <div style={styles.titleContainer}>
          <p style={styles.titleStyles}>Deletar QR Code?</p>
        </div>
        <div style={styles.body}>
          <p style={styles.bodyText}>
            Essa ação deletará seu QR Code e
            <span style={{ fontWeight: 500 }}>todos os links</span>
            {' '}
            vinculados permanentemente, e portanto não poderá ser desfeita
          </p>
        </div>
        <div style={styles.buttonsContainer}>
          <DefaultButton label="Cancelar" w="172px" secundary onClick={() => setOverlay(false)} />
          <DefaultButton label="Sim, deletar" w="172px" onClick={deleteQRCode} />
        </div>
      </div>
    </div>
  );
}

export default DeleteQR;

/* eslint-disable max-len */
import React from 'react';

// Inputs
import SelectInput from '../Inputs/SelectInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/AddLinkFormStyles';

function AddLinkFormStep2({
  setOsName, osName, osNameWarning, setOsNameWarning, nextNode, previousNode, userFeatured, createLink,
}) {
  const next = () => {
    setOsNameWarning(!osName.length ? 'Insira ao menos 1 SO, ou pule' : '');
    if (osName.length) nextNode();
  };

  const skip = () => {
    setOsName(['iOS', 'Android']);
    setOsNameWarning('');
    nextNode();
  };

  const create = () => {
    setOsNameWarning(!osName.length ? 'Insira ao menos 1 SO, ou pule' : '');
    if (osName.length) createLink();
  };

  return (
    <div style={styles.headerFormStyles}>
      <p style={styles.titleStyles}>Restrição de SO</p>
      <div style={styles.inputsContainer}>
        <SelectInput label="Sistema operacional" options={['iOS', 'Android']} setValue={setOsName} value={osName} warningMessage={osNameWarning} isMulti placeholder="Selecione os SOs" info infoTitle="Restringir o Sistema Operacional" infoDescription="Apenas os SOs selecionados serão direcionados para esse link" />
      </div>
      <div style={styles.buttonsContainer}>
        <DefaultButton w="80px" label="Anterior" terciary onClick={previousNode} />
        {
                    userFeatured > 2
                      ? (
                        <div style={styles.rightButtonsContainer}>
                          <DefaultButton w="124px" label="Pular" onClick={skip} secundary />
                          <DefaultButton w="124px" label="Próximo" onClick={next} />
                        </div>
                      )
                      : (
                        <div style={styles.rightButtonsContainer}>
                          <DefaultButton w="0px" />
                          <DefaultButton w="160px" label="Criar link" onClick={create} />
                        </div>
                      )
                }
      </div>
    </div>
  );
}

export default AddLinkFormStep2;

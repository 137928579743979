/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import config from '../../config/host';

// Icons and images
import edit from '../../assets/icons/edit.png';
import trash from '../../assets/icons/trash.png';
import copy from '../../assets/icons/copy.png';

import styles from '../../assets/styles/UTMLinkBoxStyles';

function UTMLinkBox({
  url, accesses, setDeleteUTMOverlay, utmLink, selectUTMLink, setEditUTMOverlay,
}) {
  const [hover, setHover] = useState(false);
  const [copied, setCopied] = useState(false);

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    setCopied(true);
  }

  return (
    <div style={styles.box}>
      <div style={styles.linkContainer}>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(url)}
          onMouseEnter={() => {
            if (!copied) setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
            setCopied(false);
          }}
        >
          <p>{url.replace('http://localhost:3000', config.front)}</p>
          <img alt="copy-to-clipboard" src={copy} style={{ width: '20px', height: '20px', marginLeft: '16px' }} />
          <div style={styles.copyToClipboard(hover, copied)}>
            <div style={styles.chat} />
            <p>{copied ? 'Copiado!' : hover ? 'Copiar link' : false}</p>
          </div>
        </div>
      </div>
      <div style={styles.info}>
        <div style={styles.redirectsContainer}>
          <p style={styles.redirects}>{accesses}</p>
          <p style={styles.redirectsText}>acessos</p>
        </div>
        <div style={styles.iconsContainer}>
          <img
            alt="edit"
            src={edit}
            style={styles.icons}
            onClick={() => {
              selectUTMLink(utmLink);
              setEditUTMOverlay(true);
            }}
          />
          <img
            alt="trash"
            src={trash}
            style={styles.icons}
            onClick={() => {
              selectUTMLink(utmLink);
              setDeleteUTMOverlay(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UTMLinkBox;

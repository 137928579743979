import React from 'react';

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import ButtonLogin from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/LoginFormStyles';

function ResetPasswordForm({
  setPassword, setConfirmPassword, onClick, passwordWarning, confirmPasswordWarning, isMobile,
}) {
  return (
    <div style={styles.loginFormContainerStyles(isMobile)}>
      <p style={styles.titleStyles(isMobile)}>Redefinir senha</p>
      <p style={styles.subtitleStyles(isMobile)}>
        Sua senha antiga será
        <span style={styles.signinStyles}>substituída</span>
        {' '}
        por essa
      </p>
      <div style={styles.inputsStyles(isMobile)}>
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Nova senha"
          placeholder="Insira 8 caracteres ou mais"
          setValue={setPassword}
          warningMessage={passwordWarning}
          hide
        />
        <TextInput
          w={isMobile ? '80vw' : ''}
          label="Confirme sua nova senha"
          placeholder="Confirme a senha"
          setValue={setConfirmPassword}
          warningMessage={confirmPasswordWarning}
          hide
        />
      </div>
      <ButtonLogin
        w={isMobile ? '80vw' : ''}
        onClick={onClick}
        label="Mudar senha"
      />
    </div>
  );
}

export default ResetPasswordForm;

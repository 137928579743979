/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';

import Select from 'react-select';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  VictoryLabel,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from 'victory';

import config from '../../config/host';

import DateRange from '../Chart/DateRange';
import TimeRange from '../Chart/TimeRange';
import LocalFilter from '../Chart/Local';
import ApplyButton from '../Chart/ApplyButton';

import { groupByDay, groupByUTM } from '../../logic/groupScans';

function UTMTracking({ setVisible, scans, utms }) {
  const { qrShort } = useParams();

  const token = localStorage.getItem('token');

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [scansByDay, setScansByDay] = useState(groupByDay(scans));
  const [scansByDimension, setScansByDimension] = useState(groupByUTM(scans));

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  useEffect(() => {
    setScansByDay(groupByDay(scans));
  }, [scans]);

  const [values, setValues] = useState([0, 48]);
  const [states, setStates] = useState('');
  const [dimension, setDimension] = useState('source');

  const filter = () => {
    axios
      .get(`${config.host}/api/scan/filterScansFromQRShort`, {
        headers: { authorization: token },
        params: {
          qrShort, daysInterval: selectionRange, timeInterval: values, states,
        },
      })
      .then((response) => {
        if (!response.data.length) {
          setScansByDay([]);
        } else {
          setScansByDay(groupByDay(response.data));
          setScansByDimension(groupByUTM(response.data));
        }
      });
  };

  const bestDay = scansByDay.reduce((acc, val) => Math.max(acc, val.y), 0);

  const colorScale = ['#334D5C', '#45B29D', '#EFC94C', '#E27A3F', '#DF5A49', '#4F7DA1', '#55DBC1', '#EFDA97', '#E2A37F', '#DF948A'];

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', height: '100%',
    }}
    >
      <div style={{ display: 'flex', margin: '0px 0px 16px 22px' }}>
        <DateRange setDateRange={setSelectionRange} />
        <TimeRange setTimeRange={setValues} />
        <LocalFilter setStates={setStates} defaultStates={states} />
        <ApplyButton onClick={filter} />
      </div>
      <div style={{
        display: 'flex', alignItems: 'center', margin: '0px 0px 16px 22px', zIndex: '1',
      }}
      >
        <div style={{ width: '240px', margin: '0px 40px 0px 0px' }}>
          <Select
            options={[{ value: 'source', label: 'Source' }, { value: 'medium', label: 'Medium' }, { value: 'campaign', label: 'Campaign' }, { value: 'term', label: 'Term' }, { value: 'content', label: 'Content' }]}
            styles={{ control: (baseStyles, state) => ({ ...baseStyles, width: '240px' }) }}
            onChange={(e) => setDimension(e.value)}
            defaultValue={{ value: 'source', label: 'Source' }}
          />
        </div>
      </div>

      <div style={{ zIndex: -1 }}>
        <VictoryChart domainPadding={{ x: 10 }} width={windowSize.current[0] - 490} height={windowSize.current[1] > 800 ? 480 : 340} domain={{ y: [0, bestDay] }}>
          {
              scansByDimension[dimension].map((scan, index) => (
                <VictoryLine data={scan.scans} colorScale="qualitative" style={{ data: { stroke: colorScale[index] } }} />
              ))
            }
          {
              scansByDimension[dimension].map((scan, index) => (
                <VictoryScatter data={scan.scans} colorScale="qualitative" style={{ data: { fill: colorScale[index] } }} />
              ))
            }
          <VictoryAxis
            dependentAxis
            standalone={false}
            style={{
              grid: { stroke: 'grey', strokeWidth: 0.4 },
              axis: { stroke: 'transparent' },
            }}
          />
          <VictoryAxis
            tickLabelComponent={<VictoryLabel angle={45} y={windowSize.current[1] > 800 ? 448 : 316} />}
          />
        </VictoryChart>
      </div>
    </div>
  );
}

export default UTMTracking;

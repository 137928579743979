import React from 'react';

function Legend({ all }) {
  return (
    <div style={{ display: 'flex' }}>
      {
                all
                  ? (
                    <div style={{ display: 'flex', alignItems: 'center', width: '104px' }}>
                      <div style={{
                        width: '14px', height: '14px', borderRadius: '14px', backgroundColor: '#000000', margin: '0px 10px 0px 0px',
                      }}
                      />
                      <div>Todos</div>
                    </div>
                  )
                  : false
            }
      <div style={{ display: 'flex', alignItems: 'center', width: '120px' }}>
        <div style={{
          width: '14px', height: '14px', borderRadius: '14px', backgroundColor: '#45B29D', margin: '0px 10px 0px 0px',
        }}
        />
        <div>Android</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', width: '86px' }}>
        <div style={{
          width: '14px', height: '14px', borderRadius: '14px', backgroundColor: '#EFC94C', margin: '0px 10px 0px 0px',
        }}
        />
        <div>iOS</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', width: '180px' }}>
        <div style={{
          width: '14px', height: '14px', borderRadius: '14px', backgroundColor: '#334D5C', margin: '0px 10px 0px 0px',
        }}
        />
        <div>Não identificado</div>
      </div>
    </div>
  );
}

export default Legend;

/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable radix */
import React, { useState } from 'react';

// Libraries
import QRCode from 'react-qr-code';

// Layout
import Line from '../Layout/Line';

// Buttons
import DownloadAs from '../Buttons/DownloadAs';

// Icons and images
import edit from '../../assets/icons/edit.png';
import trash from '../../assets/icons/trash.png';
import copy from '../../assets/icons/copy.png';

// Components
import InfoHeader from './InfoHeader';

import styles from '../../assets/styles/HeaderInfoQRStyles';

function HeaderInfoQR({
  qrName, url, scans, links, success, setOverlay, setDeleteOverlay,
}) {
  const [hover, setHover] = useState(false);
  const [copied, setCopied] = useState(false);

  function downloadPNG(e) {
    const canvas = document.createElement('canvas');
    const svg = document.getElementById('qr-code');
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
    const w = parseInt(svg.getAttribute('width'));
    const h = parseInt(svg.getAttribute('height'));
    const img_to_download = document.createElement('img');
    img_to_download.src = `data:image/svg+xml;base64,${base64doc}`;
    img_to_download.onload = () => {
      canvas.setAttribute('width', w);
      canvas.setAttribute('height', h);
      const context = canvas.getContext('2d');
      context.drawImage(img_to_download, 0, 0, w, h);
      const dataURL = canvas.toDataURL('image/png');
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(canvas.msToBlob(), `${qrName.replace(/\s/g, '')}.png`);
        e.preventDefault();
      } else {
        const a = document.createElement('a');
        const my_evt = new MouseEvent('click');
        a.download = `${qrName.replace(/\s/g, '')}.png`;
        a.href = dataURL;
        a.dispatchEvent(my_evt);
      }
    };
  }

  function downloadSVG() {
    const svg = document.getElementById('qr-code');
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
    const a = document.createElement('a');
    const e = new MouseEvent('click');
    a.download = `${qrName.replace(/\s/g, '')}.svg`;
    a.href = `data:image/svg+xml;base64,${base64doc}`;
    a.dispatchEvent(e);
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    setCopied(true);
  }

  return (
    <div>
      <div style={styles.headerInfoContainerStyles}>
        <div style={styles.qrContainerStyles}>
          <QRCode value={url} size={138} id="qr-code" />
        </div>
        <div style={styles.infoContainerStyles}>
          <div style={styles.headerTitle}>
            <p style={styles.titleStyles}>{qrName}</p>
            <div style={styles.iconsContainer}>
              <img alt="edit" src={edit} style={styles.icon} onClick={() => setOverlay(true)} />
              <img
                alt="trash"
                src={trash}
                style={styles.icon}
                onClick={() => {
                  setDeleteOverlay(true);
                }}
              />
            </div>
          </div>
          <Line mt="10px" mb="10px" />
          <div style={styles.underLine}>
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => copyToClipboard(url)}
              onMouseEnter={() => {
                if (!copied) setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
                setCopied(false);
              }}
            >
              <p style={{ fontSize: '20px' }}>{url}</p>
              <img alt="copy-to-clipboard" src={copy} style={{ width: '20px', height: '20px', marginLeft: '16px' }} />
              <div style={styles.copyToClipboard(hover, copied)}>
                <div style={styles.chat} />
                <p>{copied ? 'Copiado!' : hover ? 'Copiar para área de transferência' : false}</p>
              </div>
            </div>
            <div style={styles.downloadButtons}>
              <DownloadAs type="SVG" onClick={downloadSVG} />
              <DownloadAs type="PNG" onClick={downloadPNG} />
            </div>
          </div>
          <div style={styles.dataContainerStyles}>
            <InfoHeader name="Scans" value={scans} />
            <InfoHeader name="Links" value={links} />
            <InfoHeader name="Sucesso" value={success} />
          </div>
        </div>
      </div>

    </div>
  );
}

export default HeaderInfoQR;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import useFetchPlans from '../../hooks/Plan/useFetchPlans';

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/AddLinkFormStyles';

function AddLinkFormStep1({
  setLinkName, setUrl, setLinkNameWarning, setUrlWarning, linkName, url, linkNameWarning, urlWarning, nextNode, setOverlay, userPlan, nOfLinks,
}) {
  const navigate = useNavigate();
  const plans = useFetchPlans();
  let possible = false;
  if (plans.filter((plan) => plan._id === userPlan).length) {
    possible = plans.filter((plan) => plan._id === userPlan)[0].links > nOfLinks;
  }

  const next = () => {
    if (!linkNameWarning && !urlWarning && linkName && url) {
      nextNode();
      setLinkNameWarning('');
      setUrlWarning('');
    } else {
      if (!linkName) setLinkNameWarning('Insira um nome para seu link');
      if (!url) setUrlWarning('Insira uma URL');
    }
  };

  const handleLinkName = (linkNameInputed) => {
    setLinkName(linkNameInputed);
    setLinkNameWarning(!linkNameInputed ? 'Insira um nome para seu link' : '');
  };

  const handleUrl = (urlInputed) => {
    if (urlInputed.includes('https://')) {
      setUrl(urlInputed);
      const validLink = /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/.test(urlInputed);
      setUrlWarning(
        urlInputed === 'https://'
          ? 'Insira um link para o seu QR Code'
          : !validLink
            ? 'Link inválido'
            : '',
      );
    }
  };

  return (
    <div style={styles.headerFormStyles}>
      <p style={styles.titleStyles}>Adicionar novo link</p>
      {
        possible
          ? (
            <div style={styles.inputsContainer}>
              <TextInput
                label="Nome do link"
                placeholder="Android e IOs domingo em SP"
                setValue={handleLinkName}
                value={linkName}
                warningMessage={linkNameWarning}
                info
                infoTitle="Nome do seu link"
                infoDescription="Este nome será usado apenas para você identificar facilmente seu link"
              />
              <TextInput
                label="URL"
                placeholder="https://menu/domingo/happy-hour"
                setValue={handleUrl}
                value={url}
                warningMessage={urlWarning}
              />
            </div>
          )
          : (
            <div style={styles.inputsContainer}>
              <TextInput
                label="Nome do link"
                placeholder="Android e IOs domingo em SP"
                setValue={handleLinkName}
                value={linkName}
                warningMessage="Número máximos de links para esse QR atingido"
                disabled
              />
              <TextInput
                label="URL"
                placeholder="https://menu/domingo/happy-hour"
                setValue={handleUrl}
                value={url}
                warningMessage="Número máximos de links para esse QR atingido"
                disabled
              />
            </div>
          )
        }
      {
                    !possible
                      ? (
                        <div style={styles.buttonsContainer}>
                          <DefaultButton w="192px" label="Cancelar" secundary onClick={setOverlay} />
                          <DefaultButton
                            w="206px"
                            label="Atualizar plano"
                            onClick={() => {
                              setOverlay(false);
                              navigate('/plans');
                            }}
                          />
                        </div>
                      )
                      : (
                        <div style={styles.buttonsContainer}>
                          <DefaultButton w="192px" label="Cancelar" secundary onClick={setOverlay} />
                          <DefaultButton w="192px" label="Próximo" onClick={next} />
                        </div>
                      )
                }
    </div>
  );
}

export default AddLinkFormStep1;

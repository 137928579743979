import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config/host';

function useFetchPlan(planId, setPlan) {
  useEffect(() => {
    axios
      .get(`${config.host}/api/product/getPlan`, {
        params: { planId },
      })
      .then((response) => setPlan(response.data[0]));
  }, []);
}

export default useFetchPlan;

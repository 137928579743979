/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import styles from '../../assets/styles/Buttons/DownloadAsStyles';

import download from '../../assets/icons/download.png';

function DownloadAs({ type, onClick }) {
  return (
    <div style={styles.container} onClick={onClick}>
      <img alt="download-icon" src={download} style={styles.icon} />
      <p style={styles.text}>{type}</p>
    </div>
  );
}

export default DownloadAs;

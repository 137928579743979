import globalStyles from './Global/globalStyles';
import colors from './Global/colors';

const styles = {
  box: {
    ...globalStyles.verticalAlign,
    ...globalStyles.littleRoundedBorder,
    height: '62px',
    borderColor: colors.grey,
    backgroundColor: '#FFFFFF',
    width: '70%',
    marginBottom: '20px',
    position: 'relative',
  },

  linkTitle: {
    fontSize: '18px',
  },

  url: {
    fontSize: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: '0px',
  },

  starContainer: {
    ...globalStyles.center,
    width: '72px',
    height: '50%',
    borderRight: `solid 1px ${colors.grey}`,
  },

  star: {
    width: '28px',
  },

  infoContainer: {
    ...globalStyles.column,
    justifyContent: 'center',
    width: 'calc(100% - 306px)',
    height: '76%',
    padding: '0px 0px 0px 16px',
  },

  redirectsContainer: {
    ...globalStyles.columnCentered,
    width: '100px',
    height: '76%',
  },

  redirects: {
    fontSize: '22px',
    fontWeight: '500',
    margin: '0px',
    padding: '0px',
  },

  redirectsText: {
    fontSize: '12px',
    fontWeight: '500',
    margin: '0px 0px 0px 0px',
    padding: '0px',
  },

  actions: {
    ...globalStyles.horizontalSpaceBetween,
    margin: '0px 3% 0px 2%',
    width: '110px',
    height: '50%',
    borderLeft: 'solid 1px',
    borderLeftColor: colors.grey,
    position: 'absolute',
    right: '0',
    padding: '0px 0px 0px 36px',
  },

  icons(disabled) {
    return {
      width: '24px',
      cursor: disabled ? 'default' : 'pointer',
      opacity: disabled ? '0.4' : '1.0',
    };
  },
};

export default styles;

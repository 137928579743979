/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';

import Select from 'react-select';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  VictoryLabel,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from 'victory';

import config from '../../config/host';

import DateRange from '../Chart/DateRange';
import TimeRange from '../Chart/TimeRange';
import LocalFilter from '../Chart/Local';
import ApplyButton from '../Chart/ApplyButton';

import { groupByDay, groupByMonth, groupByRegion } from '../../logic/groupScans';

import Legend from '../Chart/Legend';

import colors from '../../assets/styles/Global/colors';

function ScansInfo({ scans }) {
  const { qrShort } = useParams();

  const token = localStorage.getItem('token');

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [scansByDay, setScansByDay] = useState(groupByDay(scans, true));
  const [scansByMonth, setScansByMonth] = useState(groupByMonth(scans, true));

  const [androidScansByDay, setAndroidScansByDay] = useState(groupByDay(scans.filter((scan) => scan.osName === 'Android'), true));
  const [androidScansByMonth, setAndroidScansByMonth] = useState(groupByMonth(scans.filter((scan) => scan.osName === 'Android'), true));

  const [iosScansByDay, setIosScansByDay] = useState(groupByDay(scans.filter((scan) => scan.osName === 'iOS')), true);
  const [iosScansByMonth, setIosScansByMonth] = useState(groupByMonth(scans.filter((scan) => scan.osName === 'iOS')), true);

  const [unknownScansByDay, setUnknownScansByDay] = useState(groupByDay(scans.filter((scan) => scan.osName === 'unknown')), true);
  const [unknownScansByMonth, setUnknownScansByMonth] = useState(groupByMonth(scans.filter((scan) => scan.osName === 'unknown')), true);

  // eslint-disable-next-line no-unused-vars
  const [scansByRegion, setScansByRegion] = useState(groupByRegion(scans));

  useEffect(() => {
    setScansByDay(groupByDay(scans));
    setScansByMonth(groupByMonth(scans));
  }, [scans]);

  useEffect(() => {
    setScansByRegion(groupByRegion(scans));
  }, [scans]);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [values, setValues] = useState([0, 48]);
  const [states, setStates] = useState('');
  const [os, setOs] = useState('Todos');
  const [period, setPeriod] = useState('Dia');

  const filter = () => {
    axios
      .get(`${config.host}/api/scan/filterScansFromQRShort`, {
        headers: { authorization: token },
        params: {
          qrShort, daysInterval: selectionRange, timeInterval: values, states,
        },
      })
      .then((response) => {
        if (!response.data.length) {
          setScansByDay([]);
        } else {
          setScansByDay(groupByDay(response.data));
          setAndroidScansByDay(groupByDay(response.data.filter((scan) => scan.osName === 'Android')));
          setIosScansByDay(groupByDay(response.data.filter((scan) => scan.osName === 'iOS')));
          setUnknownScansByDay(groupByDay(response.data.filter((scan) => scan.osName === 'unknown')));
          setScansByMonth(groupByMonth(response.data));
          setAndroidScansByMonth(groupByMonth(response.data.filter((scan) => scan.osName === 'Android')));
          setIosScansByMonth(groupByMonth(response.data.filter((scan) => scan.osName === 'iOS')));
          setUnknownScansByMonth(groupByMonth(response.data.filter((scan) => scan.osName === 'unknown')));
          setScansByRegion(groupByRegion(response.data));
        }
      });
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', height: '100%',
    }}
    >
      <div style={{ display: 'flex', margin: '0px 0px 16px 22px' }}>
        <DateRange setDateRange={setSelectionRange} />
        <TimeRange setTimeRange={setValues} />
        <LocalFilter setStates={setStates} defaultStates={states} />
        <ApplyButton onClick={filter} />
      </div>
      <div style={{
        display: 'flex', alignItems: 'center', margin: '0px 0px 16px 22px', zIndex: '1',
      }}
      >
        <div style={{ width: '140px', margin: '0px 20px 0px 0px' }}>
          <Select
            options={[{ value: 'dia', label: 'Dia' }, { value: 'mes', label: 'Mês' }]}
            styles={{ control: (baseStyles) => ({ ...baseStyles, width: '140px' }) }}
            onChange={(e) => setPeriod(e.label)}
            defaultValue={{ value: 'dia', label: 'Dia' }}
          />
        </div>
        <div style={{ width: '212px', margin: '0px 40px 0px 0px' }}>
          <Select
            options={[{ value: 'todos', label: 'Todos' }, { value: 'ios', label: 'iOS' }, { value: 'android', label: 'Android' }, { value: 'unknown', label: 'Não identificado' }]}
            styles={{ control: (baseStyles) => ({ ...baseStyles, width: '212px' }) }}
            onChange={(e) => setOs(e.label)}
            defaultValue={{ value: 'todos', label: 'Todos' }}
          />
        </div>
        <div>
          <Legend all />
        </div>
      </div>
      <div style={{ zIndex: '-1', display: 'flex' }}>
        <div style={{ width: '70%', marginTop: '20px' }}>
          {
          scansByDay.length
            ? (
              <VictoryChart domainPadding={{ x: 10 }} width={(windowSize.current[0] - 400) / 1.5} height={windowSize.current[1] > 800 ? 460 : 340} domain={{ y: [0, period === 'Dia' ? scansByDay.reduce((acc, val) => Math.max(acc, val.y), 0) : scansByMonth.reduce((acc, val) => Math.max(acc, val.y), 0)] }}>
                {
                  os === 'Todos'
                    ? <VictoryLine data={period === 'Dia' ? scansByDay : scansByMonth} style={{ data: { stroke: '#000000' } }} />
                    : false
                }
                {
                  os === 'Todos'
                    ? <VictoryScatter data={period === 'Dia' ? scansByDay : scansByMonth} style={{ data: { fill: '#000000' } }} />
                    : false
                }
                {
                  os === 'Android'
                    ? <VictoryLine data={period === 'Dia' ? scansByDay : scansByMonth} style={{ data: { stroke: '#FFFFFF' } }} />
                    : false
                }
                {
                  os === 'Android'
                    ? <VictoryLine data={period === 'Dia' ? androidScansByDay : androidScansByMonth} style={{ data: { stroke: '#45B29D' } }} />
                    : false
                }
                {
                  os === 'Android'
                    ? <VictoryScatter data={period === 'Dia' ? androidScansByDay : androidScansByMonth} style={{ data: { fill: '#45B29D' } }} />
                    : false
                }
                {
                  os === 'iOS'
                    ? <VictoryLine data={period === 'Dia' ? scansByDay : scansByMonth} style={{ data: { stroke: '#FFFFFF' } }} />
                    : false
                }
                {
                  os === 'iOS'
                    ? <VictoryLine data={period === 'Dia' ? iosScansByDay : iosScansByMonth} style={{ data: { stroke: '#EFC94C' } }} />
                    : false
                }
                {
                  os === 'iOS'
                    ? <VictoryScatter data={period === 'Dia' ? iosScansByDay : iosScansByMonth} style={{ data: { fill: '#EFC94C' } }} />
                    : false
                }
                {
                  os === 'Não identificado'
                    ? <VictoryLine data={period === 'Dia' ? scansByDay : scansByMonth} style={{ data: { stroke: '#FFFFFF' } }} />
                    : false
                }
                {
                  os === 'Não identificado'
                    ? <VictoryLine data={period === 'Dia' ? unknownScansByDay : unknownScansByMonth} style={{ data: { stroke: '#334D5C' } }} />
                    : false
                }
                {
                  os === 'Não identificado'
                    ? <VictoryScatter data={period === 'Dia' ? unknownScansByDay : unknownScansByMonth} style={{ data: { fill: '#334D5C' } }} />
                    : false
                }

                <VictoryAxis
                  dependentAxis
                  standalone={false}
                  style={{
                    grid: { stroke: 'grey', strokeWidth: 0.4 },
                    axis: { stroke: 'transparent' },
                  }}
                />
                <VictoryAxis
                  tickLabelComponent={<VictoryLabel angle={45} y={windowSize.current[1] > 800 ? 430 : 316} />}
                />
              </VictoryChart>
            )
            : (
              <div style={{
                height: '32vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
              }}
              >
                <p>Não há dados</p>
              </div>
            )
          }
        </div>
        <div style={{ width: '30%', height: '100%' }}>
          <p style={{
            color: colors.green, fontWeight: '500', fontSize: '22px', margin: '20px 0px 20px 0px',
          }}
          >
            Leituras por região
          </p>
          {
            scansByRegion.map((region, idx) => (
              <div
                key={region.name}
                style={{
                  display: 'flex', width: '100%', justifyContent: 'space-between', height: '56px', padding: '0px 20px', alignItems: 'center', borderBottom: `${1 * (idx !== scansByRegion.length - 1)}px solid ${colors.green}`, fontSize: '0.98vw',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <p style={{ opacity: '0.4', marginRight: '24px' }}>{`#${idx + 1}`}</p>
                  <p>{region.name}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: '500', marginRight: '20px' }}>{region.scans}</p>
                  <p style={{ opacity: '0.4' }}>{`${Math.round(1000 * (region.scans / scansByRegion.reduce((acc, val) => acc + val.scans, 0))) / 10}%`}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>

  );
}

export default ScansInfo;

import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config/host';

function useFetchLinks(qrShort, setLinks) {
  useEffect(() => {
    axios
      .get(`${config.host}/api/link/getLinksFromQRShort`, {
        params: { qrShort },
      })
      .then((response) => setLinks(response.data));
  }, []);
}

export default useFetchLinks;

import React, { useState, useEffect } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import useFetchQRFromUser from '../hooks/QR/useFetchQRFromUser';

// Layout
import SideBar from '../layouts/SideBar';
import TopBar from '../layouts/TopBar';

// Forms
import CreateQRForm from '../components/Forms/CreateQRForm';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Support() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate('/login');
  });

  // All QR Codes from the user
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  const onCreateQRCode = (newQRCode) => setQRList((prev) => [...prev, newQRCode]);
  const [createQROverlay, setCreateQROverlay] = useState(false);

  return (
    <div style={globalStyles.row}>
      {
        createQROverlay
          ? (
            <CreateQRForm
              setOverlay={setCreateQROverlay}
              onCreateQRCode={onCreateQRCode}
            />
          ) : false
      }
      <SideBar
        setOverlay={setCreateQROverlay}
        qrList={qrList}
        onCreateQRCode={onCreateQRCode}
      />
      <div>
        <TopBar
          setOverlay={setCreateQROverlay}
        />
        <div style={globalStyles.containerPadding}>
          <p style={globalStyles.boldTitle}>Suporte</p>
        </div>
      </div>
    </div>
  );
}

export default Support;

import colors from '../Global/colors';
import globalStyles from '../Global/globalStyles';
import shadows from '../Global/shadows';

const styles = {
  buttonStyle(hover, isMobile) {
    return {
      ...globalStyles.center,
      ...globalStyles.pointer,
      backgroundColor: hover || isMobile ? colors.green : '#FFFFFF',
      borderWidth: hover || isMobile ? '0px' : '1px',
      borderStyle: 'solid',
      borderColor: colors.green,
      color: hover || isMobile ? colors.white : colors.green,
      boxShadow: shadows.startNow,
      width: !isMobile ? '300px' : '240px',
      height: !isMobile ? '64px' : '56px',
      borderRadius: '50px',
      transform: hover || isMobile ? 'scale(1.05)' : 'scale(1)',
      transition: 'transform .2s ease-in-out',
    };
  },

  labelStyle(isMobile) {
    return {
      fontSize: isMobile ? '22px' : '26px',
      fontWeight: 'bold',
      letterSpacing: '0.8px',
    };
  },
};

export default styles;

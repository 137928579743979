import colors from './colors';

const globalStyles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  columnCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  horizontalSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  horizontalSpaceAround: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },

  littleRoundedBorder: {
    borderRadius: '6px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#13131A',
  },

  pointer: {
    cursor: 'pointer',
  },

  defaultSize: {
    width: '420px',
    height: '50px',
  },

  regular: {
    fontWeight: '400',
  },

  bold: {
    fontWeight: '600',
  },

  title: {
    fontSize: '40px',
    fontWeight: '500',
  },

  boldTitle: {
    fontSize: '40px',
    fontWeight: '600',
  },

  containerPadding: {
    padding: '0px 96px 0px 72px',
  },

  spaceEvenlyContainer: {
    display: 'flex',
    flexDirection: 'horizontal',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: 'min(calc(100vh - 192px), 680px)',
    width: 'calc(100vw - 310px)',
    margin: '12px 0px 0px 0px',
  },

  greenUnderlinedLink: {
    color: colors.green,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default globalStyles;

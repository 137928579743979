import React, { useState } from 'react';

// Components
import QA from './QA';

// Styles
import styles from '../../assets/styles/LandingPageStyles';

// Copywriting
import lp from '../../copy/lp.json';

function FAQ({ isMobile }) {
  const [selected, setSelected] = useState(0);

  return (
    <div style={styles.section(isMobile)}>
      <div style={styles.header(isMobile)}>
        <p style={styles.title(isMobile)}>{lp['secao-faq'].titulo}</p>
        <p style={styles.subtitle(isMobile)}>{lp['secao-faq'].subtitulo}</p>
      </div>
      <div style={styles.body(isMobile, false, false, false)}>
        <div style={styles.faqContainer(isMobile)}>
          <QA first isMobile={isMobile} question={lp['secao-faq'].perguntas['pergunta-1'].titulo} answer={lp['secao-faq'].perguntas['pergunta-1'].resposta} id={1} selected={selected} onClick={() => (selected === 1 ? setSelected(0) : setSelected(1))} />
          <QA isMobile={isMobile} question={lp['secao-faq'].perguntas['pergunta-2'].titulo} answer={lp['secao-faq'].perguntas['pergunta-2'].resposta} id={2} selected={selected} onClick={() => (selected === 2 ? setSelected(0) : setSelected(2))} />
          <QA isMobile={isMobile} question={lp['secao-faq'].perguntas['pergunta-3'].titulo} answer={lp['secao-faq'].perguntas['pergunta-3'].resposta} id={3} selected={selected} onClick={() => (selected === 3 ? setSelected(0) : setSelected(3))} />
          <QA isMobile={isMobile} question={lp['secao-faq'].perguntas['pergunta-4'].titulo} answer={lp['secao-faq'].perguntas['pergunta-4'].resposta} id={4} selected={selected} onClick={() => (selected === 4 ? setSelected(0) : setSelected(4))} />
          <QA isMobile={isMobile} question={lp['secao-faq'].perguntas['pergunta-5'].titulo} answer={lp['secao-faq'].perguntas['pergunta-5'].resposta} id={5} selected={selected} onClick={() => (selected === 5 ? setSelected(0) : setSelected(5))} />
          <QA isMobile={isMobile} question={lp['secao-faq'].perguntas['pergunta-6'].titulo} answer={lp['secao-faq'].perguntas['pergunta-6'].resposta} id={6} selected={selected} onClick={() => (selected === 6 ? setSelected(0) : setSelected(6))} />
        </div>
      </div>
    </div>
  );
}

export default FAQ;

import axios from 'axios';
import config from '../../config/host';

function updateLinksWhenQRUpdated(token, oldShort, qrShort) {
  axios
    .put(`${config.host}/api/link/updateLinksWhenQRUpdated`, {
      headers: { authorization: token },
      params: { oldShort, qrShort },
    })
    .then((response) => response);
}

export default updateLinksWhenQRUpdated;

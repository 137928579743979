/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

import styles from '../../assets/styles/Buttons/ButtonStartNowStyles';

function ButtonStartNow({ label, isMobile }) {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  return (
    <div
      className="crie-seu-qr"
      style={styles.buttonStyle(hover, isMobile)}
      onClick={() => navigate('/signin')}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p style={styles.labelStyle(isMobile)}>{label}</p>
    </div>
  );
}

export default ButtonStartNow;

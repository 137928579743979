/* eslint-disable max-len */
import axios from 'axios';
import config from '../../config/host';

function updateLink(token, linkId, linkName, url, osName, timeRestriction, hourRestriction, locRestriction) {
  axios
    .put(`${config.host}/api/link/updateLink`, {
      headers: { authorization: token },
      params: {
        linkId, linkName, url, osName, timeRestriction, hourRestriction, locRestriction,
      },
    })
    .then((response) => response);
}

export default updateLink;

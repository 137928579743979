/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Icons and images
import help from '../assets/icons/help.png';
import add from '../assets/icons/add.png';
import wallet from '../assets/icons/wallet.png';
import profile from '../assets/icons/profile.png';
import back from '../assets/icons/back.png';

// Styles
import styles from '../assets/styles/Layout/TopBarStyles';

function TopBar({ setOverlay, goBack, backPage }) {
  const navigate = useNavigate();

  return (
    <div style={styles.topBarContainer}>
      <div>
        {
          goBack
            ? (
              <div style={styles.goBackContainer} onClick={() => navigate(backPage)}>
                <img alt="back-arrow" src={back} style={styles.shortCutIcons} />
                <p style={styles.goBack}>Cancelar</p>
              </div>
            )
            : false
        }
      </div>
      <div style={styles.shortCutIconsContainer}>
        <img alt="question-mark" src={help} style={styles.shortCutIcons} onClick={() => navigate('/aboutus')} />
        <img alt="cute-wallet" src={wallet} style={styles.shortCutIcons} onClick={() => navigate('/plans')} />
        <img alt="rounded-add" src={add} style={styles.shortCutIcons} onClick={() => setOverlay(true)} />
      </div>
      <div style={styles.profileContainer} onClick={() => navigate('/profile')}>
        <p style={styles.profileTitle}>Perfil</p>
        <img alt="profile-icon" src={profile} style={styles.shortCutIcons} />
      </div>
    </div>
  );
}

export default TopBar;

import axios from 'axios';
import config from '../../config/host';

function updatePlan(token, planId, featured) {
  axios
    .put(`${config.host}/api/user/updatePlan`, {
      headers: { authorization: token },
      params: { planId, featured },
    })
    .then((response) => response);
}

export default updatePlan;

/* eslint-disable no-unreachable-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

// Hooks
import updateLink from '../../hooks/Link/updateLink';

// Inputs
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import ChooseMonths from '../Inputs/MonthInput';
import ChooseWeekDays from '../Inputs/WeekDayInput';
import TimeRangeInput from '../Inputs/TimeRangeInput';
import StateInput from '../Inputs/StateInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Layout
import Line from '../Layout/Line';

// Styles
import styles from '../../assets/styles/Forms/EditLinkFormStyles';
import close from '../../assets/icons/close.png';

function EditLinkForm({
  setOverlay, link, userFeatured, onEditLink, links,
}) {
  const token = localStorage.getItem('token');

  const [linkName, setLinkName] = useState(link.linkName);
  const [linkNameWarning, setLinkNameWarning] = useState('');

  const [url, setUrl] = useState(link.url);
  const [urlWarning, setUrlWarning] = useState('');

  const [osName, setOsName] = useState(link.osName.split(','));
  const [osNameWarning, setOsNameWarning] = useState('');

  const [months, setMonths] = useState(link.timeRestriction.split('-')[0]);
  const [weekDays, setWeekDays] = useState(link.timeRestriction.split('-')[1]);

  const [hourRestriction, setHourRestriction] = useState(link.hourRestriction);

  const [locRestriction, setLocRestriction] = useState(link.locRestriction);

  const [conflictWarning, setConflictWarning] = useState('');

  const [selected, setSelected] = useState(0);

  const handleLinkName = (linkNameInputed) => {
    setLinkName(linkNameInputed);
    setLinkNameWarning(!linkNameInputed ? 'Insira um nome para seu link' : '');
  };

  const handleUrl = (urlInputed) => {
    setUrl(urlInputed);
    const validUrl = /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/.test(urlInputed);
    setUrlWarning(!validUrl ? 'URL inválida' : '');
  };

  const closeOverlay = () => {
    setOverlay(false);
    setSelected(0);
    setLinkName(link.linkName);
    setUrl(link.url);
    setHourRestriction(link.hourRestriction);
  };

  const isThereConflict = (newLink, linkList) => {
    for (const thisLink of linkList) {
      let osNameConflict = false;
      let hourConflict = false;
      let monthConflict = false;
      let weekConflict = false;
      let locConflict = false;
      const osList = newLink.osName.split(',');
      for (const os of osList) {
        if (thisLink.osName.includes(os)) osNameConflict = true;
      }
      const locList = newLink.locRestriction.split(',');
      for (const loc of locList) {
        if (thisLink.locRestriction.includes(loc)) locConflict = true;
      }
      const monthList = newLink.timeRestriction.split('-')[0].split(',');
      for (const month of monthList) {
        if (thisLink.timeRestriction.split('-')[0].includes(month)) monthConflict = true;
      }
      const weekList = newLink.timeRestriction.split('-')[1].split(',');
      for (const week of weekList) {
        if (thisLink.timeRestriction.split('-')[1].includes(week)) weekConflict = true;
      }
      const hourList = [Number(newLink.hourRestriction.split(',')[0]), Number(newLink.hourRestriction.split(',')[1])];
      if (Math.min(hourList[1], Number(thisLink.hourRestriction.split(',')[1])) > Math.max(hourList[0], Number(thisLink.hourRestriction.split(',')[0]))) {
        hourConflict = true;
      }
      if (osNameConflict && locConflict && monthConflict && weekConflict && hourConflict) {
        setConflictWarning('Esse link conflita com outros já criados');
        return true;
      }
    }
    return false;
  };

  const tryUpdateLink = () => {
    if (!osName.length) {
      setOsNameWarning('Insira ao menos uma opção');
      return;
    }
    const newLink = {
      linkId: link._id,
      linkName,
      url,
      osName: osName.toString(),
      timeRestriction: `${months.toString()}-${weekDays.toString()}`,
      hourRestriction,
      locRestriction,
    };
    if (!isThereConflict(newLink, links.filter((el) => el._id !== link._id && !el.default))) {
      setConflictWarning('');
      updateLink(
        token,
        link._id,
        linkName,
        url,
        osName.toString(),
        `${months.toString()}-${weekDays.toString()}`,
        hourRestriction,
        locRestriction,
      );
      onEditLink(newLink);
      closeOverlay();
    }
  };

  return (
    <div style={styles.background}>
      <div style={styles.containerStyles}>
        <img alt="close" src={close} style={styles.close} onClick={closeOverlay} />
        <p style={styles.titleStyles}>Editar Link</p>
        <p style={styles.warningConflict}>{conflictWarning}</p>
        <div style={styles.mainInfo}>
          <TextInput
            label="Nome do seu QR"
            placeholder="Baixe o aplicativo"
            value={linkName}
            setValue={handleLinkName}
            warningMessage={linkNameWarning}
          />
          <TextInput
            label="Link"
            placeholder="https://app.com"
            value={url}
            setValue={handleUrl}
            warningMessage={urlWarning}
          />
        </div>
        <div style={styles.header}>
          <p style={selected === 0 ? styles.subtitleSelected : styles.subtitle} onClick={() => setSelected(0)}>Sistema operacional</p>
          <p style={selected === 1 ? styles.subtitleSelected : styles.subtitle} onClick={() => setSelected(1)}>Tempo</p>
          <p style={selected === 2 ? styles.subtitleSelected : styles.subtitle} onClick={() => setSelected(2)}>Horário</p>
          <p style={selected === 3 ? styles.subtitleSelected : styles.subtitle} onClick={() => setSelected(3)}>Localidade</p>
        </div>
        <Line w="100%" mt="10px" mb="8px" />
        <div style={styles.form}>
          <div style={{ ...styles.row, display: selected === 0 && userFeatured !== 1 ? 'flex' : 'none' }}>
            <SelectInput
              label="Sistema operacional"
              options={['iOS', 'Android']}
              isMulti
              placeholder="Selecione os SOs"
              setValue={setOsName}
              warningMessage={osNameWarning}
              value={osName}
              reseted={osName.length === 2}
            />
            <DefaultButton terciary label="Remover restrição" w="176px" m="28px 0px 0px 54px" onClick={() => setOsName(['iOS', 'Android'])} disabled={osName.length === 2} />
          </div>
          <div style={{ ...styles.row, display: selected === 1 && userFeatured !== 1 ? 'flex' : 'none' }}>
            <div>
              <ChooseMonths
                setValue={setMonths}
                info={false}
                defaultMonths={link.timeRestriction.split('-')[0].split(',')}
                reseted={months.split(',').length === 12}
              />
              <ChooseWeekDays
                setValue={setWeekDays}
                info={false}
                defaultWeekDays={link.timeRestriction.split('-')[1].split(',')}
                reseted={weekDays.split(',').length === 7}
              />
            </div>
            <DefaultButton
              terciary
              label="Remover restrição"
              w="176px"
              m="20px 0px 0px 54px"
              onClick={() => {
                setMonths(['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'].toString());
                setWeekDays(['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'].toString());
              }}
              disabled={months.split(',').length === 12 && weekDays.split(',').length === 7}
            />
          </div>
          <div style={{ ...styles.row, display: selected === 2 && userFeatured === 3 ? 'flex' : 'none' }}>
            <TimeRangeInput
              setValue={setHourRestriction}
              hours={hourRestriction.split(',').map((e) => Number(e))}
              reseted={hourRestriction === '0,48'}
            />
            <DefaultButton
              terciary
              label="Remover restrição"
              w="176px"
              m="20px 0px 0px 44px"
              onClick={() => {
                setHourRestriction('0,48');
              }}
              disabled={hourRestriction === '0,48'}
            />
          </div>
          <div style={{ ...styles.row, display: selected === 3 && userFeatured === 3 ? 'flex' : 'none' }}>
            <StateInput
              defaultStates={link.locRestriction}
              setValue={setLocRestriction}
              reseted={locRestriction.split(',').length === 27}
            />
            <DefaultButton
              terciary
              label="Remover restrição"
              w="176px"
              m="20px 0px 0px 40px"
              onClick={() => {
                setLocRestriction('AC,AP,AM,PA,RO,RR,TO,AL,BA,CE,MA,PB,PE,PI,RN,SE,ES,MG,RJ,SP,DF,GO,MT,MS,PR,RS,SC');
              }}
            />
          </div>
          <div style={{ ...styles.notIncludedContainer, display: (selected === 0 && userFeatured === 1) || (selected === 1 && userFeatured === 1) || (selected === 2 && userFeatured !== 3) || (selected === 3 && userFeatured !== 3) ? 'flex' : 'none' }}>
            <p style={styles.notIncluded}>Não incluso no plano atual</p>
          </div>
        </div>
        <div style={styles.button}>
          <DefaultButton label="Atualizar" w="240px" onClick={tryUpdateLink} />
        </div>
      </div>
    </div>
  );
}

export default EditLinkForm;

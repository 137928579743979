/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { ptBR } from 'date-fns/locale';

import calendar from '../../assets/icons/calendar.png';

import LittleButton from './LittleButton';
import colors from '../../assets/styles/Global/colors';

function DateRange({ setDateRange }) {
  const [visible, setVisible] = useState(false);

  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() - 30);

  const [selectionRange, setSelectionRange] = useState({
    startDate: thirtyDaysFromNow,
    endDate: new Date(),
    key: 'selection',
  });

  function handleSelect(ranges) {
    setSelectionRange(ranges.selection);
    setDateRange(ranges.selection);
  }

  const styles = {
    container: {
      width: '276px',
      height: '44px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderColor: colors.grey,
      padding: '0px 20px 0px 20px',
      color: colors.darkgrey,
      justifyContent: 'space-between',
      cursor: 'pointer',
      margin: '0px 20px 0px 0px',
    },

    icon: {
      width: '20px',
      height: '20px',
    },

    closeIcon: {
      width: '16px',
      height: '16px',
      cursor: 'pointer',
      margin: '24px 20px 0px 0px',
    },

    checkIcon: {
      width: '26px',
      height: '26px',
      cursor: 'pointer',
      marginTop: '24px',
    },

    label: {
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      marginBottom: '6px',
    },

    calendarContainer: {
      position: 'absolute',
      border: '1px solid #EFF2F7',
      marginTop: '10px',
      height: '414px',
      backgroundColor: '#FFFFFF',
      overflow: 'auto',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      zIndex: visible ? 4 : 1,
    },

    buttonContainer: {
      display: 'flex',
      margin: '-6px 10px 0px 0px',
    },
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <p style={styles.label}>Selecione um período</p>
          <div style={styles.container} onClick={() => setVisible(!visible)}>
            <p>
              {selectionRange.startDate.toLocaleDateString()}
              {' '}
              -
              {' '}
              {selectionRange.endDate.toLocaleDateString()}
            </p>
            <img alt="calendar" src={calendar} style={styles.icon} />
          </div>
        </div>
      </div>
      {
                visible
                  ? (
                    <div style={styles.calendarContainer}>
                      <DateRangePicker
                        locale={ptBR}
                        rangeColors={[colors.green]}
                        maxDate={new Date()}
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                      />
                      <div style={styles.buttonContainer}>
                        <LittleButton text="Cancelar" cancel onClick={setVisible} />
                        <LittleButton text="Confirmar" onClick={setVisible} />
                      </div>
                    </div>
                  )
                  : false
            }
    </div>
  );
}

export default DateRange;

import React from 'react';

// Inputs
import TimeRangeInput from '../Inputs/TimeRangeInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/AddLinkFormStyles';

function AddLinkFormStep4({
  setHourRestriction, nextNode, previousNode, createLink, userFeatured, hours,
}) {
  const next = () => nextNode();

  const skip = () => {
    setHourRestriction('0,48');
    nextNode();
  };

  return (
    <div style={styles.headerFormStyles}>
      <p style={styles.titleStyles}>Restrição de Horário</p>
      <div style={styles.inputsContainer}>
        <TimeRangeInput
          setValue={setHourRestriction}
          hours={hours.split(',')}
        />
      </div>
      <div style={styles.buttonsContainer}>
        <DefaultButton w="80px" label="Anterior" terciary onClick={previousNode} />
        {
          userFeatured < 2
            ? (
              <div style={styles.rightButtonsContainer}>
                <DefaultButton w="0px" />
                <DefaultButton w="160px" label="Criar link" onClick={createLink} />
              </div>
            )
            : (
              <div style={styles.rightButtonsContainer}>
                <DefaultButton w="124px" label="Pular" onClick={skip} secundary />
                <DefaultButton w="124px" label="Próximo" onClick={next} />
              </div>
            )
        }
      </div>
    </div>
  );
}

export default AddLinkFormStep4;

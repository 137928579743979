/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
// Formats a integer to hour format (9 to "09h", 14 to "14h", etc.)
const numToHour = (num) => {
  if (num < 10) {
    return `0${num}h`;
  }
  return `${num}h`;
};

// Days of each month
const monthsDays = [
  { abv: 'Jan', name: 'Jan', days: 31 },
  { abv: 'Fev', name: 'Fev', days: 28 },
  { abv: 'Mar', name: 'Mar', days: 31 },
  { abv: 'Abr', name: 'Abr', days: 30 },
  { abv: 'Mai', name: 'Mai', days: 31 },
  { abv: 'Jun', name: 'Jun', days: 30 },
  { abv: 'Jul', name: 'Jul', days: 31 },
  { abv: 'Ago', name: 'Ago', days: 31 },
  { abv: 'Set', name: 'Set', days: 30 },
  { abv: 'Out', name: 'Out', days: 31 },
  { abv: 'Nov', name: 'Nov', days: 30 },
  { abv: 'Dez', name: 'Dez', days: 31 },
];

// Array of days, represented as objects (TODO: classes and methods may be handful here)
const all2023Days = [];
for (const month of monthsDays) {
  for (let day = 1; day <= month.days; day += 1) {
    all2023Days.push({ day, month: month.name, abv: month.abv });
  }
}

// Maps scan to a more friendly object
const groupScans = (scans, thirtyDays = false) => {
  const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  const weekDays = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
  if (thirtyDays) {
    const currentDate = new Date();
    scans = scans.filter((scan) => {
      const scanDate = new Date(scan.scanDate);
      const timeDifference = currentDate.getTime() - scanDate.getTime();
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
      return timeDifference <= thirtyDaysInMilliseconds;
    });
  }
  scans = scans.map((scan) => {
    const date = new Date(scan.scanDate);
    return {
      osName: scan.osName,
      country: scan.scanLocation.split(',')[0],
      state: scan.scanLocation.split(',')[1],
      city: scan.scanLocation.split(',')[2],
      month: months[date.getMonth()],
      weekDay: weekDays[date.getDay() - 1],
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
    };
  });
  return scans;
};

const groupByDay = (scans, thirtyDays = false) => {
  if (!scans.length) return [];
  const group = groupScans(scans, thirtyDays);
  const scansPerDay = [];
  for (const scan of group) {
    let added = false;
    for (const addedScan of scansPerDay) {
      if (addedScan.x === `${scan.day}/${scan.month}`) {
        added = true;
      }
    }
    if (!added) {
      scansPerDay.push({ x: `${scan.day}/${scan.month}`, y: 0 });
    }
  }

  for (const scan of group) {
    for (const scanDay of scansPerDay) {
      if (scanDay.x === `${scan.day}/${scan.month}`) {
        scanDay.y += 1;
      }
    }
  }

  const scansPerAllDay = [];
  let scanPointer = 0;
  let dayPointer = 0;

  const firstDay = Number(scansPerDay[0].x.split('/')[0]);
  const firstMonth = scansPerDay[0].x.split('/')[1];
  const lastDay = Number(scansPerDay[scansPerDay.length - 1].x.split('/')[0]);
  const lastMonth = scansPerDay[scansPerDay.length - 1].x.split('/')[1];

  for (const day in all2023Days) {
    if (all2023Days[day].day === firstDay && all2023Days[day].month === firstMonth) {
      dayPointer = Number(day);
      break;
    }
  }

  while (all2023Days[dayPointer].day !== lastDay || all2023Days[dayPointer].month !== lastMonth) {
    if (Number(scansPerDay[scanPointer].x.split('/')[0]) === all2023Days[dayPointer].day && scansPerDay[scanPointer].x.split('/')[1] === all2023Days[dayPointer].month) {
      scansPerAllDay.push(scansPerDay[scanPointer]);
      scanPointer += 1;
      dayPointer += 1;
    } else {
      scansPerAllDay.push({ x: `${all2023Days[dayPointer].day}/${all2023Days[dayPointer].month}`, y: 0 });
      dayPointer += 1;
    }
  }

  scansPerAllDay.push(scansPerDay[scansPerDay.length - 1]);

  return scansPerAllDay;
};

const groupByMonth = (scans, thirtyDays = false) => {
  if (!scans.length) return [];
  const group = groupScans(scans, thirtyDays);
  const scansPerMonth = [];
  for (const scan of group) {
    let added = false;
    for (const addedScan of scansPerMonth) {
      if (addedScan.x === scan.month) {
        added = true;
      }
    }
    if (!added) {
      scansPerMonth.push({ x: scan.month, y: 0 });
    }
  }

  for (const scan of group) {
    for (const scanMonth of scansPerMonth) {
      if (scanMonth.x === scan.month) {
        scanMonth.y += 1;
      }
    }
  }

  const scansPerAllMonths = [];
  let scanPointer = 0;
  let monthPointer = 0;

  const firstMonth = scansPerMonth[0].x;
  const lastMonth = scansPerMonth[scansPerMonth.length - 1].x;

  for (const month in monthsDays) {
    if (monthsDays[month].abv === firstMonth) {
      monthPointer = Number(month);
      break;
    }
  }

  while (monthsDays[monthPointer].abv !== lastMonth) {
    if (scansPerMonth[scanPointer].x === monthsDays[monthPointer].abv) {
      scansPerAllMonths.push(scansPerMonth[scanPointer]);
      scanPointer += 1;
      monthPointer += 1;
    } else {
      scansPerAllMonths.push({ x: monthsDays[monthPointer].abv, y: 0 });
      monthPointer += 1;
    }
  }

  scansPerAllMonths.push(scansPerMonth[scansPerMonth.length - 1]);

  return scansPerAllMonths;
};

const groupByHour = (scans, thirtyDays = false) => {
  const group = groupScans(scans, thirtyDays);
  const scansPerOs = [];
  for (const scan of group) {
    if (!scansPerOs.filter((el) => el.osName === scan.osName).length) {
      scansPerOs.push({ osName: scan.osName, scansPerHour: [] });
      for (let hour = 0; hour < 24; hour += 1) {
        if (numToHour(hour) === numToHour(scan.hour)) {
          scansPerOs[scansPerOs.length - 1].scansPerHour.push({ x: numToHour(hour), y: 1 });
        } else {
          scansPerOs[scansPerOs.length - 1].scansPerHour.push({ x: numToHour(hour), y: 0.05 });
        }
      }
    } else {
      for (const os of scansPerOs) {
        if (os.osName === scan.osName) {
          for (const hour of os.scansPerHour) {
            if (hour.x === numToHour(scan.hour)) {
              hour.y += 1;
            }
          }
        }
      }
    }
  }
  return scansPerOs;
};

const groupByWeekDay = (scans, thirtyDays = false) => {
  const weekDays = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
  const group = groupScans(scans, thirtyDays);
  const scansPerOs = [];
  for (const scan of group) {
    if (!scansPerOs.filter((el) => el.osName === scan.osName).length) {
      scansPerOs.push({ osName: scan.osName, scansPerWeekDay: [] });
      for (const day of weekDays) {
        if (day === scan.weekDay) {
          scansPerOs[scansPerOs.length - 1].scansPerWeekDay.push({ x: day, y: 1 });
        } else {
          scansPerOs[scansPerOs.length - 1].scansPerWeekDay.push({ x: day, y: 0.05 });
        }
      }
    } else {
      for (const os of scansPerOs) {
        if (os.osName === scan.osName) {
          for (const weekDay of os.scansPerWeekDay) {
            if (weekDay.x === scan.weekDay) {
              weekDay.y += 1;
            }
          }
        }
      }
    }
  }
  return scansPerOs;
};

const groupByOs = (scans, thirtyDays = false) => {
  const group = groupScans(scans, thirtyDays);
  const scansPerOs = [];
  for (const scan of group) {
    if (!scansPerOs.filter((el) => el.x === scan.osName).length) {
      scansPerOs.push({ x: scan.osName, y: 1 });
    } else {
      for (const os of scansPerOs) {
        if (os.x === scan.osName) {
          os.y += 1;
        }
      }
    }
  }
  return scansPerOs;
};

const groupByUTM = (scans) => {
  const allSources = [];
  const allMediuns = [];
  const allCampaigns = [];
  const allTerms = [];
  const allContents = [];

  const sourcesScans = [];
  const mediunsScans = [];
  const campaignsScans = [];
  const termsScans = [];
  const contentsScans = [];

  for (const scan of scans) {
    if (scan.utmSource) {
      if (!allSources.includes(scan.utmSource)) {
        allSources.push(scan.utmSource);
      }
    }
    if (scan.utmMedium) {
      if (!allMediuns.includes(scan.utmMedium)) {
        allMediuns.push(scan.utmMedium);
      }
    }
    if (scan.utmCampaign) {
      if (!allCampaigns.includes(scan.utmCampaign)) {
        allCampaigns.push(scan.utmCampaign);
      }
    }
    if (scan.utmTerm) {
      if (!allTerms.includes(scan.utmTerm)) {
        allTerms.push(scan.utmTerm);
      }
    }
    if (scan.utmContent) {
      if (!allContents.includes(scan.utmContent)) {
        allContents.push(scan.utmContent);
      }
    }
  }

  for (const source of allSources) {
    sourcesScans.push({
      source,
      scans: groupByDay(scans.filter((scan) => scan.utmSource === source)),
    });
  }
  for (const medium of allMediuns) {
    mediunsScans.push({
      medium,
      scans: groupByDay(scans.filter((scan) => scan.utmMedium === medium)),
    });
  }
  for (const campaign of allCampaigns) {
    campaignsScans.push({
      campaign,
      scans: groupByDay(scans.filter((scan) => scan.utmCampaign === campaign)),
    });
  }
  for (const term of allTerms) {
    termsScans.push({
      term,
      scans: groupByDay(scans.filter((scan) => scan.utmTerm === term)),
    });
  }
  for (const content of allContents) {
    contentsScans.push({
      content,
      scans: groupByDay(scans.filter((scan) => scan.utmContent === content)),
    });
  }

  return {
    source: sourcesScans,
    medium: mediunsScans,
    campaign: campaignsScans,
    term: termsScans,
    content: contentsScans,
  };
};

const groupByRegion = (scans) => {
  const scansByRegion = [];
  for (const scan of scans) {
    const scanRegion = scan.scanLocation.split(',')[1];
    let found = false;
    for (const region of scansByRegion) {
      if (region.name === scanRegion) {
        region.scans += 1;
        found = true;
      }
    }
    if (!found) {
      scansByRegion.push({ name: scanRegion, scans: 1 });
    }
  }
  scansByRegion.sort((a, b) => b.scans - a.scans);
  const sliced5 = scansByRegion.slice(0, 5);
  return sliced5;
};

export {
  groupScans, groupByWeekDay, groupByHour, groupByOs, groupByDay, groupByUTM, groupByMonth, groupByRegion,
};

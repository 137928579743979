import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/host';

function useFetchUser(token) {
  const [user, setUser] = useState({});
  useEffect(() => {
    axios
      .get(`${config.host}/api/user/getUser`, {
        headers: { authorization: token },
      })
      .then((response) => setUser(response.data));
  }, []);

  return user;
}

export default useFetchUser;

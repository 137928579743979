/* eslint-disable no-underscore-dangle */
import React from 'react';

// Styles
import styles from '../../assets/styles/DeleteStyles';

// General components
import DefaultButton from '../Buttons/DefaultButton';

// Hooks
import deleteUTM from '../../hooks/UTM/deleteUTM';

function DeleteUTM({
  setOverlay, utmLink, onDeleteUTM,
}) {
  const token = localStorage.getItem('token');

  const deleteThisLink = () => {
    setOverlay(false);
    deleteUTM(token, utmLink._id);
    onDeleteUTM(utmLink._id);
  };

  return (
    <div style={styles.background}>
      <div style={styles.containerStyles}>
        <div style={styles.titleContainer}>
          <p style={styles.titleStyles}>Deletar Link?</p>
        </div>
        <div style={styles.body}>
          <p style={styles.bodyText}>
            Essa ação deletará esse link
            <span style={{ fontWeight: 500 }}>permanentemente</span>
            . Não se preocupe, o QR Code será mantido com o restante dos links
          </p>
        </div>
        <div style={styles.buttonsContainer}>
          <DefaultButton label="Cancelar" w="172px" secundary onClick={() => setOverlay(false)} />
          <DefaultButton label="Sim, deletar" w="172px" onClick={deleteThisLink} />
        </div>
      </div>
    </div>
  );
}

export default DeleteUTM;

import React from 'react';

import UTMLinkBox from './UTMLinkBox';

import DefaultButton from '../Buttons/DefaultButton';

import styles from '../../assets/styles/UTMLinksStyles';

function UTMLinks({
  setOverlay, setDeleteUTMOverlay, selectUTMLink, utms, setEditUTMOverlay, scans,
}) {
  return (
    <div style={styles.container}>
      <div style={styles.links}>
        <div>
          <p style={styles.linkDescription}>Links com UTM Tracking</p>
        </div>
        <div style={styles.linksContainer}>
          {
            utms.map((utmLink) => (
              <UTMLinkBox
                utmLink={utmLink}
                url={utmLink.url}
                accesses={scans.filter((scan) => scan.utmUrl === utmLink.url).length}
                selectUTMLink={selectUTMLink}
                setDeleteUTMOverlay={setDeleteUTMOverlay}
                setEditUTMOverlay={setEditUTMOverlay}
                key={utmLink._id}
                utmId={utmLink._id}
              />
            ))
          }
        </div>
        <div style={styles.button}>
          <DefaultButton label="Adicionar novo" w="24%" secundary onClick={() => setOverlay(true)} />
        </div>
      </div>
    </div>
  );
}

export default UTMLinks;

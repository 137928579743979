import globalStyles from './Global/globalStyles';
import colors from './Global/colors';

const styles = {
  container(n) {
    return {
      ...globalStyles.horizontalSpaceBetween,
      margin: '20px 0px 0px 100px',
      width: `${960 * (n / 4)}px`,
    };
  },

  notSelected: {
    ...globalStyles.center,
    ...globalStyles.pointer,
    width: '226px',
    height: '39px',
    borderRadius: '30px',
    border: '1px solid',
    borderColor: colors.grey,
    color: colors.darkgrey,
  },

  selected: {
    ...globalStyles.center,
    ...globalStyles.pointer,
    width: '226px',
    height: '39px',
    backgroundColor: colors.green,
    borderRadius: '30px',
    fontWeight: '500',
  },

  text: {
    fontSize: '18px',
  },

  disabled: {
    ...globalStyles.center,
    cursor: 'not-allowed',
    backgroundColor: colors.lesslightgrey,
    width: '226px',
    height: '39px',
    borderRadius: '30px',
    border: '1px solid',
    borderColor: colors.lesslightgrey,
    color: colors.darkgrey,
    opacity: 0.4,
  },
};

export default styles;

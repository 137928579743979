/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unreachable-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config/host';

// Navigation

// Hooks
import updateNOfLinks from '../../hooks/QR/updateNOfLinks';

// Components
import AddLinkFormStep1 from './AddLinkFormStep1';
import AddLinkFormStep2 from './AddLinkFormStep2';
import AddLinkFormStep3 from './AddLinkFormStep3';
import AddLinkFormStep4 from './AddLinkFormStep4';
import AddLinkFormStep5 from './AddLinkFormStep5';
import ProgressBar from './ProgressBar';

// Styles
import styles from '../../assets/styles/Forms/AddLinkFormStyles';
import close from '../../assets/icons/close.png';

function AddLinkForm({
  setOverlay, userPlan, qrId, qrShort, nOfLinks, userFeatured, links, setConflictWarning, onCreateLink,
}) {
  const token = localStorage.getItem('token');

  const [nodes, setNodes] = useState([1, 0, 0, 0, 0]);
  const [current, setCurrent] = useState(1);

  const [linkName, setLinkName] = useState('');
  const [linkNameWarning, setLinkNameWarning] = useState('');

  const [url, setUrl] = useState('https://');
  const [urlWarning, setUrlWarning] = useState('');

  const [osName, setOsName] = useState(['iOS', 'Android']);
  const [osNameWarning, setOsNameWarning] = useState('');

  const [months, setMonths] = useState('Janeiro,Fevereiro,Março,Abril,Maio,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro');
  const [weekDays, setWeekDays] = useState('Segunda,Terça,Quarta,Quinta,Sexta,Sábado,Domingo');

  const [hourRestriction, setHourRestriction] = useState('0,48');

  const [locRestriction, setLocRestriction] = useState('');

  const isThereConflict = (newLink, linkList) => {
    for (const link of linkList) {
      let osNameConflict = false;
      let hourConflict = false;
      let monthConflict = false;
      let weekConflict = false;
      let locConflict = false;
      const osList = newLink.osName.split(',');
      for (const os of osList) {
        if (link.osName.includes(os)) osNameConflict = true;
      }
      const locList = newLink.locRestriction.split(',');
      for (const loc of locList) {
        if (link.locRestriction.includes(loc)) locConflict = true;
      }
      const monthList = newLink.timeRestriction.split('-')[0].split(',');
      for (const month of monthList) {
        if (link.timeRestriction.split('-')[0].includes(month)) monthConflict = true;
      }
      const weekList = newLink.timeRestriction.split('-')[1].split(',');
      for (const week of weekList) {
        if (link.timeRestriction.split('-')[1].includes(week)) weekConflict = true;
      }
      const hourList = [Number(newLink.hourRestriction.split(',')[0]), Number(newLink.hourRestriction.split(',')[1])];
      if (Math.min(hourList[1], Number(link.hourRestriction.split(',')[1])) > Math.max(hourList[0], Number(link.hourRestriction.split(',')[0]))) {
        hourConflict = true;
      }
      if (osNameConflict && locConflict && monthConflict && weekConflict && hourConflict) {
        setConflictWarning(true);
        setTimeout(() => {
          setConflictWarning(false);
        }, 3000);
        return 'Esse link conflita com outros já criados';
      }
      return '';
    }
    return false;
  };

  const createLink = () => {
    const newLink = {
      qrId,
      linkName,
      qrShort,
      osName: osName.toString(),
      timeRestriction: `${months}-${weekDays}`,
      hourRestriction,
      locRestriction,
      url,
      default: false,
    };
    const conflict = isThereConflict(newLink, links.filter((link) => !link.default));
    if (!conflict) {
      axios
        .post(`${config.host}/api/link/createLink`, newLink, {
          headers: { authorization: token },
        })
        .then((response) => onCreateLink(response.data));
      setOverlay(false);
      updateNOfLinks(token, qrShort, 1);
    }
  };

  const nextNode = () => {
    const updatedNodes = nodes;
    for (const i in updatedNodes) {
      if (updatedNodes[i] === 0) {
        updatedNodes[i] = 1;
        setCurrent(Number(i) + 1);
        break;
      }
    }
    setNodes(updatedNodes);
  };

  const previousNode = () => {
    const updatedNodes = nodes;
    if (current === nodes.length) {
      updatedNodes[nodes.length - 1] = 0;
      setCurrent(nodes.length - 1);
      setNodes(updatedNodes);
      return;
    }
    for (const i in updatedNodes) {
      if (updatedNodes[i] === 0) {
        updatedNodes[i - 1] = 0;
        setCurrent(Number(i) - 1);
        break;
      }
    }
    setNodes(updatedNodes);
  };

  const closeOverlay = () => {
    setLinkName('');
    setUrl('');
    setOsName('');
    setHourRestriction('');
    setLocRestriction('');
    setLinkNameWarning('');
    setUrlWarning('');
    setOverlay(false);
    setCurrent(1);
    const updatedNodes = nodes;
    for (const i in updatedNodes) {
      if (i === 0) {
        updatedNodes[i] = 1;
      } else {
        updatedNodes[i] = 0;
      }
    }
    setNodes(updatedNodes);
  };

  return (
    <div style={styles.background}>
      <div style={styles.formContainer}>
        <img alt="close" src={close} style={styles.close} onClick={closeOverlay} />
        <ProgressBar nodes={nodes} userPlan={userPlan} userFeatured={userFeatured} names={['Link', 'SO', 'Tempo', 'Horário', 'Local']} />
        {
          current === 1

            ? (
              <AddLinkFormStep1
                nextNode={nextNode}
                setOverlay={closeOverlay}
                linkName={linkName}
                setLinkName={setLinkName}
                linkNameWarning={linkNameWarning}
                setLinkNameWarning={setLinkNameWarning}
                url={url}
                setUrl={setUrl}
                urlWarning={urlWarning}
                setUrlWarning={setUrlWarning}
                createLink={createLink}
                userPlan={userPlan}
                userFeatured={userFeatured}
                nOfLinks={nOfLinks}
              />
            )
            : current === 2

              ? (
                <AddLinkFormStep2
                  nextNode={nextNode}
                  previousNode={previousNode}
                  osName={osName}
                  setOsName={setOsName}
                  osNameWarning={osNameWarning}
                  setOsNameWarning={setOsNameWarning}
                  createLink={createLink}
                  userFeatured={userFeatured}
                />
              )
              : current === 3

                ? (
                  <AddLinkFormStep3
                    nextNode={nextNode}
                    previousNode={previousNode}
                    setMonths={setMonths}
                    setWeekDays={setWeekDays}
                    createLink={createLink}
                  />
                )
                : current === 4

                  ? (
                    <AddLinkFormStep4
                      nextNode={nextNode}
                      previousNode={previousNode}
                      setHourRestriction={setHourRestriction}
                      createLink={createLink}
                      userFeatured={userFeatured}
                      hours={hourRestriction}
                    />
                  )
                  : current === 5

                    ? (
                      <AddLinkFormStep5
                        createLink={createLink}
                        previousNode={previousNode}
                        setLocRestriction={setLocRestriction}
                      />
                    ) : false
        }
      </div>
    </div>
  );
}

export default AddLinkForm;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

// Navigation
import { useNavigate, useParams } from 'react-router-dom';
import config from '../config/host';

// Styles
import styles from '../assets/styles/Layout/SideBarStyles';

// Icons and images
import logo from '../assets/images/logo_color.png';
import add from '../assets/icons/add.png';
import search from '../assets/icons/search.png';
import newfolder from '../assets/icons/newfolder.png';
import wallet from '../assets/icons/wallet.png';
import support from '../assets/icons/support.png';
import qrcode from '../assets/icons/qrcode.png';
import logout from '../assets/icons/logout.png';
import link from '../assets/icons/link.png';
import tracking from '../assets/icons/tracking.png';

import Line from '../components/Layout/Line';

function SideBar({
  setOverlay, qrList, setQR, setLinks, setScans, setUTMs,
}) {
  const navigate = useNavigate();
  const { qrShort } = useParams();

  const token = localStorage.getItem('token');
  if (!token) navigate('/login');

  const [addHover, setAddHover] = useState(false);
  const [addClicked, setAddClicked] = useState(false);
  const [searchHover, setSearchHover] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [plansHover, setPlansHover] = useState(false);
  const [supportHover, setSupportHover] = useState(false);

  const [qrHover, setQRHover] = useState(false);
  const [folderHover, setFolderHover] = useState(false);

  const [hoveredQR, setHoveredQR] = useState(-1);
  const [clickedQR, setClickedQR] = useState(-1);

  const [searchText, setSearchText] = useState('');

  const refAdd = useRef(null);
  const refSearch = useRef(null);
  const refQR = useRef(null);

  function useOutsideAlerter(ref, setCurrentOverlay, variable) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCurrentOverlay(variable);
          setHoveredQR(-1);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(refAdd, setAddClicked, false);
  useOutsideAlerter(refSearch, setSearchClicked, false);
  useOutsideAlerter(refQR, setClickedQR, -1);

  const selectQR = (thisQRShort, analysis = false) => {
    axios
      .get(`${config.host}/api/qr/getQRFromShort`, {
        headers: { authorization: token },
        params: { qrShort: thisQRShort },
      })
      .then((response) => setQR(response.data[0]));
    axios
      .get(`${config.host}/api/link/getLinksFromQRShort`, {
        params: { qrShort: thisQRShort },
      })
      .then((response) => setLinks(response.data));
    axios
      .get(`${config.host}/api/utm/getUTMsFromQRShort`, {
        headers: { authorization: token },
        params: { qrShort: thisQRShort },
      })
      .then((response) => setUTMs(response.data));
    axios
      .get(`${config.host}/api/scan/getScansFromQRShort`, {
        headers: { authorization: token },
        params: { qrShort: thisQRShort },
      })
      .then((response) => setScans(response.data));
    setClickedQR(-1);
    navigate(!analysis ? `/qr/${thisQRShort}` : `/qr/${thisQRShort}?analysis=true`);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div style={styles.sideBarContainer}>
      <div style={styles.header} ref={refAdd}>
        <img alt="thundercode-logo" src={logo} style={styles.logo} onClick={() => navigate('/home')} />
        <div style={styles.selectAdd(addClicked)}>
          <div
            style={styles.itemToAdd(qrHover)}
            onMouseEnter={() => setQRHover(true)}
            onMouseLeave={() => setQRHover(false)}
            onClick={setOverlay}
          >
            <img alt="qrcode-icon" src={qrcode} style={styles.subItemIcon} />
            <p>Novo QR Code</p>
          </div>
          <div
            style={styles.itemToAdd(folderHover)}
            onMouseEnter={() => setFolderHover(true)}
            onMouseLeave={() => setFolderHover(false)}
            onClick={() => setAddClicked(false)}
          >
            <img alt="add-folder" src={newfolder} style={styles.subItemIcon} />
            <p>Nova pasta</p>
          </div>
        </div>
        <div
          style={styles.itemContainer(addHover, addClicked)}
          onMouseEnter={() => setAddHover(true)}
          onMouseLeave={() => setAddHover(false)}
          onClick={() => {
            setAddClicked(!addClicked);
            setClickedQR(-1);
          }}
        >
          <div style={styles.itemContent}>
            <img alt="add" src={add} style={styles.sideBarIcons} />
            <p style={styles.itemTitle}>Adicionar</p>
          </div>
          <div />
        </div>
        <div
          style={styles.searchContainer(searchHover, searchClicked)}
          onMouseEnter={() => setSearchHover(true)}
          onMouseLeave={() => setSearchHover(false)}
          onClick={() => setSearchClicked(true)}
          ref={refSearch}
        >
          <div style={styles.searchContent}>
            <img alt="search-icon" src={search} style={styles.sideBarIcons} onClick={() => setSearchClicked(false)} />
            <input
              style={styles.searchInput(searchClicked, searchHover)}
              placeholder="Pesquisar"
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            />
          </div>
          <div />
        </div>
      </div>
      <Line w="calc(100% - 16px)" ml="8px" mb="20px" />
      <div style={styles.allQRsContainer} ref={refQR}>
        {
          qrList
            .filter((qr) => (searchText ? qr.qrName.toLowerCase().includes(searchText) || qr.defaultLink.toLowerCase().includes(searchText) : true))
            .map((qr, idx) => (
              <div key={qr._id}>
                <div style={styles.selectAdd(idx === clickedQR)}>
                  <div
                    style={styles.itemToAdd(qrHover)}
                    onMouseEnter={() => setQRHover(true)}
                    onMouseLeave={() => setQRHover(false)}
                    onClick={() => selectQR(qr.qrShort)}
                  >
                    <img alt="link-icon" src={link} style={styles.subItemIcon} />
                    <p>Ver ou adicionar links</p>
                  </div>
                  <div
                    style={styles.itemToAdd(folderHover)}
                    onMouseEnter={() => setFolderHover(true)}
                    onMouseLeave={() => setFolderHover(false)}
                    onClick={() => selectQR(qr.qrShort, true)}
                  >
                    <img alt="growing-arrow" src={tracking} style={styles.subItemIcon} />
                    <p>Ver análise de leituras</p>
                  </div>
                </div>

                <div
                  key={qr._id}
                  style={(window.location.pathname.includes('qr') && qr.qrShort === qrShort) ? styles.qrContainerSelected : styles.qrContainer(hoveredQR === idx)}
                  onClick={() => {
                    setClickedQR(idx);
                    setHoveredQR(idx);
                    setAddClicked(false);
                  }}
                  onMouseEnter={() => setHoveredQR(idx)}
                  onMouseLeave={() => {
                    if (clickedQR !== idx) {
                      setHoveredQR(-1);
                    }
                  }}
                >
                  <p style={styles.qrTitle}>{qr.qrName}</p>
                </div>
              </div>
            ))
        }
      </div>
      <Line w="calc(100% - 16px)" ml="8px" mb="20px" />
      <div style={styles.sideBarEnding}>
        <div>
          <div
            style={styles.itemContainer(plansHover)}
            onMouseEnter={() => setPlansHover(true)}
            onMouseLeave={() => setPlansHover(false)}
            onClick={() => navigate('/plans')}
          >
            <div style={styles.itemContent}>
              <img alt="cute-wallet" src={wallet} style={styles.sideBarIcons} />
              <p style={styles.itemTitle}>Planos</p>
            </div>
            <div />
          </div>
          <div
            style={styles.itemContainer(supportHover)}
            onMouseEnter={() => setSupportHover(true)}
            onMouseLeave={() => setSupportHover(false)}
            onClick={() => navigate('/support')}
          >
            <div style={styles.itemContent}>
              <img alt="support-icon" src={support} style={styles.sideBarIcons} />
              <p style={styles.itemTitle}>Suporte</p>
            </div>
            <div />
          </div>
        </div>
        <div>
          <div style={styles.itemContainer(false)} onClick={logOut}>
            <div style={styles.itemContent}>
              <img alt="logout-icon" src={logout} style={styles.sideBarIcons} />
              <p style={styles.itemTitle}>Sair</p>
            </div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;

const extractTrackingParameters = (trackingURL) => {
  const parameters = {};

  const url = new URL(trackingURL);
  const { searchParams } = url;
  const baseUrl = `${url.protocol}//${url.host}`;

  searchParams.forEach((value, key) => {
    const parameterName = key.replace('utm_', '');
    parameters[parameterName] = decodeURIComponent(value);
  });

  parameters.redirect = baseUrl;

  return parameters;
};

export default extractTrackingParameters;

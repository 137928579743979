/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config/host';

// Hooks
import updateLinksWhenQRUpdated from '../../hooks/Link/updateLinksWhenQRUpdated';
import updateScansWhenQRUpdated from '../../hooks/Scan/updateScansWhenQRUpdated';

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import ButtonLogin from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/EditQRFormStyles';
import close from '../../assets/icons/close.png';

function EditQRForm({
  setOverlay, qr, onEditQRCode,
}) {
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const [qrName, setQRName] = useState(qr.qrName);
  const [qrNameWarning, setQRNameWarning] = useState('');

  const [defaultLink, setDefaultLink] = useState(qr.defaultLink);
  const [defaultLinkWarning, setDefaultLinkWarning] = useState('');

  const [qrShort, setQRShort] = useState(qr.qrShort);
  const [qrShortWarning, setQRShortWarning] = useState('');

  const updateQR = () => {
    if (defaultLink) {
      axios.put(`${config.host}/api/link/updateEscapeLink`, {
        headers: { authorization: token },
        params: {
          qrShort: qr.qrShort,
          defaultLink,
        },
      });
    }
    axios
      .put(`${config.host}/api/qr/updateQRFromId`, {
        headers: { authorization: token },
        params: {
          qrId: qr._id,
          qrName,
          defaultLink,
          qrShort,
        },
      })
      .then((response) => {
        if (response.data.code === 11000) {
          setQRShortWarning('Esse nome já existe');
        } else {
          onEditQRCode({
            qrId: qr._id,
            qrName,
            defaultLink,
            qrShort,
          });
          if (qrShort !== qr.qrShort) {
            updateLinksWhenQRUpdated(token, qr.qrShort, qrShort);
            updateScansWhenQRUpdated(token, qr.qrShort, qrShort);
          }
          setOverlay(false);
          navigate(`/qr/${qrShort}`);
        }
      });
  };

  const handleQRName = (qrNameInputed) => {
    setQRName(qrNameInputed);
    if (!qrNameInputed) {
      setQRNameWarning('Insira um nome para o seu QR Code');
    } else {
      setQRNameWarning('');
    }
  };

  const handleDefaultLink = (defaultLinkInputed) => {
    if (defaultLinkInputed.includes('https://')) {
      setDefaultLink(defaultLinkInputed);
      const validLink = /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/.test(defaultLinkInputed);
      setDefaultLinkWarning(
        !defaultLink
          ? 'Insira um link para o seu QR Code'
          : !validLink
            ? 'Link inválido'
            : '',
      );
    }
  };

  const handleQRShort = (qrShortInputed) => {
    setQRShort(qrShortInputed);
    if (!qrShortInputed) {
      setQRShortWarning('Insira uma URL');
    } else {
      setQRShortWarning('');
    }
  };

  const closeOverlay = () => {
    setOverlay(false);
    setQRName(qr.qrName);
    setDefaultLink(qr.defaultLink);
    setQRShort(qr.qrShort);
  };

  return (
    <div style={styles.background}>
      <div style={styles.containerStyles}>
        <img alt="close" src={close} style={styles.close} onClick={closeOverlay} />
        <p style={styles.titleStyles}>Editar QR Code</p>
        <div style={styles.inputsStyles}>
          <TextInput
            label="Nome do QR"
            placeholder="Baixe o aplicativo"
            setValue={handleQRName}
            value={qrName}
            warningMessage={qrNameWarning}
          />
          <TextInput
            label="Link"
            placeholder="https://app.com"
            setValue={handleDefaultLink}
            value={defaultLink}
            warningMessage={defaultLinkWarning}
          />
          <div style={styles.row}>
            <div style={styles.labelLink}>
              <p style={styles.label}>URL do QR Code</p>
              <p style={styles.link}>thunderco.de/redirect/</p>
            </div>
            <TextInput
              placeholder="short"
              setValue={handleQRShort}
              value={qrShort}
              warningMessage={qrShortWarning}
              w="199px"
            />
          </div>
        </div>
        <ButtonLogin
          label="Atualizar QR Code"
          onClick={updateQR}
        />
      </div>
    </div>
  );
}

export default EditQRForm;

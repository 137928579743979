/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import styled from 'styled-components';
import { useRanger } from 'react-ranger';
import colors from '../../assets/styles/Global/colors';

export const Track = styled('div')`
  display: inline-block;
  height: 7px;
  width: 100%;
  margin: 0 5%;
`;

export const Tick = styled('div')`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    height: 10px;
    width: 2px;
    transform: translate(-50%, 0.6rem);
  }
`;

export const TickLabel = styled('div')`
  position: absolute;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.6rem);
  white-space: nowrap;
`;

export const Segment = styled('div')`
  background: ${(props) => (props.index === 0
    ? colors.grey
    : props.index === 1
      ? colors.green
      : props.index === 2
        ? colors.grey
        : colors.grey)};
  height: 100%;
`;

export const Handle = styled('div')`
    background: ${colors.green};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 2rem;
    border-radius: 18%;
    font-size: 1rem;
    white-space: nowrap;
    color: white;
    cursor: pointer;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    transform: ${(props) => (props.active ? 'translateY(-100%) scale(1.3)' : 'translateY(0) scale(0.95)')};
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    `;

function TimeRangeInput({ setValue, hours, reseted }) {
  const [values, setValues] = useState(hours);

  const handleChange = (vals) => {
    setValues(vals);
    setValue(vals.toString());
  };

  if (reseted && (values[0] !== 0 || values[1] !== 48)) {
    setValues([0, 48]);
  }

  const value2Hour = (value) => {
    if (value === 48) {
      return '23h59';
    }
    if (value % 2) {
      if (value / 2 < 10) {
        return `0${Math.floor(value / 2)}h30`;
      }
      return `${Math.floor(value / 2)}h30`;
    }
    if (value / 2 < 10) {
      return `0${value / 2}h00`;
    }
    return `${value / 2}h00`;
  };

  const {
    getTrackProps, ticks, segments, handles,
  } = useRanger({
    min: 0,
    max: 48,
    stepSize: 1,
    values,
    onChange: handleChange,
  });

  const styles = {
    containerDisplay: {
      display: 'flex',
      justifyContent: 'center',
      width: '420px',
      height: '80px',
      margin: '12px 0px 0px 0px',
    },

    displayTime: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '28px',
      fontWeight: '500',
    },

    to: {
      fontSize: '28px',
      margin: '0px 10px',
      fontWeight: '100',
    },

    label: {
      fontSize: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.9px',
      marginBottom: '6px',
    },

    containerSelector: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '12px',
      width: '430px',
      height: '80px',
    },
  };

  return (
    <div>
      {
        Number(values[0]) === 0 && Number(values[1]) === 48
          ? (
            <div style={styles.containerDisplay}>
              <p style={styles.displayTime}>Dia todo</p>
            </div>
          )
          : (
            <div style={styles.containerDisplay}>
              <p style={styles.displayTime}>{value2Hour(values[0])}</p>
              <p style={styles.to}>a</p>
              <p style={styles.displayTime}>{value2Hour(values[1])}</p>
            </div>
          )
      }
      <div style={styles.containerSelector}>
        <Track {...getTrackProps()}>
          {ticks.map(({ value, getTickProps }) => (
            <Tick {...getTickProps()}>
              <TickLabel>{value === 48 ? '23h59' : value < 20 ? `0${value / 2}h` : `${value / 2}h`}</TickLabel>
            </Tick>
          ))}
          {segments.map(({ getSegmentProps }, i) => (
            <Segment {...getSegmentProps()} index={i} />
          ))}
          {handles.map(({ value, active, getHandleProps }) => (
            <button
              {...getHandleProps({
                style: {
                  appearance: 'none',
                  border: 'none',
                  background: 'transparent',
                  outline: 'none',
                },
              })}
            >
              <Handle active={active}>{value === 48 ? '23h59' : value < 20 ? value % 2 ? `0${(value - 1) / 2}h30` : `0${value / 2}h00` : value % 2 ? `${(value - 1) / 2}h30` : `${value / 2}h00`}</Handle>
            </button>
          ))}
        </Track>
      </div>
    </div>
  );
}

export default TimeRangeInput;

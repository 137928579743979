import axios from 'axios';
import config from '../../config/host';

function deleteLink(token, linkId) {
  axios
    .delete(`${config.host}/api/link/deleteLink`, {
      headers: { authorization: token },
      params: { linkId },
    })
    .then((response) => response);
}

export default deleteLink;

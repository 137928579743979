import globalStyles from './Global/globalStyles';

const styles = {
  headerInfoContainerStyles: {
    ...globalStyles.row,
    width: 'calc(100vw - 310px)',
    height: '142px',
    padding: '0px 100px 0px 100px',
  },

  qrContainerStyles: {
    width: '150px',
    display: 'flex',
    alignItems: 'center',
  },

  infoContainerStyles: {
    width: 'calc(100% - 150px)',
    padding: '0px 10px 0px 40px',
  },

  titleStyles: {
    fontSize: '32px',
    fontWeight: 'bold',
  },

  dataContainerStyles: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    justifyContent: 'space-between',
    marginTop: '8px',
  },

  optionsContainer: {
    ...globalStyles.horizontalSpaceBetween,
    margin: '20px 0px 0px 100px',
    width: '600px',
    height: '20px',
  },

  subtitle: {
    fontSize: '18px',
  },

  headerTitle: {
    ...globalStyles.horizontalSpaceBetween,
    width: '100%',
  },

  iconsContainer: {
    ...globalStyles.horizontalSpaceBetween,
    width: '92px',
  },

  icon: {
    ...globalStyles.pointer,
    width: '28px',
  },

  downloadButtons: {
    ...globalStyles.horizontalSpaceBetween,
    width: '204px',
  },

  underLine: {
    ...globalStyles.horizontalSpaceBetween,
  },

  copyToClipboard(hover, copied) {
    return {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#EDEDED',
      padding: '4px 8px 4px 8px',
      fontSize: '13px',
      borderRadius: '4px',
      margin: '-66px 0px 0px -30px',
      opacity: hover || copied ? 1 : 0,
      transition: hover || copied ? 'opacity 0.3s ease-in-out' : '',
    };
  },

  chat: {
    width: '9px',
    height: '9px',
    backgroundColor: '#EDEDED',
    position: 'absolute',
    margin: '24px 0px 0px 8px',
    transform: 'rotate(45deg)',
  },
};

export default styles;

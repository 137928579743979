/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config/host';

// Navigation

// Hooks
import useFetchUser from '../../hooks/User/useFetchUser';

// Logic
import generateTrackingURL from '../../logic/generateUTM';

// Inputs
import TextInput from '../Inputs/TextInput';

// Buttons
import DefaultButton from '../Buttons/DefaultButton';

// Styles
import styles from '../../assets/styles/Forms/CreateUTMLinkFormStyles';
import close from '../../assets/icons/close.png';

function AddUTMLink({
  setOverlay, redirect, qr, onCreateUTM,
}) {
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  if (!token) navigate('/login');

  const user = useFetchUser(token);

  const [source, setSource] = useState('');
  const [medium, setMedium] = useState('');
  const [campaign, setCampaign] = useState('');
  const [term, setTerm] = useState('');
  const [content, setContent] = useState('');

  const closeOverlay = () => {
    setSource('');
    setMedium('');
    setCampaign('');
    setTerm('');
    setContent('');
    setOverlay(false);
  };

  const tryCreateUTMLink = () => {
    const newUTM = {
      url: generateTrackingURL(redirect, source, medium, campaign, term, content),
      userId: user._id,
      qrId: qr._id,
      qrShort: qr.qrShort,
    };
    axios
      .post(`${config.host}/api/utm/createUTM`, newUTM, {
        headers: { authorization: token },
      })
      .then((response) => onCreateUTM(response.data));
    closeOverlay();
  };

  return (
    <div style={styles.background}>

      <div style={{ ...styles.containerStyles, position: 'relative' }}>
        <img alt="close" src={close} style={styles.close} onClick={closeOverlay} />
        <p style={styles.titleStyles}>Criar novo UTM Link</p>
        <div style={styles.inputsStyles}>
          <TextInput
            w="12vw"
            label="Source"
            placeholder="source"
            setValue={setSource}
            value={source}
          />
          <TextInput
            w="12vw"
            label="Medium"
            placeholder="medium"
            setValue={setMedium}
            value={medium}
          />
          <TextInput
            w="12vw"
            label="Campaign"
            placeholder="campaign"
            setValue={setCampaign}
            value={campaign}
          />
          <TextInput
            w="12vw"
            placeholder="term"
            label="Term"
            setValue={setTerm}
            value={term}
          />
          <TextInput
            w="12vw"
            placeholder="content"
            label="Content"
            setValue={setContent}
            value={content}
          />
        </div>
        <p style={{ fontSize: '17px', alignSelf: 'flex-start', marginLeft: '4%' }}>
          Prévia:
          {' '}
          <span style={{ fontWeight: '500', textDecoration: 'underline' }}>{generateTrackingURL(redirect, source, medium, campaign, term, content)}</span>
        </p>
        <DefaultButton
          label="Criar UTM link"
          onClick={tryCreateUTMLink}
        />
      </div>
    </div>
  );
}

export default AddUTMLink;

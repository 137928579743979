import React, { useState, useEffect } from 'react';

// Navigation
import { useParams, useNavigate } from 'react-router-dom';

// Hooks
import updatePlan from '../hooks/User/updatePlan';
import useFetchPlan from '../hooks/Plan/useFetchPlan';
import useFetchQRFromUser from '../hooks/QR/useFetchQRFromUser';

// Layout
import SideBar from '../layouts/SideBar';
import TopBar from '../layouts/TopBar';
import Line from '../components/Layout/Line';

// Forms
// eslint-disable-next-line import/no-named-as-default-member
import BillingInformation from '../components/Forms/BillingInformation';
import CreateQRForm from '../components/Forms/CreateQRForm';

// Components
import CartReview from '../components/Checkout/CartReview';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Checkout() {
  const navigate = useNavigate();
  const { planId } = useParams();

  // Token (session)
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) navigate('/login');
  });

  // All QR Codes from the user
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  const onCreateQRCode = (newQRCode) => setQRList((prev) => [...prev, newQRCode]);
  const [createQROverlay, setCreateQROverlay] = useState(false);

  const [plan, setPlan] = useState({});
  useFetchPlan(planId, setPlan);

  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [cpf, setCPF] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [cardName, setCardName] = useState('');

  const tryUpdatePlan = () => updatePlan(token, planId, plan.featureLevel);

  return (
    <div style={globalStyles.row}>
      {
        createQROverlay
          ? (
            <CreateQRForm
              setOverlay={setCreateQROverlay}
              onCreateQRCode={onCreateQRCode}
            />
          ) : false
      }
      <SideBar
        qrList={qrList}
        onCreateQRCode={onCreateQRCode}
        setOverlay={setCreateQROverlay}
      />
      <div>
        <TopBar
          goBack
          backPage="/plans"
          setOverlay={setCreateQROverlay}
        />
        <div style={globalStyles.containerPadding}>
          <p style={globalStyles.boldTitle}>
            Checkout:
            {' '}
            <span style={globalStyles.regular}>{plan.productName}</span>
          </p>
          <Line w="calc(100vw - 480px)" mt="24px" mb="40px" />
          <div style={globalStyles.horizontalSpaceBetween}>
            <BillingInformation
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              cardCVV={cardCVV}
              setCardCVV={setCardCVV}
              cardExpiration={cardExpiration}
              setCardExpiration={setCardExpiration}
              cardName={cardName}
              setCardName={setCardName}
              cpf={cpf}
              setCPF={setCPF}
              onClick={tryUpdatePlan}
            />
            <CartReview
              planName={plan.productName}
              planPrice={plan.productPrice}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;

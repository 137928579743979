/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState } from 'react';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styles from '../../assets/styles/Inputs/ChooseWeekDaysStyles';

import questionMark from '../../assets/icons/info.png';

function ChooseWeekDays({
  setValue, info, defaultWeekDays, reseted,
}) {
  const allWeekDays = [
    { name: 'Segunda', abv: 'Seg', selected: true },
    { name: 'Terça', abv: 'Ter', selected: true },
    { name: 'Quarta', abv: 'Qua', selected: true },
    { name: 'Quinta', abv: 'Qui', selected: true },
    { name: 'Sexta', abv: 'Sex', selected: true },
    { name: 'Sábado', abv: 'Sáb', selected: true },
    { name: 'Domingo', abv: 'Dom', selected: true },
  ].map((weekDay) => {
    if (defaultWeekDays) {
      if (!defaultWeekDays.includes(weekDay.name)) {
        weekDay.selected = !weekDay.selected;
      }
    }
    return weekDay;
  });

  const [weekDays, setWeekDays] = useState(allWeekDays);

  if (reseted && weekDays.filter((weekDay) => weekDay.selected).length !== 7) {
    setWeekDays(allWeekDays.map((weekDay) => {
      weekDay.selected = true;
      return weekDay;
    }));
    setValue(allWeekDays.map((weekDay) => {
      weekDay.selected = true;
      return weekDay;
    }).map((weekDay) => weekDay.name).toString());
  }

  const handleChangeWeekDays = (weekDayName) => {
    const tryWeekDays = weekDays.map((weekDay) => {
      if (weekDay.name === weekDayName) {
        weekDay.selected = !weekDay.selected;
      }
      return weekDay;
    });
    if (tryWeekDays.filter((weekDay) => weekDay.selected).length) {
      setWeekDays(tryWeekDays);
      setValue(weekDays.filter((weekDay) => weekDay.selected).map((weekDay) => weekDay.name).toString());
    }
  };

  const popover = (
    <Popover style={styles.popover}>
      <Popover.Header style={styles.popoverHeader}>Restrição de Dias da Semana</Popover.Header>
      <Popover.Body style={styles.popoverBody}>
        Apenas durante os dias da semana selecionados o usuário será direcionado para esse link
      </Popover.Body>
      <div style={styles.point} />
    </Popover>
  );

  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.defaultLabelContainer}>
        <p style={styles.defaultLabel}>Dias da semana</p>
      </div>
      <div style={styles.weekDaysContainer}>
        {
                    weekDays.map((weekDay) => (
                      <div style={styles.weekDayContainer} key={weekDay.abv}>
                        <p
                          onClick={() => handleChangeWeekDays(weekDay.name)}
                          style={weekDay.selected ? styles.selectedWeekDay : styles.notSelectedWeekDay}
                        >
                          {weekDay.abv}
                        </p>
                      </div>
                    ))
                }
      </div>
      {
                info
                  ? (
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                      <img alt="question-mark" src={questionMark} style={styles.info} />
                    </OverlayTrigger>
                  )
                  : false
            }
    </div>
  );
}

export default ChooseWeekDays;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import google from '../../assets/images/google-logo.png';

import styles from '../../assets/styles/Buttons/ContinueWithGoogleStyles';

function ContinueWithGoogle({ onClick, w, label }) {
  return (
    <div style={w ? { ...styles.containerStyles, width: w } : styles.containerStyles} onClick={onClick}>
      <img alt="google-logo" src={google} style={styles.logoStyles} />
      <p style={styles.textStyles}>{label}</p>
    </div>
  );
}

export default ContinueWithGoogle;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, forwardRef } from 'react';

// Styles
import styles from '../../assets/styles/LandingPageStyles';

// Copywriting
import lp from '../../copy/lp.json';

const Examples = forwardRef(({ isMobile }, ref) => {
  const [selected, setSelected] = useState(1);

  return (
    <div style={styles.section(isMobile, false, true)} ref={ref}>
      <div style={styles.header(isMobile)}>
        <p style={styles.title(isMobile)}>{lp['secao-exemplos'].titulo}</p>
        <p style={styles.subtitle(isMobile)}>{lp['secao-exemplos'].subtitulo}</p>
      </div>
      <div style={styles.body(isMobile, true, false)}>
        {
          !isMobile
            ? (
              <div style={styles.optionsContainer(isMobile)}>
                <div style={selected === 1 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(1)}>
                  <p style={selected === 1 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-1'].titulo}</p>
                </div>
                <div style={selected === 2 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(2)}>
                  <p style={selected === 2 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-2'].titulo}</p>
                </div>
                <div style={selected === 3 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(3)}>
                  <p style={selected === 3 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-3'].titulo}</p>
                </div>
              </div>
            ) : false
        }
        {
          !isMobile
            ? (
              <div style={styles.optionsContainer(isMobile)}>
                <div style={selected === 4 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(4)}>
                  <p style={selected === 4 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-4'].titulo}</p>
                </div>
                <div style={selected === 5 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(5)}>
                  <p style={selected === 5 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-5'].titulo}</p>
                </div>
                <div style={selected === 6 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(6)}>
                  <p style={selected === 6 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-6'].titulo}</p>
                </div>
              </div>
            ) : false
        }
        {
          isMobile
            ? (
              <div style={styles.optionsContainer(isMobile)}>
                <div style={selected === 1 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(1)}>
                  <p style={selected === 1 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-1'].titulo}</p>
                </div>
                <div style={selected === 2 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(2)}>
                  <p style={selected === 2 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-2'].titulo}</p>
                </div>
              </div>
            ) : false
        }
        {
          isMobile
            ? (
              <div style={styles.optionsContainer(isMobile)}>
                <div style={selected === 3 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(3)}>
                  <p style={selected === 3 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-3'].titulo}</p>
                </div>
                <div style={selected === 4 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(4)}>
                  <p style={selected === 4 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-4'].titulo}</p>
                </div>
              </div>
            ) : false
        }
        {
          isMobile
            ? (
              <div style={styles.optionsContainer(isMobile)}>
                <div style={selected === 5 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(5)}>
                  <p style={selected === 5 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-5'].titulo}</p>
                </div>
                <div style={selected === 6 ? styles.selectedOptionContainer(isMobile) : styles.optionContainer(isMobile)} onClick={() => setSelected(6)}>
                  <p style={selected === 6 ? styles.selectedOptionTitle(isMobile) : styles.optionTitle(isMobile)}>{lp['secao-exemplos'].exemplos['exemplo-6'].titulo}</p>
                </div>
              </div>
            ) : false
        }
        <div style={styles.selectedExampleContainer}>
          <div style={styles.selectedExample(isMobile)}>
            <div style={styles.exampleImageContainer} />
            <p style={styles.exampleText(isMobile)}>{lp['secao-exemplos'].exemplos[`exemplo-${selected}`].texto}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Examples;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import colors from '../../assets/styles/Global/colors';

function LittleButton({ text, cancel, onClick }) {
  const styles = {
    container: {
      width: '80px',
      height: '24px',
      backgroundColor: colors.green,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: colors.white,
      fontSize: '13px',
    },

    containerCancel: {
      width: '80px',
      height: '24px',
      backgroundColor: colors.grey,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: colors.lightgrey,
      fontSize: '13px',
      margin: '0px 10px 0px 0px',
    },
  };

  return (
    <div style={cancel ? styles.containerCancel : styles.container} onClick={() => onClick(false)}>
      <p>{text}</p>
    </div>
  );
}

export default LittleButton;

import axios from 'axios';
import config from '../../config/host';

function sendResetEmail(email, setEmailWarning) {
  axios
    .get(`${config.host}/api/user/sendResetEmail`, {
      params: { email },
    })
    .then((response) => {
      if (!response.data) {
        setEmailWarning('Este email não está cadastrado');
      } else {
        setEmailWarning('');
      }
    });
}

export default sendResetEmail;

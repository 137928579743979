import React, { useState, useEffect } from 'react';

// Layout
import NavBar from '../layouts/NavBar';

// Hooks
import sendResetEmail from '../hooks/User/sendResetEmail';

// Forms
import ForgotForm from '../components/Forms/ForgotForm';

// Global styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Forgot() {
  // Checking width for responsiveness purposes
  const [width, setWidth] = useState(Number(window.innerWidth));
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isMobile = width <= 768;

  // Forgot password form variables
  const [email, setEmail] = useState('');
  const [emailWarning, setEmailWarning] = useState('');

  const send = () => sendResetEmail(email, setEmailWarning);

  return (
    <div style={globalStyles.columnCentered}>
      <NavBar
        isMobile={isMobile}
      />
      <ForgotForm
        isMobile={isMobile}
        setEmail={setEmail}
        emailWarning={emailWarning}
        forgot
        onClick={send}
      />
    </div>
  );
}

export default Forgot;

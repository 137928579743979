/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import styles from '../../assets/styles/Buttons/ButtonNavBarStyles';

function ButtonNavBar({ label, onClick }) {
  return (
    <div style={styles.buttonStyle} onClick={onClick}>
      <p style={styles.labelStyle}>{label}</p>
    </div>
  );
}

export default ButtonNavBar;

/* eslint-disable import/no-cycle */
import axios from 'axios';
import config from '../../config/host';

import googleLogUser from './googleLogUser';
import generateRandomPassword from '../../logic/randomPassword';

function googleCreateUser(nav, email, googleId) {
  const password = generateRandomPassword(16);
  axios
    .post(`${config.host}/api/user/createUser`, {
      email, password, googleId, google: true,
    })
    .then(() => googleLogUser(email, googleId, nav));
}

export default googleCreateUser;

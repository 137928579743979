/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

// Navigation
import { useNavigate, useParams } from 'react-router-dom';

// Hooks
import useFetchQRFromUser from '../hooks/QR/useFetchQRFromUser';
import useFetchQRFromShort from '../hooks/QR/useFetchQRFromShort';
import useFetchUser from '../hooks/User/useFetchUser';
import useFetchLinksFromQRShort from '../hooks/Link/useFetchLinksFromQRShort';
import useFetchScansFromQRShort from '../hooks/Scan/useFetchScansFromQRShort';
import useFetchUTMsFromQRShort from '../hooks/UTM/useFetchUTMsFromQRShort';
import config from '../config/host';

// Layout
import SideBar from '../layouts/SideBar';
import TopBar from '../layouts/TopBar';

// Forms
import CreateQRForm from '../components/Forms/CreateQRForm';
import EditQRForm from '../components/Forms/EditQRForm';
import DeleteQR from '../components/SureTo/DeleteQR';
import AddLinkForm from '../components/Forms/AddLinkForm';
import EditLinkForm from '../components/Forms/EditLinkForm';
import DeleteLink from '../components/SureTo/DeleteLink';
import AddUTMLink from '../components/Forms/AddUTMLink';
import UTMLinks from '../components/QR/UTMLinks';
import DeleteUTM from '../components/SureTo/DeleteUTM';
import EditUTMForm from '../components/Forms/EditUTMForm';

// Components
import HeaderInfoQR from '../components/QR/HeaderInfoQR';
import MinHeaderInfoQR from '../components/QR/MinHeaderInfoQR';
import Links from '../components/QR/Links';
import ScansInfo from '../components/QR/ScansInfo';
import ScansAnalysis from '../components/QR/ScanAnalysis';
import Selector from '../components/QR/Selector';
import Info from '../components/Info';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';
import UTMTracking from '../components/QR/UTMTracking';

function QR() {
  const navigate = useNavigate();
  const { qrShort } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const analysis = queryParams.get('analysis');

  // Token (session)
  const token = localStorage.getItem('token');
  if (!token) navigate('/login');

  const user = useFetchUser(token);

  const [scans, setScans] = useState([]);
  useFetchScansFromQRShort(token, qrShort, setScans);

  // Selected QR Code
  const [qr, setQR] = useState({});
  useFetchQRFromShort(token, qrShort, setQR);

  // All QR Codes from the user
  const [qrList, setQRList] = useState([]);
  useFetchQRFromUser(token, setQRList);
  const onCreateQRCode = (newQRCode) => setQRList((prev) => [...prev, newQRCode]);
  const onEditQRCode = (editedQRCode) => {
    setQRList((prev) => prev.map((thisQR) => {
      if (thisQR._id === editedQRCode.qrId) {
        thisQR.qrName = editedQRCode.qrName;
        thisQR.defaultLink = editedQRCode.defaultLink;
        thisQR.qrShort = editedQRCode.qrShort;
        setQR(thisQR);
      }
      return thisQR;
    }));
  };
  const [createQROverlay, setCreateQROverlay] = useState(false);

  // All links from the selected QR Code
  const [links, setLinks] = useState([]);
  useFetchLinksFromQRShort(qrShort, setLinks);
  const onCreateLink = (newLink) => setLinks((prev) => [...prev, newLink]);
  const onDeleteLink = (deletedLinkId) => {
    setLinks((prev) => prev.filter((link) => link._id !== deletedLinkId));
  };
  const onEditLink = (editedLink) => setLinks((prev) => prev.map((link) => {
    if (link._id === editedLink.linkId) {
      link.linkName = editedLink.linkName;
      link.url = editedLink.url;
      link.osName = editedLink.osName;
      link.hourRestriction = editedLink.hourRestriction;
      link.locRestriction = editedLink.locRestriction;
      link.timeRestriction = editedLink.timeRestriction;
    }
    return link;
  }));

  const [utms, setUTMs] = useState([]);
  useFetchUTMsFromQRShort(token, qrShort, setUTMs);
  const onCreateUTM = (newUTM) => setUTMs((prev) => [...prev, newUTM]);
  const onDeleteUTM = (deletedUTMId) => setUTMs((prev) => prev.filter((utm) => utm._id !== deletedUTMId));
  const onEditUTM = (editedUTM) => setUTMs((prev) => prev.map((utmLink) => {
    if (utmLink._id === editedUTM.utmId) {
      utmLink.url = editedUTM.url;
    }
    return utmLink;
  }));

  const [createLinkOverlay, setCreateLinkOverlay] = useState(false);
  const [editQROverlay, setEditQROverlay] = useState(false);
  const [editLinkOverlay, setEditLinkOverlay] = useState(false);
  const [deleteQROverlay, setDeleteQROverlay] = useState(false);
  const [deleteLinkOverlay, setDeleteLinkOverlay] = useState(false);
  const [createUTMLinkOverlay, setCreateUTMLinkOverlay] = useState(false);
  const [deleteUTMOverlay, setDeleteUTMOverlay] = useState(false);
  const [editUTMOverlay, setEditUTMOverlay] = useState(false);

  const [selectedLink, selectLink] = useState({ });
  const [selectedUTMLink, selectUTMLink] = useState({ });

  const [selects, setSelects] = useState(0);
  const [selectsAnalysis, setSelectsAnalysis] = useState(0);

  const [conflictWarning, setConflictWarning] = useState(false);

  const redirect = `${config.front}/${qrShort}`;
  const success = scans.length === 0 || !user.featured
    ? '-'
    : `${Math.round(scans.filter((scan) => scan.success).length / (scans.length * 100))}%`;

  if (analysis !== 'true') {
    return (
      <div style={globalStyles.row}>
        <Info display={conflictWarning} warning title="Não foi possível criar o link" body="Esse link conflita com outros já criados. Por favor, crie um link sem abiguidades com outros links." />
        {
          createQROverlay
            ? (
              <CreateQRForm
                setOverlay={setCreateQROverlay}
                onCreateQRCode={onCreateQRCode}
              />
            ) : false
        }
        {
          createLinkOverlay
            ? (
              <AddLinkForm
                setOverlay={setCreateLinkOverlay}
                qrId={qr._id}
                qrShort={qrShort}
                userPlan={user.plan}
                userFeatured={user.featured}
                nOfLinks={qr.nOfLinks}
                links={links}
                setConflictWarning={setConflictWarning}
                onCreateLink={onCreateLink}
              />
            ) : false
        }
        {
          createUTMLinkOverlay
            ? (
              <AddUTMLink
                setOverlay={setCreateUTMLinkOverlay}
                redirect={redirect}
                qr={qr}
                onCreateUTM={onCreateUTM}
              />
            ) : false
        }
        {
          editQROverlay
            ? (
              <EditQRForm
                setOverlay={setEditQROverlay}
                qr={qr}
                onEditQRCode={onEditQRCode}
              />
            )
            : false
        }
        {
          editLinkOverlay
            ? (
              <EditLinkForm
                setOverlay={setEditLinkOverlay}
                link={selectedLink}
                userFeatured={user.featured}
                onEditLink={onEditLink}
                links={links}
              />
            )
            : false
        }
        {
          editUTMOverlay
            ? (
              <EditUTMForm
                setOverlay={setEditUTMOverlay}
                utmLink={selectedUTMLink}
                onEditUTM={onEditUTM}
              />
            ) : false
        }
        {
          deleteQROverlay
            ? (
              <DeleteQR
                overlay={deleteQROverlay}
                setOverlay={setDeleteQROverlay}
                qrShort={qrShort}
              />
            )
            : false
        }
        {
          deleteLinkOverlay
            ? (
              <DeleteLink
                setOverlay={setDeleteLinkOverlay}
                link={selectedLink}
                qrShort={qrShort}
                onDeleteLink={onDeleteLink}
              />
            )
            : false
        }
        {
          deleteUTMOverlay
            ? (
              <DeleteUTM
                overlay={deleteUTMOverlay}
                setOverlay={setDeleteUTMOverlay}
                utmLink={selectedUTMLink}
                onDeleteUTM={onDeleteUTM}
              />
            ) : false
        }
        <SideBar
          setOverlay={setCreateQROverlay}
          qrList={qrList}
          setQR={setQR}
          setLinks={setLinks}
          setScans={setScans}
          setUTMs={setUTMs}
        />
        <div>
          <TopBar
            setOverlay={setCreateQROverlay}
          />
          <HeaderInfoQR
            qrName={qr.qrName}
            url={redirect}
            links={links.length}
            scans={scans.length}
            defaultLink={qr.defaultLink}
            success={success}
            setOverlay={setEditQROverlay}
            setDeleteOverlay={setDeleteQROverlay}
          />
          <Selector
            options={['Links cadastrados', 'UTM Tracking']}
            utm={user.featured >= 3}
            selected={selects}
            setSelector={setSelects}
          />
          <div style={{ padding: '16px 100px 30px 100px', height: 'calc(100vh - 284px)' }}>
            {
              selects === 0
                ? (
                  <Links
                    links={links}
                    qrShort={qrShort}
                    defaultLink={qr.defaultLink}
                    setOverlay={setCreateLinkOverlay}
                    setEditOverlay={setEditLinkOverlay}
                    setDeleteLinkOverlay={setDeleteLinkOverlay}
                    selectLink={selectLink}
                    scans={scans}
                  />
                )
                : selects === 1
                  ? (
                    <UTMLinks
                      setOverlay={setCreateUTMLinkOverlay}
                      qrShort={qrShort}
                      setDeleteUTMOverlay={setDeleteUTMOverlay}
                      setEditUTMOverlay={setEditUTMOverlay}
                      selectUTMLink={selectUTMLink}
                      utms={utms}
                      scans={scans}
                    />
                  )
                  : false
            }
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={globalStyles.row}>
      <Info display={conflictWarning} warning title="Não foi possível criar o link" body="Esse link conflita com outros já criados. Por favor, crie um link sem abiguidades com outros links." />
      {
        createQROverlay
          ? (
            <CreateQRForm
              setOverlay={setCreateQROverlay}
              onCreateQRCode={onCreateQRCode}
            />
          ) : false
      }
      {
        createLinkOverlay
          ? (
            <AddLinkForm
              setOverlay={setCreateLinkOverlay}
              qrId={qr._id}
              qrShort={qrShort}
              userPlan={user.plan}
              userFeatured={user.featured}
              nOfLinks={qr.nOfLinks}
              links={links}
              setConflictWarning={setConflictWarning}
              onCreateLink={onCreateLink}
            />
          ) : false
      }
      {
        createUTMLinkOverlay
          ? (
            <AddUTMLink
              setOverlay={setCreateUTMLinkOverlay}
              redirect={redirect}
              qr={qr}
              onCreateUTM={onCreateUTM}
            />
          ) : false
      }
      {
        editQROverlay
          ? (
            <EditQRForm
              setOverlay={setEditQROverlay}
              qr={qr}
              onEditQRCode={onEditQRCode}
            />
          )
          : false
      }
      {
        editLinkOverlay
          ? (
            <EditLinkForm
              setOverlay={setEditLinkOverlay}
              link={selectedLink}
              userFeatured={user.featured}
              onEditLink={onEditLink}
            />
          )
          : false
      }
      {
        editUTMOverlay
          ? (
            <EditUTMForm
              setOverlay={setEditUTMOverlay}
              utmLink={selectedUTMLink}
              onEditUTM={onEditUTM}
            />
          ) : false
      }
      {
        deleteQROverlay
          ? (
            <DeleteQR
              overlay={deleteQROverlay}
              setOverlay={setDeleteQROverlay}
              qrShort={qrShort}
            />
          )
          : false
      }
      {
        deleteLinkOverlay
          ? (
            <DeleteLink
              setOverlay={setDeleteLinkOverlay}
              link={selectedLink}
              qrShort={qrShort}
              onDeleteLink={onDeleteLink}
            />
          )
          : false
      }
      {
        deleteUTMOverlay
          ? (
            <DeleteUTM
              overlay={deleteUTMOverlay}
              setOverlay={setDeleteUTMOverlay}
              utmLink={selectedUTMLink}
              onDeleteUTM={onDeleteUTM}
            />
          ) : false
      }

      <SideBar
        setOverlay={setCreateQROverlay}
        qrList={qrList}
        setQR={setQR}
        setLinks={setLinks}
        setScans={setScans}
        setUTMs={setUTMs}
      />
      <div>
        <TopBar
          setOverlay={setCreateQROverlay}
        />
        <MinHeaderInfoQR
          qrName={qr.qrName}
          url={redirect}
        />
        <Selector
          options={['Leituras do QR Code', 'Análise das leituras', 'UTM Tracking']}
          utm={user.featured >= 3}
          selected={selectsAnalysis}
          setSelector={setSelectsAnalysis}
        />
        <div style={{ padding: '16px 100px 30px 100px', height: 'calc(100vh - 220px)' }}>
          {
            selectsAnalysis === 0
              ? (
                <ScansInfo scans={scans} />
              )
              : selectsAnalysis === 1
                ? <ScansAnalysis scans={scans} />
                : selectsAnalysis === 2
                  ? (
                    <UTMTracking
                      redirect={redirect}
                      qrShort={qrShort}
                      scans={scans}
                      setVisible={setCreateUTMLinkOverlay}
                      utms={utms}
                    />
                  )
                  : false
                  }
        </div>
      </div>
    </div>
  );
}

export default QR;

import React, { useState, useEffect } from 'react';

// Navigation
import { useNavigate } from 'react-router-dom';

// Hooks
import logUser from '../hooks/User/logUser';

// Layout
import NavBar from '../layouts/NavBar';

// Forms
import LoginForm from '../components/Forms/LoginForm';

// Styles
import globalStyles from '../assets/styles/Global/globalStyles';

function Login() {
  const navigate = useNavigate();

  // Token (session)
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) navigate('/home');
  });

  // Checking width for responsiveness purposes
  const [width, setWidth] = useState(Number(window.innerWidth));
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isMobile = width <= 768;

  // Login form variables
  const [email, setEmail] = useState('');
  const [emailWarning, setEmailWarning] = useState('');

  const [password, setPassword] = useState('');
  const [passwordWarning, setPasswordWarning] = useState('');

  const tryLogUser = () => {
    logUser(email, password, navigate, setEmail, setPassword, setEmailWarning, setPasswordWarning);
  };

  return (
    <div style={globalStyles.columnCentered}>
      <NavBar
        isMobile={isMobile}
        onlySignin
      />
      <LoginForm
        isMobile={isMobile}
        setEmail={setEmail}
        userEmail={email}
        password={password}
        emailWarning={emailWarning}
        setPassword={setPassword}
        passwordWarning={passwordWarning}
        onClick={tryLogUser}
      />
    </div>
  );
}

export default Login;

import globalStyles from '../Global/globalStyles';

const styles = {
  background: {
    ...globalStyles.center,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: '9',
  },

  containerStyles: {
    ...globalStyles.column,
    backgroundColor: '#FFFFFF',
    width: '72vw',
    height: '320px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: '16px',
    zIndex: '10',
    position: 'relative',
  },

  linkContainer: {
    position: 'absolute',
  },

  inputsStyles: {
    ...globalStyles.row,
    width: '92%',
    height: '110px',
    justifyContent: 'space-between',
    margin: '0px 0px -24px 0px',
  },

  titleStyles: {
    fontSize: '30px',
    fontWeight: 'bold',
    letterSpacing: '0.9px',
  },

  close: {
    ...globalStyles.pointer,
    width: '20px',
    position: 'absolute',
    top: '16px',
    left: 'calc(100% - 36px)',
  },
};

export default styles;

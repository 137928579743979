import axios from 'axios';
import config from '../../config/host';

function setNOfLinks(token, qrShort, number) {
  axios
    .put(`${config.host}/api/qr/setNOfLinks`, {
      headers: { authorization: token },
      params: { qrShort, number },
    })
    .then((response) => response);
}

export default setNOfLinks;

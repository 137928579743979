import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config/host';

function useFetchUTMsFromQRShort(token, qrShort, setUTMs) {
  useEffect(() => {
    axios
      .get(`${config.host}/api/utm/getUTMsFromQRShort`, {
        headers: { authorization: token },
        params: { qrShort },
      })
      .then((response) => setUTMs(response.data));
  }, []);
}

export default useFetchUTMsFromQRShort;

/* eslint-disable max-len */
import axios from 'axios';
import config from '../../config/host';

function createUser(nav, email, setEmail, password, setPassword, setConfirmPassword, emailWarning, setEmailWarning, passwordWarning, confirmPasswordWarning) {
  if (!emailWarning && !passwordWarning && !confirmPasswordWarning && email && password) {
    axios
      .post(`${config.host}/api/user/createUser`, {
        password, email,
      })
      .then((response) => {
        if (response.data.code === 11000) {
          setEmailWarning('Esse email já está cadastrado');
        } else {
          setPassword('');
          setConfirmPassword('');
          setEmail('');
          nav('/login');
        }
      });
  }
}

export default createUser;

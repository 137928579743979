import React, { useState } from 'react';
import styles from '../../assets/styles/ProfileStyles';

// Hooks
import useFetchUser from '../../hooks/User/useFetchUser';
import updateUser from '../../hooks/User/updateUser';

// Components
import TextInput from '../Inputs/TextInput';
import DefaultButton from '../Buttons/DefaultButton';

function PersonalInfo() {
  const token = localStorage.getItem('token');
  const [editable, setEditable] = useState(false);
  const user = useFetchUser(token);

  const [userName, setUserName] = useState(user.userName);
  const [userEmail, setUserEmail] = useState(user.email);

  const tryUpdateUser = () => {
    updateUser(token, userName, userEmail);
    setEditable(false);
  };

  if (editable) {
    return (
      <div style={styles.personalInfoContainer}>
        <TextInput label="Nome do usuário" value={userName || user.userName} setValue={setUserName} />
        <TextInput label="E-mail cadastrado" value={userEmail || user.email} setValue={setUserEmail} />
        <div style={styles.allButons}>
          <DefaultButton label="Cancelar" terciary w="100px" onClick={() => setEditable(false)} />
          <div style={styles.actionButtons}>
            <DefaultButton label="Editar" secundary w="120px" onClick={() => setEditable(true)} />
            <DefaultButton label="Salvar" w="120px" onClick={tryUpdateUser} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={styles.personalInfoContainer}>
      <TextInput label="Nome do usuário" value={userName || user.userName} disabled />
      <TextInput label="E-mail cadastrado" value={userEmail || user.email} disabled />
      <div style={styles.allButons}>
        <DefaultButton label="" terciary w="0px" />
        <div style={styles.actionButtons}>
          <DefaultButton label="Editar" secundary w="120px" onClick={() => setEditable(true)} />
          <DefaultButton label="Salvar" disabled w="120px" />
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;

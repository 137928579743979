import axios from 'axios';
import config from '../../config/host';

function deleteUTM(token, utmId) {
  axios
    .delete(`${config.host}/api/utm/deleteUTM`, {
      headers: { authorization: token },
      params: { utmId },
    })
    .then((response) => response);
}

export default deleteUTM;

/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';

import Node from './Node';

function ProgressBar({
  nodes, userFeatured, names,
}) {
  let locked = [0, 1, 1, 1, 1];
  if (userFeatured === 0) {
    locked = [0, 1, 1, 1, 1];
  }
  if (userFeatured === 1 || userFeatured === 2) {
    locked = [0, 0, 1, 1, 1];
  }
  if (userFeatured === 3) {
    locked = [0, 0, 0, 0, 0];
  }

  return (
    <div style={{ display: 'flex' }}>
      {
                nodes.map((el, idx) => {
                  const starting = idx === 0;
                  const ending = idx === nodes.length - 1;
                  return (
                    <Node starting={starting} ending={ending} colored={el} n={nodes.length} idx={idx + 1} key={idx} locked={locked[idx]} names={names} />
                  );
                })
            }
    </div>
  );
}

export default ProgressBar;

import axios from 'axios';
import config from '../../config/host';

function deleteScansWhenLinkDeleted(token, linkId) {
  axios
    .delete(`${config.host}/api/scan/deleteScansFromLinkId`, {
      headers: { authorization: token },
      params: { linkId },
    })
    .then((response) => response);
}

export default deleteScansWhenLinkDeleted;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styles from '../../assets/styles/Inputs/ChooseMonthsStyles';

import questionMark from '../../assets/icons/info.png';

function ChooseMonths({
  setValue, info, defaultMonths, reseted,
}) {
  const allMonths = [
    { name: 'Janeiro', abv: 'Jan', selected: true },
    { name: 'Fevereiro', abv: 'Fev', selected: true },
    { name: 'Março', abv: 'Mar', selected: true },
    { name: 'Abril', abv: 'Abr', selected: true },
    { name: 'Maio', abv: 'Mai', selected: true },
    { name: 'Junho', abv: 'Jun', selected: true },
    { name: 'Julho', abv: 'Jul', selected: true },
    { name: 'Agosto', abv: 'Ago', selected: true },
    { name: 'Setembro', abv: 'Set', selected: true },
    { name: 'Outubro', abv: 'Out', selected: true },
    { name: 'Novembro', abv: 'Nov', selected: true },
    { name: 'Dezembro', abv: 'Dez', selected: true },
  ].map((month) => {
    if (defaultMonths) {
      if (!defaultMonths.includes(month.name)) {
        month.selected = !month.selected;
      }
    }
    return month;
  });

  const [months, setMonths] = useState(allMonths);

  if (reseted && months.filter((month) => month.selected).length !== 12) {
    setMonths(allMonths.map((month) => {
      month.selected = true;
      return month;
    }));
    setValue(allMonths.map((month) => {
      month.selected = true;
      return month;
    }).map((month) => month.name).toString());
  }

  const handleChangeMonths = (monthName) => {
    const tryMonths = months.map((month) => {
      if (month.name === monthName) {
        month.selected = !month.selected;
      }
      return month;
    });
    if (tryMonths.filter((month) => month.selected).length) {
      setMonths(tryMonths);
      setValue(months.filter((month) => month.selected).map((month) => month.name).toString());
    }
  };

  const popover = (
    <Popover style={styles.popover}>
      <Popover.Header style={styles.popoverHeader}>Restrição de Meses</Popover.Header>
      <Popover.Body style={styles.popoverBody}>
        Apenas durante os meses selecionados o usuário será direcionado para esse link
      </Popover.Body>
      <div style={styles.point} />
    </Popover>
  );

  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.defaultLabelContainer}>
        <p style={styles.defaultLabel}>Meses do ano</p>
      </div>
      <div style={styles.monthsContainer}>
        {
                    months.map((month) => (
                      <div style={styles.monthContainer} key={month.abv}>
                        <p
                          onClick={() => handleChangeMonths(month.name)}
                          style={month.selected ? styles.selectedMonth : styles.notSelectedMonth}
                        >
                          {month.abv}
                        </p>
                      </div>
                    ))
                }
      </div>
      {
                info
                  ? (
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                      <img alt="question-mark" src={questionMark} style={styles.info} />
                    </OverlayTrigger>
                  )
                  : false
            }
    </div>
  );
}

export default ChooseMonths;

/* eslint-disable no-nested-ternary */
import React from 'react';
import colors from '../assets/styles/Global/colors';

const style = {
  container: {
    width: '20.2%',
    height: '14%',
    backgroundColor: colors.lightgrey,
    position: 'absolute',
    bottom: '40px',
    right: '40px',
    borderRadius: '10px',
    border: `1px solid ${colors.grey}`,
    zIndex: '30',
  },

  headerWarning: {
    backgroundColor: colors.redalert,
    height: '36px',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottom: `1px solid ${colors.grey}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },

  headerInfo: {
    backgroundColor: colors.yellowalert,
    height: '36px',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottom: `1px solid ${colors.grey}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },

  headerLog: {
    backgroundColor: colors.grey,
    height: '36px',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottom: `1px solid ${colors.grey}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },

  title: {
    fontSize: '1vw',
    fontWeight: '600',
    color: colors.black,
  },

  body: {
    padding: '12px',
    fontSize: '0.83vw',
  },
};

function Info({
  info, warning, title, body, display,
}) {
  if (display) {
    return (
      <div style={style.container}>
        <div style={info ? style.headerInfo : warning ? style.headerWarning : style.headerLog}>
          <p style={style.title}>{title}</p>
        </div>
        <div style={style.body}>
          <p>{body}</p>
        </div>
      </div>
    );
  }
  return (
    <div />
  );
}

export default Info;

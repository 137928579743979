import axios from 'axios';
import config from '../../config/host';

function createNewLink(token, link) {
  axios
    .post(`${config.host}/api/link/createLink`, link, {
      headers: { authorization: token },
    })
    .then((response) => response);
}

export default createNewLink;

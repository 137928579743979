const colors = {
  black: '#13131A',
  green: '#13C47A',
  white: '#F5F5FA',
  blue: '#6495ED',
  warning: '#FF5F5F',
  grey: '#B3B3B3',
  lesslightgrey: '#D1D1D1',
  darkgrey: '#505A64',
  lightgrey: '#FBFBFB',
  transparentgreen: 'rgba(19, 196, 122, 0.2)',
  reallytransparentgreen: 'rgba(19, 196, 122, 0.05)',
  unabled: '#F9F9F9',
  unabledtxt: '#B0B0B0',
  redalert: '#FFA9A9',
  yellowalert: '#EDE395',
};

export default colors;
